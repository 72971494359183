import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  TextField,
  MenuItem,
  ListItemText,
  Checkbox,
  Select,
  OutlinedInput,
  Chip,
} from "@mui/material";
import classes from "./classManagement.module.css";
import { Button, Col } from "react-bootstrap";
import Footer from "../../../Components/Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import { BrudCrumsNextIcon } from "../../../Assets/Icons/icons";
import { schoolDetails } from "../../../Utilities/TableDummyDatas";
import SchoolDropDown from "../../../Components/schoolDropDown";
import AlertBox from "../../../Components/AlertBox";
import { getOneSchools } from "../../../Redux/actions/schoolActions";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  addNewClass,
  getOneClass,
  updateClass,
} from "../../../Redux/actions/classActions";
import { Numeric } from "../../../Utilities/commonFunctions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const MenuProps1 = {
  PaperProps: {
    style: {
      top: "550px",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddClassPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [terminology, setTerminology] = useState("GRADE");
  const [grade, setGrade] = useState("");
  const [section, setSection] = React.useState([]);
  const { error, oneSchool } = useSelector((state) => state.schoolDatas);
  const [defaultError, setDefaultError] = useState("");
  const [schoolName, setSchoolName] = useState(oneSchool?.name);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(oneSchool?._id);
  const {
    success,
    error: classError,
    oneClass,
  } = useSelector((state) => state.classDatas);

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    if (window.location.pathname.includes("edit")) {
      dispatch(pageLoader(true));
      dispatch(getOneClass(arr[arr.length - 1], setErrorAlert));
    } else {
      dispatch(pageLoader(true));
      dispatch(getOneSchools(arr[arr.length - 1], setErrorAlert));
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (window.location.pathname.includes("edit")) {
      setSelectedSchool(oneClass?.schoolId);
      setSchoolName(oneClass?.school?.name);
      setGrade(oneClass?.grade);
      setSection(
        oneClass?.terminology?.map((item) => {
          return item.section;
        })
      );
    }
  }, [oneClass]);

  useEffect(() => {
    setSchoolName(oneSchool?.name);
    setSelectedSchool(oneSchool?._id);
    setTerminology(oneSchool?.batch);
  }, [oneSchool]);

  const handleChange = (e) => {
    setSelectedSchool(e.target.value);
  };

  const handleChangeCheckBox = (event) => {
    const {
      target: { value },
    } = event;
    setSection(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeGradeCheckBox = (event) => {
    setGrade(event.target.value);
  };

  const handleNavigateclassManageMennt = () => {
    navigate("/class-management");
    dispatch(setHeaderName("class-management"));
  };

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  const handleSubmit = (e) => {
    if (selectedSchool === "select") {
      setErrorAlert(true);
      setDefaultError("Please Select Any School");
    } else if (!grade) {
      setErrorAlert(true);
      setDefaultError("Please Select Any Grade");
    } else if (section?.length < 1) {
      setErrorAlert(true);
      setDefaultError("Please Select Any Section");
    } else {
      const userEntry = {
        schoolId: selectedSchool,
        grade: grade,
        terminology: section?.map((item) => {
          return { section: item, isActive: true };
        }),
        type: terminology,
      };
      if (window.location.pathname.includes("edit")) {
        dispatch(pageLoader(true));
        dispatch(
          updateClass(oneClass._id, userEntry, setSuccessAlert, setErrorAlert)
        );
      } else {
        userEntry.gradeId = Math.floor(100000 + Math.random() * 900000);
        dispatch(pageLoader(true));
        dispatch(addNewClass(userEntry, setSuccessAlert, setErrorAlert));
      }
    }
  };

  const handleselectTerminology = (name) => {
    console.log(oneSchool, name);
    if (oneSchool && oneSchool.batch !== "-" && oneSchool.batch !== name) {
      setErrorAlert(true);
      setDefaultError("You have already seleted your school Terminology Type");
    } else {
      setTerminology(name);
      setGrade("");
      setSection([]);
    }
  };

  const handleDelete = (item) => {
    section.splice(section.indexOf(item), 1);
    setSection([...section]);
  };

  return (
    <div>
      <div className={classes.brudcrumbs}>
        <span
          className={classes.TableHeaderText}
          onClick={() => handleNavigateclassManageMennt()}
        >
          Class Management
        </span>
        <span>
          <BrudCrumsNextIcon />
        </span>
        <span
          className={`${classes.TableBodyText} ${classes.HeadingText}`}
          style={{ textTransform: "capitalize" }}
        >
          {window.location.pathname.includes("edit")
            ? `Edit ${
                oneClass?.grade?.charAt(0).toUpperCase() +
                oneClass?.grade?.slice(1).toLowerCase().replaceAll("_", " ")
              }`
            : "Add New Class"}
        </span>
      </div>
      <ValidatorForm onSubmit={handleSubmit} useref="form">
        <Card
          style={{
            padding: 16,
            height: "90vh",
            marginBottom: "60px",
          }}
          className="mt-3"
        >
          <Col md={4}>
            <Typography className={classes.PageMaindiv}>
              Add New Class
            </Typography>
            <div className="mt-3">
              <Typography className={`${classes.TableBodyText} mb-1`}>
                Select School
              </Typography>
              <SchoolDropDown
                value={selectedSchool ? selectedSchool : "select"}
                handleChange={handleChange}
                width={"100%"}
                edit={true}
              />
            </div>
            <div className="mt-3">
              <Typography className={`${classes.TableBodyText} mb-1`}>
                Select terminology
              </Typography>
              <div className="d-flex justify-content-between align-items-center">
                <Card
                  className={
                    terminology === "GRADE"
                      ? `${classes.btnCardGrade} ${classes.activeCard}`
                      : classes.btnCardGrade
                  }
                  onClick={() => {
                    handleselectTerminology("GRADE");
                  }}
                >
                  <Typography>Grade</Typography>
                </Card>
                <Card
                  className={
                    terminology === "CLASS"
                      ? `${classes.btnCardGrade} ${classes.activeCard}`
                      : classes.btnCardGrade
                  }
                  onClick={() => {
                    handleselectTerminology("CLASS");
                  }}
                >
                  <Typography>Class</Typography>
                </Card>
                <Card
                  className={
                    terminology === "YEARS"
                      ? `${classes.btnCardGrade} ${classes.activeCard}`
                      : classes.btnCardGrade
                  }
                  onClick={() => {
                    handleselectTerminology("YEARS");
                  }}
                >
                  <Typography>Year</Typography>
                </Card>
              </div>
            </div>
            <div className="mt-3">
              <Typography className={`${classes.TableBodyText} mb-1`}>
                {terminology === "GRADE"
                  ? "Grade Name"
                  : terminology === "CLASS"
                  ? "Class Name"
                  : "No of Years"}
              </Typography>
              {terminology !== "YEARS" ? (
                <Select
                  fullWidth
                  value={grade}
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                    textAlign: "left",
                  }}
                  size="small"
                  onChange={handleChangeGradeCheckBox}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => {
                    if (selected === "") {
                      return (
                        <em className={classes.defaultSelectValue}>
                          {terminology === "GRADE"
                            ? "Select Grade"
                            : "Select Class"}
                        </em>
                      );
                    }
                    return (
                      selected?.charAt(0).toUpperCase() +
                      selected?.slice(1).toLowerCase().replaceAll("_", " ")
                    );
                  }}
                  MenuProps={MenuProps}
                  displayEmpty // Add displayEmpty property
                  InputLabelProps={{
                    shrink: false, // Add shrink property
                  }}
                >
                  <MenuItem disabled value="" style={{ display: "none" }}>
                    <em>
                      {terminology === "Grade"
                        ? "Select Grade"
                        : "Select Class"}
                    </em>
                  </MenuItem>
                  {terminology === "GRADE" &&
                    ["GRADE_1", "GRADE_2", "GRADE_3", "GRADE_4", "GRADE_5"].map(
                      (name) => (
                        <MenuItem key={name} value={name}>
                          <ListItemText
                            primary={
                              name?.charAt(0).toUpperCase() +
                              name?.slice(1).toLowerCase().replaceAll("_", " ")
                            }
                            className={classes.ListItemText}
                          />
                        </MenuItem>
                      )
                    )}
                  {terminology === "CLASS" &&
                    ["CLASS_A", "CLASS_B", "CLASS_C", "CLASS_D", "CLASS_E"].map(
                      (name) => (
                        <MenuItem key={name} value={name}>
                          <ListItemText
                            primary={
                              name?.charAt(0).toUpperCase() +
                              name?.slice(1).toLowerCase().replaceAll("_", " ")
                            }
                            className={classes.ListItemText}
                          />
                        </MenuItem>
                      )
                    )}
                </Select>
              ) : (
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="gradeInput"
                  fullWidth
                  className={classes.TextInput}
                  placeholder="Enter Number of Years"
                  value={grade}
                  onChange={(e) => {
                    setGrade(e.target.value);
                  }}
                  inputProps={{ maxLength: 2 }}
                  onKeyPress={(e) => Numeric(e)}
                  size="small"
                  validators={["required"]} // Add this line
                  errorMessages={["Grade is required"]}
                />
              )}
            </div>
            <Typography className={`mt-3 mb-2 ${classes.PageMaindiv}`}>
              Please add a section for your Grade
            </Typography>
            <div className="mt-3">
              <Typography className={`${classes.TableBodyText} mb-1`}>
                Section Name
              </Typography>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                fullWidth
                value={section || []}
                sx={{
                  "& legend": {
                    display: "none",
                  },
                  "& fieldset": {
                    top: 0,
                  },
                  textAlign: "left",
                }}
                size="small"
                onChange={handleChangeCheckBox}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => {
                  if (selected?.length === 0) {
                    return (
                      <em className={classes.defaultSelectValue}>
                        Select Section
                      </em>
                    );
                  }
                  return selected
                    ?.map((value) => value.replaceAll("_", " "))
                    ?.map(
                      (value) =>
                        value?.charAt(0).toUpperCase() +
                        value?.slice(1).toLowerCase()
                    )
                    .join(", ");
                }}
                MenuProps={MenuProps1}
                displayEmpty // Add displayEmpty property
                InputLabelProps={{
                  shrink: false, // Add shrink property
                }}
              >
                <MenuItem disabled value="" style={{ display: "none" }}>
                  <em>Select Section</em>
                </MenuItem>
                {terminology !== "GRADE"
                  ? [
                      "SECTION_1",
                      "SECTION_2",
                      "SECTION_3",
                      "SECTION_4",
                      "SECTION_5",
                    ].map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={section?.indexOf(name) > -1} />
                        <ListItemText
                          primary={
                            name?.charAt(0).toUpperCase() +
                            name?.slice(1).toLowerCase().replaceAll("_", " ")
                          }
                          className={classes.ListItemText}
                        />
                      </MenuItem>
                    ))
                  : ["CLASS_A", "CLASS_B", "CLASS_C", "CLASS_D", "CLASS_E"].map(
                      (name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={section?.indexOf(name) > -1} />
                          <ListItemText
                            primary={
                              name?.charAt(0).toUpperCase() +
                              name?.slice(1).toLowerCase().replaceAll("_", " ")
                            }
                            className={classes.ListItemText}
                          />
                        </MenuItem>
                      )
                    )}
              </Select>
              <div className={`mt-2 ${classes.SectionChipContainer}`}>
                {section &&
                  section.length > 0 &&
                  section.map((item, index) => {
                    return (
                      <Chip
                        key={item}
                        label={
                          item?.charAt(0).toUpperCase() +
                          item?.slice(1).toLowerCase().replaceAll("_", " ")
                        }
                        onDelete={() => handleDelete(item)}
                      />
                    );
                  })}
              </div>
            </div>
          </Col>
        </Card>
        <Footer
          callback={handleNavigateclassManageMennt}
          edit={window.location.pathname.includes("edit")}
        />
      </ValidatorForm>
      {errorAlert && error ? (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      ) : errorAlert && defaultError ? (
        <AlertBox type="error" message={defaultError} stateName={CloseAlert} />
      ) : errorAlert && classError ? (
        <AlertBox type="error" message={classError} stateName={CloseAlert} />
      ) : null}
      {successAlert && success && (
        <AlertBox
          type="success"
          message={success}
          stateName={CloseAlert}
          callback={handleNavigateclassManageMennt}
        />
      )}
    </div>
  );
};

export default AddClassPage;
