module.exports = {
    FirstGraphoptions: {
        series: [
            {
                name: 'Other Collection',
                data: [0, 31, 40, 28, 51, 42, 87, 99],
                color: '#BA9BFF',
            },
            {
                name: 'Subscription Collection',
                data: [0, 11, 32, 45, 32, 34, 52, 41],
                color: '#FFC700',
            },
        ],

        options: {
            chart: {
                height: 210,
                type: 'area',
                toolbar: {
                    show: false,
                },
                yaxis: [
                    {
                        label: {
                            text: "25k"
                        }
                    }
                ]
            },
            grid: {
                show: true,      // you can either change hear to disable all grids
                xaxis: {
                    lines: {
                        show: true, //or just here to disable only x axis grids
                    }
                },
                yaxis: {
                    lines: {
                        show: true  //or just here to disable only y axis
                    }
                },
            },
            fill: {
                type: "solid", // Set to "solid" to use a solid color
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                type: 'text',
                categories: [
                    "",
                    "Mon",
                    "Tue",
                    "Wed",
                    "Thu",
                    "Fri",
                    "Sat",
                    "Sun",
                ],
            },
            legend: {
                show: false, // Hide the legend
            },
        },
    },
    SecondGraphoptions: {
        series: [
            {
                name: 'Active',
                data: [0, 19, 3, 35, 11, 26, 31, 40],
                color: '#BA9BFF',
            },
            {
                name: 'Inactive',
                data: [0, 1, 38, 14, 9, 29, 17, 4],
                color: '#FFADDB',
            },
        ],

        options: {
            chart: {
                height: 210,
                type: 'area',
                toolbar: {
                    show: false,
                },
                yaxis: [
                    {
                        label: {
                            text: "25k"
                        }
                    }
                ]
            },
            grid: {
                show: true,      // you can either change hear to disable all grids
                xaxis: {
                    lines: {
                        show: true, //or just here to disable only x axis grids
                    }
                },
                yaxis: {
                    lines: {
                        show: true  //or just here to disable only y axis
                    }
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                type: 'text',
                categories: [
                    "",
                    "Mon",
                    "Tue",
                    "Wed",
                    "Thu",
                    "Fri",
                    "Sat",
                    "Sun",
                ],
            },
            legend: {
                show: false, // Hide the legend
            },
        },
    }
}