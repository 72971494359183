import { Button } from "@mui/material";
import React from "react";
import classes from "./index.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Footer = ({ callback, edit }) => {
  const { toggleSideBar } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();
  return (
    <div
      className={classes.FooterMainDiv}
      style={{
        width: toggleSideBar ? "calc(100% - 240px)" : "calc(100% - 68px)",
        left: toggleSideBar ? "240px" : "68px",
        zIndex: "10",
      }}
    >
      <Button
        variant="outlined"
        className={classes.FooterBtn}
        onClick={() => callback()}
        style={{
          color: "var(--forgot-password-text-color)",
          borderColor: "var(--forgot-password-text-color)",
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        className={classes.FooterBtn}
        type="submit"
        style={{
          background: "var(--forgot-password-text-color)",
        }}
      >
        {edit ? "Save" : "Submit"}
      </Button>
    </div>
  );
};

export default Footer;
