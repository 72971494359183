import {
  ActiveSchoolsIcon,
  DashboardSchoolIcon,
  InactiveSchoolsIcon,
  NewSchoolsIcon,
  SchoolCourseIcon,
  SchoolStudentIcon,
  SchoolTeacherIcon,
} from "../../Assets/Icons/icons";
import { SchoolStudentData } from "../../Utilities/TableDummyDatas";
import {
  ADD_ONE_SCHOOL_PIECHART_FAIL,
  ADD_ONE_SCHOOL_PIECHART_SUCCESS,
  ADD_SCHOOL_FAIL,
  ADD_SCHOOL_PIECHART_FAIL,
  ADD_SCHOOL_PIECHART_SUCCESS,
  ADD_SCHOOL_SUCCESS,
  GET_ONE_SCHOOL_FAIL,
  GET_ONE_SCHOOL_SUCCESS,
  GET_SCHOOLS_FAIL,
  GET_SCHOOLS_NAME_LIST_FAIL,
  GET_SCHOOLS_NAME_LIST_SUCCESS,
  GET_SCHOOLS_SUCCESS,
} from "../contants/schoolConstants";

const initialState = {
  error: null,
  success: null,
  schoolsList: [],
  oneSchool: null,
  schoolsNameList: [],
  totalCount: 0,
  piechartData: [
    {
      icon: <DashboardSchoolIcon />,
      title: "Total Schools",
      number: 0,
      key: "totalSchools",
    },
    {
      icon: <ActiveSchoolsIcon />,
      title: "Active Schools",
      number: 0,
      key: "activeSchools",
    },
    {
      icon: <InactiveSchoolsIcon />,
      title: "Inactive Schools",
      number: 0,
      key: "inactiveSchools",
    },
    {
      icon: <NewSchoolsIcon />,
      title: "New Schools(This Month)",
      number: 0,
      key: "schoolsRegisteredThisMonth",
    },
  ],
  oneSchoolPieChart: [
    {
      icon: <SchoolStudentIcon />,
      title: "Total Students",
      number: "0",
      key: "totalStudents",
    },
    {
      icon: <SchoolTeacherIcon />,
      title: "Teachers",
      number: "0",
      key: "totalTeachers",
    },
    {
      icon: <SchoolCourseIcon />,
      title: "Games/ Course added",
      number: "0",
      key: "totalCourses",
    },
  ],
};

export const schoolReducers = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SCHOOL_FAIL:
      return { ...state, error: action.payload };
    case ADD_SCHOOL_SUCCESS:
      return { ...state, error: null, success: action.payload };
    case ADD_SCHOOL_PIECHART_FAIL:
      return { ...state, error: action.payload };
    case ADD_SCHOOL_PIECHART_SUCCESS:
      return { ...state, error: null, piechartData: action.payload };
    case GET_SCHOOLS_SUCCESS:
      return {
        ...state,
        error: null,
        schoolsList: action.payload.schools,
        totalCount: action.payload.totalCount,
      };
    case GET_SCHOOLS_FAIL:
      return { ...state, error: action.payload };
    case GET_ONE_SCHOOL_SUCCESS:
      return { ...state, error: null, oneSchool: action.payload };
    case GET_ONE_SCHOOL_FAIL:
      return { ...state, error: action.payload };
    case ADD_ONE_SCHOOL_PIECHART_SUCCESS:
      return { ...state, error: null, oneSchoolPieChart: action.payload };
    case ADD_ONE_SCHOOL_PIECHART_FAIL:
      return { ...state, error: action.payload };
    case GET_SCHOOLS_NAME_LIST_SUCCESS:
      return { ...state, error: null, schoolsNameList: action.payload };
    case GET_SCHOOLS_NAME_LIST_FAIL:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
