import {
  Card,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import React from "react";
import classes from "./roleManagement.module.css";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import SchoolDropDown from "../../../Components/schoolDropDown";
import {
  SearchIcon,
  RoundAddIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import DefaultPagination from "../../../Components/Pagination";
import RoleManagementListItem from "./roleManagementListItem";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setHeaderName,
  setPageValue,
} from "../../../Redux/actions/userActions";
import AlertBox from "../../../Components/AlertBox";
import { useEffect } from "react";
import { getAllRoles } from "../../../Redux/actions/roleManagementAction";
import NodataFound from "../../../Components/nodataFound";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import { GET_ALL_ROLES_SUCCESS } from "../../../Redux/contants/roleManagementConstants";

const RoleManagementTable = ({ value, data, topic }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [school, setSchool] = useState("select");
  const [errorAlert, setErrorAlert] = useState(false);
  const [defaultError, setDefaultError] = useState("");
  const [search, setSearch] = useState("");
  const { sortBy, page } = useSelector((state) => state.userLogin);
  const { error, rolesList, totalCount } = useSelector(
    (state) => state.roleDatas
  );

  useEffect(() => {
    dispatch(setPageValue(1));
  }, []);

  const handleChange = (e) => {
    console.log(e.target.value);
    setSchool(e.target.value);
  };

  const handleAddRoleClick = () => {
    dispatch(setHeaderName("add-new-role"));
    if (topic === "schools") {
      if (school === "select") {
        setErrorAlert(true);
        setDefaultError("Please Select Any School");
      } else {
        navigate(`/add-new-role/${topic}-${school}`);
      }
    } else {
      navigate(`/add-new-role/${topic}`);
    }
  };

  useEffect(() => {
    if (topic === "schools" && school !== "select") {
      dispatch(getAllRoles(setErrorAlert, topic, school, search, sortBy, page));
    } else if (topic === "schools" && school === "select") {
      console.log("Trigrgere School without");
      dispatch({
        type: GET_ALL_ROLES_SUCCESS,
        payload: [],
      });
    } else if (topic !== "schools") {
      dispatch(getAllRoles(setErrorAlert, topic, school, search, sortBy, page));
      console.log("This First");
    }
  }, [dispatch, topic, search, school, sortBy, page]);

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  return (
    <div>
      {/* {value === 1 && ( */}
      {/* )} */}
      <Card>
        <Row className="mb-3 mt-3" style={{ padding: "0 20px" }}>
          <Col md={4} className={classes.roleSchoolSelector}>
            <Typography className={classes.formLable}>School Name</Typography>
            <SchoolDropDown
              value={school}
              handleChange={handleChange}
              width={"100%"}
            />
          </Col>
        </Row>
        <div style={{ padding: "20px" }}>
          <div className={`${classes.FilterContainerMain}`}>
            <div className={classes.HeadertextFeildContainer}>
              <div>
                <TextField
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                  }}
                  fullWidth
                  placeholder="Search by Designation, Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  className={`${classes.TextInput} ${classes.searchBar}`}
                  id="firstNameinput"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  size="small"
                />
              </div>
              <div>
                <Button variant="contained" className={classes.searchButton}>
                  <span className={classes.nrmlBtnText}>Search</span>
                </Button>
              </div>
            </div>
            <div
              className="d-flex align-items-center"
              style={{
                columnGap: "10px",
              }}
            >
              <div
                className={`${classes.DividerClasses} ${classes.vertical}`}
              ></div>
              <Button
                variant="contained"
                className={`d-flex align-items-center ${classes.searchButton}`}
                onClick={() => handleAddRoleClick()}
              >
                <span style={{ marginRight: "10px" }}>
                  <RoundAddIcon />
                </span>
                <span className={classes.mainBtnText}>Add Role</span>
              </Button>
            </div>
          </div>
        </div>
        {totalCount && totalCount > 0 ? (
          <div>
            <div className={`${classes.TableContainer}`}>
              <Table className={classes.TableClass}>
                <TableHead>
                  <TableRow className={classes.TableHeaderRow}>
                    <TableCell
                      flex={1}
                      className={classes.firstCell}
                      style={{ minWidth: 85 }}
                    >
                      <span className={classes.TableHeaderText}>#No</span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "createdAt")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("createdAt") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    {value !== 1 && (
                      <TableCell flex={1} style={{ minWidth: 125 }}>
                        <span className={classes.TableHeaderText}>Name</span>
                        <span
                          onClick={() =>
                            handleSortClick(dispatch, sortBy, "name")
                          }
                        >
                          {sortBy?.includes("desc") &&
                          sortBy?.includes("name") ? (
                            <SortIconOpposite />
                          ) : (
                            <SortIcon />
                          )}
                        </span>
                      </TableCell>
                    )}
                    <TableCell flex={1} style={{ minWidth: 125 }}>
                      <span className={classes.TableHeaderText}>
                        Designation
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(
                            dispatch,
                            sortBy,
                            "designationInfo.name"
                          )
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("designationInfo") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: 125 }}>
                      <span className={classes.TableHeaderText}>
                        Access Level
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "accessInfo.name")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("accessInfo") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell style={{ width: "60px" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rolesList &&
                    rolesList.length > 0 &&
                    rolesList?.map((item, index) => {
                      return (
                        <RoleManagementListItem
                          item={item}
                          key={index}
                          index={index + 1 + (page - 1) * 10}
                          value={value}
                          topic={topic}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <div className={`mt-3`}>
              <DefaultPagination count={totalCount} />
            </div>
          </div>
        ) : (
          <div>
            <NodataFound
              text={
                school !== "select"
                  ? "Please add roles to view"
                  : "Please Select Schools to view"
              }
            />
          </div>
        )}
      </Card>
      {errorAlert && defaultError && (
        <AlertBox type="error" message={defaultError} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default RoleManagementTable;
