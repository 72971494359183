module.exports = {
  ADD_SCHOOL_SUCCESS: "ADD_SCHOOL_SUCCESS",
  ADD_SCHOOL_FAIL: "ADD_SCHOOL_FAIL",
  GET_SCHOOLS_SUCCESS: "GET_SCHOOLS_SUCCESS",
  GET_SCHOOLS_FAIL: "GET_SCHOOLS_FAIL",
  GET_ONE_SCHOOL_SUCCESS: "GET_ONE_SCHOOL_SUCCESS",
  GET_ONE_SCHOOL_FAIL: "GET_ONE_SCHOOL_FAIL",
  ADD_SCHOOL_PIECHART_SUCCESS: "ADD_SCHOOL_PIECHART_SUCCESS",
  ADD_SCHOOL_PIECHART_FAIL: "ADD_SCHOOL_PIECHART_FAIL",
  ADD_ONE_SCHOOL_PIECHART_SUCCESS: "ADD_ONE_SCHOOL_PIECHART_SUCCESS",
  ADD_ONE_SCHOOL_PIECHART_FAIL: "ADD_ONE_SCHOOL_PIECHART_FAIL",
  GET_SCHOOLS_NAME_LIST_SUCCESS: "GET_SCHOOLS_NAME_LIST_SUCCESS",
  GET_SCHOOLS_NAME_LIST_FAIL: "GET_SCHOOLS_NAME_LIST_FAIL",
};
