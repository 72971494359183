import {
  Box,
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
} from "@mui/material";
import classes from "./coupon.module.css";
import { DeleteBinIcon, EditPenIcon, RoundAddIcon, ThreeDotMenuIcon } from "../../../Assets/Icons/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCoupon, getAllCoupons } from "../../../Redux/actions/couponsAction";
import { StyledMenu } from "../../../Components/CommonMenu";
import { useNavigate } from "react-router-dom";
import SchoolActionModals from "../../../Components/SchoolActionModals";
import AlertBox from "../../../Components/AlertBox";
import dayjs from "dayjs";

function Coupons() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [myCoupon, setMyCoupon] = useState(null);
    const [addSuccess, setAddSuccess] = useState("");
    const [addError, setAddError] = useState("");
    const [modalToggle, setModalToggle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allCoupons, allCouponsErr } = useSelector(
    (state) => state.couponDatas
  );
  const open = Boolean(anchorEl);
  console.log("allCoupons =", allCoupons);

  const handleClick = (event,datas) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMyCoupon(datas);
  };

  const handleSnackClose = () => {
    setAddSuccess("");
    setAddError("");
    navigate("/coupons");
  };

  const handleReloadDatas = () => {
    
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  function handleGetAllCoupons() {
    dispatch(getAllCoupons());
  }

  const modalClose = () => {
    setMyCoupon(null);
    setAnchorEl(null);
    setModalToggle(false);
  };

  const handleDeleteClick = () =>{
    setAnchorEl(null);
    dispatch(deleteCoupon(myCoupon?._id,setAddSuccess, setAddError));
    modalClose();
  }

  useEffect(() => {
    handleGetAllCoupons();
  }, []);

  return (
    <Box className={classes.coupon_container}>
      <Box className={classes.row_one}>
        <Typography className={classes.table_title}>All Coupons</Typography>
        <Button
          variant="contained"
          className={`d-flex align-items-center ${classes.searchButton}`}
          onClick={() =>  navigate("/add-coupon")}
        >
          <span style={{ marginRight: "10px" }}>
            <RoundAddIcon />
          </span>
          <span className={classes.mainBtnText}>Add Coupon</span>
        </Button>
      </Box>
      <Box mt={4}>
      <Table className={classes.TableClass}>
        <TableHead>
          <TableRow className={classes.TableHeaderRow}>
          <TableCell
              flex={1}
            >
              <span className={classes.TableHeaderText}>#No</span>
            </TableCell>
            <TableCell
              flex={1}
            >
              <span className={classes.TableHeaderText}>Coupon Name</span>
            </TableCell>
            <TableCell flex={1}>
              <span className={classes.TableHeaderText}>Coupon Code</span>
            </TableCell>
            <TableCell flex={1} >
              <span className={classes.TableHeaderText}>User Type</span>
            </TableCell>
            <TableCell flex={1} >
              <span className={classes.TableHeaderText}>Amount</span>
            </TableCell>
            <TableCell flex={1} >
              <span className={classes.TableHeaderText}>Start Date</span>
            </TableCell>
            <TableCell flex={1} >
              <span className={classes.TableHeaderText}>End Date</span>
            </TableCell>
            <TableCell flex={1} >
              <span className={classes.TableHeaderText}>Action</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
       {Array.isArray(allCoupons)&&
       allCoupons.map((datas,index)=>(
        <TableRow
        key={index}
        className={classes.TableBodyRow}
      >
        <TableCell flex={1} className={classes.firstCell}>
          <Typography className={classes.TableBodyText}>
           {index+1}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography
            className={classes.TableBodyText}
          >{datas.coupon_name}</Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography
            className={classes.TableBodyText}
            style={{ textTransform: "capitalize" }}
          >
            {datas.coupon_code}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
          {datas.user_type}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography
            className={classes.TableBodyText}
            style={{ textTransform: "capitalize" }}
          >
             {datas.discount}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {datas.start_date ?   dayjs(datas.start_date).format("DD-MMMM-YYYY") : "-"}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
          {datas.end_date ?   dayjs(datas.end_date).format("DD-MMMM-YYYY") : "-"}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={(e)=>handleClick(e,datas)}
            variant="text"
            style={{
              minWidth: 0,
            }}
          >
            <ThreeDotMenuIcon />
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={()=>navigate("/add-coupon",{state:{singleCoupon:myCoupon}})}
              disableRipple
              style={{ height: "40px" }}
            >
              <EditPenIcon />
              <span className={classes.MenuItemText}>Edit Details</span>
            </MenuItem>
            <MenuItem
              onClick={()=>setModalToggle(true)}
              disableRipple
              style={{ height: "40px" }}
            >
              <DeleteBinIcon />
              <span className={classes.MenuItemText}>Delete</span>
            </MenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>
       ))
       }
        </TableBody>
      </Table>
      </Box>
      {modalToggle && (
        <SchoolActionModals
          type={"delete"}
          height={205}
          modalClose={modalClose}
          item={""}
          name={myCoupon?.coupon_name ?? ""}
          callback={handleDeleteClick}
        />
      )}
      {addSuccess && (
        <AlertBox
          type="success"
          message={addSuccess}
          stateName={handleSnackClose}
          callback={handleReloadDatas}
        />
      )}
    </Box>
  );
}

export default Coupons;
