import {
  InputAdornment,
  TextField,
  MenuItem,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Divider,
  Typography,
} from "@mui/material";
import classes from "./student.module.css";
import React, { useEffect } from "react";
import {
  ChipCancelIcon,
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import { useState } from "react";
import StudentTableListItem from "./StudentTableListItem";
import DefaultPagination from "../../../Components/Pagination";
import MultiSelectSchoolDropdown from "../../../Components/MultiSelectSchoolDropdown";
import { useDispatch, useSelector } from "react-redux";
import { getAllStudents } from "../../../Redux/actions/studentAction";
import AlertBox from "../../../Components/AlertBox";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import NodataFound from "../../../Components/nodataFound";
import { setPageValue } from "../../../Redux/actions/userActions";
import { PAGINATION_VALUE } from "../../../Redux/contants/userConstants";

const StudentsTable = ({ value }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const [isActive, setIsActive] = useState("all");
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const { schoolsNameList } = useSelector((state) => state.schoolDatas);
  const [errorAlert, setErrorAlert] = useState(false);
  const { error, studentsList, totalCount } = useSelector(
    (state) => state.studentDatas
  );
  const { classesList } = useSelector((state) => state.classDatas);
  const [selectedSchoolIds, setSelectedSchoolIds] = useState([]);
  const { sortBy, page } = useSelector((state) => state.userLogin);

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  useEffect(() => {
    dispatch(setPageValue(1));
  }, []);

  const handleChange = (e) => {
    setIsActive(e.target.value);
  };

  useEffect(() => {
    const userEntry = {
      schools: selectedSchoolIds,
      classes: selectedClasses,
      section: selectedSection,
    };
    if (value === 0) {
      dispatch(
        getAllStudents(
          setErrorAlert,
          search,
          true,
          userEntry,
          sortBy,
          page,
          isActive
        )
      );
    }
    if (value === 1) {
      dispatch(
        getAllStudents(
          setErrorAlert,
          search,
          false,
          userEntry,
          sortBy,
          page,
          isActive
        )
      );
    }
  }, [
    dispatch,
    value,
    selectedSchoolIds,
    selectedClasses,
    selectedSection,
    sortBy,
    page,
    search,
    isActive,
  ]);

  useEffect(() => {
    dispatch(setPageValue(1));
  }, [search]);

  const handleSearch = (event) => {
    const userEntry = {
      schools: selectedSchoolIds,
      classes: selectedClasses,
      section: selectedSection,
    };
    if (value === 0) {
      dispatch(
        getAllStudents(setErrorAlert, search, true, userEntry, sortBy, page)
      );
    }
    if (value === 1) {
      dispatch(
        getAllStudents(setErrorAlert, search, false, userEntry, sortBy, page)
      );
    }
  };

  const handleCheckboxChange = (event) => {
    const selectedValue = event.target.value;
    const {
      target: { value },
    } = event;
    console.log(selectedValue);
    if (value.includes("all")) {
      setSelectedSchools([]);
      setSelectedSchoolIds([]);
    } else {
      setSelectedSchools(value);
      const arr = value.map((item) => {
        return item._id;
      });
      setSelectedSchoolIds(arr);
    }
  };

  useEffect(() => {
    getClassesList();
    getSectionList();
  }, [classesList]);

  const handleClassSelect = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setSelectedClasses(value);
  };

  const handleSectionSelect = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setSelectedSection(value);
  };

  const handleDelete = (e, value) => {
    console.log("clicked delete");
    console.log("Value ==> ", value);
    const array = selectedSchools.filter((item) => item !== value);
    setSelectedSchools(array);
  };

  const getClassesList = () => {
    const array = classesList.map((item) => {
      return item.grade;
    });
    // console.log(array);
    const resultArray = array.filter((value, index, self) => {
      return (
        value !== undefined && value !== "" && self.indexOf(value) === index
      );
    });
    // console.log(resultArray);
    setClassList(resultArray);
  };

  const getSectionList = () => {
    const array = classesList.map((item, index) => {
      let arr1 = [];
      item.terminology.map((terminology, index1) => {
        arr1.push(terminology.section);
      });
      return arr1;
    });
    const flatarR = [].concat(...array);
    // console.log(flatarR);
    const resultArray = flatarR.filter((value, index, self) => {
      return (
        value !== undefined && value !== "" && self.indexOf(value) === index
      );
    });
    setSectionList(resultArray);
  };

  return (
    <div>
      <div className={`${classes.FilterMainContainer}`}>
        <div className={`${classes.FilterSubContainer}`}>
          <div>
            <TextField
              sx={{
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
              fullWidth
              placeholder="Search by Student Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.searchBar}
              id="firstNameinput"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="small"
            />
          </div>
          <div>
            <Button
              variant="contained"
              className={classes.searchButton}
              style={{
                width: "110px",
                height: "40px",
              }}
              onClick={() => {
                handleSearch();
              }}
            >
              <span className={classes.nrmlBtnText}>Search</span>
            </Button>
          </div>
        </div>
        <div className={`${classes.FilterSubContainer}`}>
          {value === 0 && (
            <div>
              <MultiSelectSchoolDropdown
                value={selectedSchools}
                handleChange={handleCheckboxChange}
                multiple
                width={"250px"}
                list={schoolsNameList}
                type={"School"}
              />
            </div>
          )}
          <div>
            <MultiSelectSchoolDropdown
              value={selectedClasses}
              handleChange={handleClassSelect}
              multiple
              width={"140px"}
              list={classList}
              type={"Grade"}
            />
          </div>
          {value === 0 ? (
            <div>
              <MultiSelectSchoolDropdown
                value={selectedSection}
                handleChange={handleSectionSelect}
                multiple
                width={"140px"}
                list={sectionList}
                type={"Section"}
              />
            </div>
          ) : null}
          <div>
            <TextField
              select
              value={isActive}
              onChange={handleChange}
              sx={{
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
                width: "161px",
              }}
              size="small"
              className={
                isActive === "all"
                  ? `${classes.defaultMenuItem} ${classes.schoolFilterDropdown}`
                  : `${classes.schoolFilterDropdown}`
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography className={classes.SortByLable}>
                      Filter by:
                    </Typography>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="all" style={{ display: "none" }}>
                <em>All</em>
              </MenuItem>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
          </div>
        </div>
      </div>
      {selectedSchools && selectedSchools.length > 0 && (
        <div className={`mt-3`}>
          <Divider className={classes.divider} />
          <div className={`${classes.ChipContainer} mt-2`}>
            <Typography className={classes.MenuItemText}>Schools:</Typography>
            {selectedSchools.map((item, index) => (
              <Chip
                key={index}
                label={item.name}
                clickable
                style={{
                  background: `var(--school-chip-color)`,
                }}
                onDelete={(e) => handleDelete(e, item)}
                deleteIcon={
                  <span
                    onClick={(e) => handleDelete(e, item)}
                    className={classes.ChipDeleteIcon}
                  >
                    <ChipCancelIcon />
                  </span>
                }
                className={`${classes.StatusChip} ${classes.SchoolStatusChip}`}
              />
              // <Chip
              //   style={{
              //     background: `var(--school-chip-color)`,
              //   }}
              //   onDelete={handleDelete}
              //   deleteIcon={<ChipCancelIcon />}
              //   label={item.name}
              //   className={classes.StatusChip}
              // />
            ))}
          </div>
        </div>
      )}
      {totalCount && totalCount > 0 ? (
        <div>
          <div className={`mt-3 mb-3 ${classes.TableContainer}`}>
            <Table className={classes.TableClass}>
              <TableHead>
                <TableRow className={classes.TableHeaderRow}>
                  <TableCell
                    flex={1}
                    className={classes.firstCell}
                    style={{ minWidth: "135px" }}
                  >
                    <span className={classes.TableHeaderText}>
                      Student Name
                    </span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "first_name")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("first_name") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: "80px" }}>
                    <span className={classes.TableHeaderText}>Age</span>
                    <span
                      onClick={() => handleSortClick(dispatch, sortBy, "age")}
                    >
                      {sortBy?.includes("desc") && sortBy?.includes("age") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: "90px" }}>
                    <span className={classes.TableHeaderText}>Grade</span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "gradeInfo.grade")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("gradeInfo.grade") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: "270px" }}>
                    <span className={classes.TableHeaderText}>
                      {value === 0 ? "School Name" : " Parent Name"}
                    </span>
                    {value === 0 ? (
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "schoolInfo.name")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("schoolInfo.name") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "parent.name")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("parent.name") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    )}
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: "95px" }}>
                    <span className={classes.TableHeaderText}>Section</span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "section")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("section") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: "185px" }}>
                    <span className={classes.TableHeaderText}>
                      Parent Contact number
                    </span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "parent.phone")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("parent.phone") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1}>
                    <span className={classes.TableHeaderText}>
                      Parent Email ID
                    </span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "parent.email")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("parent.email") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1}>
                    <span className={classes.TableHeaderText}>Status</span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "isActive")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("isActive") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentsList?.map((item, index) => {
                  return (
                    <StudentTableListItem
                      item={item}
                      key={index}
                      value={value}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <DefaultPagination count={totalCount} />
        </div>
      ) : (
        <div>
          <NodataFound text={"Please add students to view"} />
        </div>
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default StudentsTable;
