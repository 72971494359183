import React, { useRef, useState } from "react";
import { Modal, Button, Typography, Link, Box } from "@mui/material";
import {
  CloseIcon,
  ModalHeartIcon,
  ModalVectorDesign,
  ReminderClockIcon,
  SuccessIcon,
} from "../Assets/Icons/icons";
import classes from "../Pages/Screens/Schools/school.module.css";
import { useDispatch } from "react-redux";
import { pageLoader } from "../Redux/actions/userActions";
import { changeSchoolStatus } from "../Redux/actions/schoolActions";

const SchoolActionModals = ({
  type,
  height,
  modalClose,
  item,
  callback,
  name,
}) => {
  const rootRef = useRef(null);
  const dispatch = useDispatch();
  const [modalToggle, setModalToggle] = useState(true);
  const [errorAlert, setErrorAlert] = useState(false);

  function closeModal(message) {
    modalClose(errorAlert);
    setModalToggle(false);
  }

  const handleClickNoKeep = () => {
    closeModal();
  };

  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => closeModal()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          top: "10%",
          height: "fit-content",
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{ height: height }}
          className={classes.SchoolActionModalsWidth}
        >
          <div className={classes.forgetPasswordmodalclose}>
            <Button
              variant="text"
              sx={{ minWidth: "unset" }}
              className={"closebtn"}
              onClick={() => closeModal()}
            >
              <CloseIcon />
            </Button>
          </div>
          <span
            style={{
              position: "absolute",
              top: 0,
            }}
          >
            <ModalVectorDesign />
          </span>
          {type === "Reminder" && (
            <div className={classes.ModelContentContainer}>
              <span className={classes.modalReminderClock}>
                <ReminderClockIcon />
              </span>
              <Typography className={classes.reminderHeading}>
                Reminder has been sent for Renew subscription of Level 1
              </Typography>
            </div>
          )}
          {type === "active/inactive" && (
            <div className={classes.ModelContentContainer}>
              <Typography className={classes.ActiveModalHeader}>
                {`Are you sure you want to ${
                  item.isActive ? "Inactive" : "Active"
                } ${name}?`}
              </Typography>
              <div className={classes.btnSection}>
                <Button
                  variant="outlined"
                  className={classes.FooterBtn}
                  style={{
                    color: "var(--forgot-password-text-color)",
                    borderColor: "var(--forgot-password-text-color)",
                  }}
                  onClick={() => handleClickNoKeep()}
                >
                  No, Keep
                </Button>
                <Button
                  variant="contained"
                  className={classes.FooterBtn}
                  style={{
                    background: "var(--forgot-password-text-color)",
                  }}
                  onClick={() => callback()}
                >
                  {`Yes, ${item.isActive ? "Inactive" : "Active"}`}
                </Button>
              </div>
            </div>
          )}
          {type === "delete" && (
            <div className={classes.ModelContentContainer}>
              <Typography className={classes.ActiveModalHeader}>
                {`Are you sure you want to delete ${name}?`}
              </Typography>
              <div className={classes.btnSection}>
                <Button
                  variant="outlined"
                  className={classes.FooterBtn}
                  style={{
                    color: "var(--forgot-password-text-color)",
                    borderColor: "var(--forgot-password-text-color)",
                  }}
                  onClick={() => handleClickNoKeep()}
                >
                  No, Keep
                </Button>
                <Button
                  variant="contained"
                  className={classes.FooterBtn}
                  style={{
                    background: "var(--forgot-password-text-color)",
                  }}
                  onClick={() => callback()}
                >
                  {`Yes, Delete`}
                </Button>
              </div>
            </div>
          )}
          <span
            style={{
              position: "absolute",
              bottom: 15,
              left: 15,
            }}
          >
            <ModalHeartIcon />
          </span>
        </Box>
      </Modal>
    </div>
  );
};

export default SchoolActionModals;
