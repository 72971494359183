import React, { useEffect, useState } from "react";
import classes from "./student.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeaderName } from "../../../Redux/actions/userActions";
import {
  BrudCrumsNextIcon,
  CheckCircleIcon,
  DownloadCertificateIcon,
  EditPenIcon,
} from "../../../Assets/Icons/icons";
import { Box, Card, Typography, LinearProgress } from "@mui/material";
import { SchoolStudentData } from "../../../Utilities/TableDummyDatas";
import { Col, Row } from "react-bootstrap";
import game1 from "../../../Assets/Images/game1.png";
import game2 from "../../../Assets/Images/game2.png";
import game3 from "../../../Assets/Images/game3.png";
import game4 from "../../../Assets/Images/game4.png";
import ResetPasswordModal from "./resetPasswordModal";
import {
  getAllStudents,
  getOneStudent,
} from "../../../Redux/actions/studentAction";
import AlertBox from "../../../Components/AlertBox";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "110px", mr: 1 }}>
        <LinearProgress
          className={classes.Studentprogress}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography className={classes.TableBodyText}>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const StudentsViewPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resetModal, setResetModal] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const { error, oneStudent } = useSelector((state) => state.studentDatas);
  const { success } = useSelector((state) => state.userLogin);

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    dispatch(getOneStudent(arr[arr.length - 1], setErrorAlert));
  }, []);

  const CloseAlert = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
  };

  const handleNavigateStudent = () => {
    dispatch(setHeaderName("students"));
    navigate("/students");
  };

  const handleResetPassword = () => {
    setResetModal(true);
  };

  const handleEditStudentDetails = () => {
    dispatch(setHeaderName("edit-student-details"));
    navigate(`/edit-student-details/${oneStudent._id}`);
  };

  const ModalClose = () => {
    setResetModal(false);
  };

  return (
    <div>
      <div className={`${classes.BrudcrumbContainer}`}>
        <div className={classes.brudcrumbs}>
          <span
            className={classes.TableHeaderText}
            onClick={() => handleNavigateStudent()}
          >
            Students
          </span>
          <span>
            <BrudCrumsNextIcon />
          </span>
          <span className={`${classes.textLable}`}>Add Student</span>
        </div>
        <div
          className={classes.brudcrumbs}
          onClick={() => {
            handleEditStudentDetails();
          }}
        >
          <span>
            <EditPenIcon />
          </span>
          <span className={`${classes.TableBodyText} ${classes.editText}`}>
            Edit Details
          </span>
        </div>
      </div>
      {oneStudent && (
        <div>
          <Card
            className={`mt-3`}
            style={{ padding: "20px", textAlign: "left" }}
          >
            <Typography className={classes.StudentPageMainHeading}>
              Student Details
            </Typography>
            <Row>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    School name
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent?.schoolInfo?.name}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Student Name
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {`${oneStudent?.first_name} ${oneStudent?.last_name}`}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Age
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent?.age}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Subscription level Selected by
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0, textTransform: "capitalize" }}
                  >
                    {oneStudent?.subscribed_by?.toLowerCase()}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Select Subscription Level
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent?.subscriptionInfo?.subscription_name}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Grade
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0, textTransform: "capitalize" }}
                  >
                    {oneStudent?.gradeInfo?.grade?.charAt(0).toUpperCase() +
                      oneStudent?.gradeInfo?.grade
                        ?.slice(1)
                        .toLowerCase()
                        ?.replaceAll("_", " ")}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Section
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0, textTransform: "capitalize" }}
                  >
                    {oneStudent?.section?.charAt(0).toUpperCase() +
                      oneStudent?.section
                        ?.slice(1)
                        .toLowerCase()
                        ?.replaceAll("_", " ")}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Interested Section
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0, textTransform: "capitalize" }}
                  >
                    {oneStudent?.interest?.replaceAll("_", " ")}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Completed Game
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent.completed_game
                      ? oneStudent.completed_game
                      : "-"}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Progress
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    <LinearProgressWithLabel value={30} />
                  </Typography>
                </div>
              </Col>
            </Row>
          </Card>
          <Card
            className={`mt-3`}
            style={{ padding: "20px", textAlign: "left" }}
          >
            <Typography className={classes.StudentPageMainHeading}>
              Parents Details
            </Typography>
            <Row>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Parent Name
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent?.parent?.name}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Email ID
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent?.parent?.email}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Contact Number
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    +44 {oneStudent?.parent?.phone}
                  </Typography>
                </div>
              </Col>
            </Row>
          </Card>
          <Card
            className={`mt-3`}
            style={{ padding: "20px", textAlign: "left" }}
          >
            <Typography className={classes.StudentPageMainHeading}>
              Account Details
            </Typography>
            <Row>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Account Email ID
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    {oneStudent?.parent?.email}
                  </Typography>
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-3">
                  <Typography className={`${classes.textLable}`}>
                    Password
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    XXXXXXXX{" "}
                    <span
                      className={classes.resetPasswordText}
                      onClick={handleResetPassword}
                    >
                      Reset Password
                    </span>
                  </Typography>
                </div>
              </Col>
            </Row>
          </Card>
          <Row className={`mt-3 ${classes.certificatesContainer}`}>
            <Col md={6} style={{ height: "100%" }}>
              <Card
                style={{ padding: "20px", textAlign: "left" }}
                className={classes.certigicateCard}
              >
                <Typography className={classes.StudentPageMainHeading}>
                  Achievements
                </Typography>
                {!oneStudent.achievements ? (
                  <div className={classes.certificatesContainer}>
                    <div
                      className={`d-flex justify-content-between align-items-center`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={game1}
                          alt="Computer Programming"
                          className={classes.GamesImages}
                        />
                        <div>
                          <Typography
                            className={classes.StudentPageMainHeading}
                          >
                            Computer Programming 2
                          </Typography>
                          <Typography className={classes.TableBodyText}>
                            Acheived On Jan 2022
                          </Typography>
                        </div>
                      </div>
                      <Typography className={classes.downloadCertificateText}>
                        <DownloadCertificateIcon />
                        <span className={classes.textInvisible}>
                          Download Certificate
                        </span>
                      </Typography>
                    </div>
                    <div
                      className={`d-flex justify-content-between align-items-center`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={game2}
                          alt="Computer Programming"
                          className={classes.GamesImages}
                        />
                        <div>
                          <Typography
                            className={classes.StudentPageMainHeading}
                          >
                            Information Technology
                          </Typography>
                          <Typography className={classes.TableBodyText}>
                            Achieved On Jan 2022
                          </Typography>
                        </div>
                      </div>
                      <Typography className={classes.downloadCertificateText}>
                        <DownloadCertificateIcon />
                        <span className={classes.textInvisible}>
                          Download Certificate
                        </span>
                      </Typography>
                    </div>
                    <div
                      className={`d-flex justify-content-between align-items-center`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={game3}
                          alt="Computer Programming"
                          className={classes.GamesImages}
                        />
                        <div>
                          <Typography
                            className={classes.StudentPageMainHeading}
                          >
                            Alogorithm
                          </Typography>
                          <Typography className={classes.TableBodyText}>
                            Acheived On Jan 2022
                          </Typography>
                        </div>
                      </div>
                      <Typography className={classes.downloadCertificateText}>
                        <DownloadCertificateIcon />
                        <span className={classes.textInvisible}>
                          Download Certificate
                        </span>
                      </Typography>
                    </div>
                    <div
                      className={`d-flex justify-content-between align-items-center`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={game4}
                          alt="Computer Programming"
                          className={classes.GamesImages}
                        />
                        <div>
                          <Typography
                            className={classes.StudentPageMainHeading}
                          >
                            Computer Programming 2
                          </Typography>
                          <Typography className={classes.TableBodyText}>
                            Acheived On Jan 2022
                          </Typography>
                        </div>
                      </div>
                      <Typography className={classes.downloadCertificateText}>
                        <DownloadCertificateIcon />
                        <span className={classes.textInvisible}>
                          Download Certificate
                        </span>
                      </Typography>
                    </div>
                    <div
                      className={`d-flex justify-content-between align-items-center`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={game3}
                          alt="Computer Programming"
                          className={classes.GamesImages}
                        />
                        <div>
                          <Typography
                            className={classes.StudentPageMainHeading}
                          >
                            Alogorithm
                          </Typography>
                          <Typography className={classes.TableBodyText}>
                            Acheived On Jan 2022
                          </Typography>
                        </div>
                      </div>
                      <Typography className={classes.downloadCertificateText}>
                        <DownloadCertificateIcon />
                        <span className={classes.textInvisible}>
                          Download Certificate
                        </span>
                      </Typography>
                    </div>
                    <div
                      className={`d-flex justify-content-between align-items-center`}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={game4}
                          alt="Computer Programming"
                          className={classes.GamesImages}
                        />
                        <div>
                          <Typography
                            className={classes.StudentPageMainHeading}
                          >
                            Computer Programming 2
                          </Typography>
                          <Typography className={classes.TableBodyText}>
                            Acheived On Jan 2022
                          </Typography>
                        </div>
                      </div>
                      <Typography className={classes.downloadCertificateText}>
                        <DownloadCertificateIcon />
                        <span className={classes.textInvisible}>
                          Download Certificate
                        </span>
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <Typography>No Achievements</Typography>
                )}
              </Card>
            </Col>
            <Col md={6} style={{ height: "100%" }}>
              <Card
                className={classes.SecondCard}
                style={{ padding: "20px", textAlign: "left", height: "100%" }}
              >
                <Typography className={classes.StudentPageMainHeading}>
                  Subscription Information
                </Typography>
                <div className="mt-4 d-flex justify-content-between">
                  <Typography className={`${classes.textLable}`}>
                    Subscription Level:{" "}
                    <span>
                      {oneStudent?.subscriptionInfo?.subscription_name}
                    </span>
                  </Typography>
                  <Typography className={classes.SomeMoneyText}>
                    £{oneStudent?.subscriptionInfo?.price}{" "}
                    <span>/ {oneStudent?.subscriptionInfo?.duration}</span>
                  </Typography>
                </div>
                <Typography className={`mt-3 ${classes.textLable}`}>
                  Subscription Details
                </Typography>
                <div className={`mt-3 ${classes.certificatesContainer}`}>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    <span style={{ marginRight: "10px" }}>
                      <CheckCircleIcon />
                    </span>
                    3 Unlocked Topics
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    <span style={{ marginRight: "10px" }}>
                      <CheckCircleIcon />
                    </span>
                    Lorem ipsum dolor sit amet
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    <span style={{ marginRight: "10px" }}>
                      <CheckCircleIcon />
                    </span>
                    Lorem ipsum dolor sit amet
                  </Typography>
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 0 }}
                  >
                    <span style={{ marginRight: "10px" }}>
                      <CheckCircleIcon />
                    </span>
                    Lorem ipsum dolor sit amet
                  </Typography>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      {resetModal && (
        <ResetPasswordModal
          modalClose={ModalClose}
          height={"434px"}
          width={"368px"}
          email={oneStudent?.parent?.email}
          setSuccessAlert={setSuccessAlert}
        />
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox type="success" message={success} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default StudentsViewPage;
