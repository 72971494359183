import { setSortName } from "../Redux/actions/userActions";

export const Numeric = (event) => {
  var x = event.charCode || event.keyCode;
  if (x > 47 && x < 58) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};
export const Alphabetic = (event) => {
  var x = event.charCode || event.keyCode;
  if (
    (x > 96 && x < 123) ||
    (x > 64 && x < 91) ||
    x === 32 ||
    x === 46 || // dot "."
    x === 44 || // comma ","
    x === 95 || // underscore "_"
    x === 45
  ) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

export const AlphaNumeric = (event) => {
  var x = event.charCode || event.keyCode;
  if (
    (x > 96 && x < 123) ||
    (x > 64 && x < 91) ||
    x === 32 ||
    (x > 47 && x < 58) ||
    x === 46 || // dot "."
    x === 44 || // comma ","
    x === 95 || // underscore "_"
    x === 45
  ) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

export const generateRandomString = (length = 10)=> {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}



export const generatePassword = (name, setPassword) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@$&*?";

  let password = "";

  // Ensure at least one uppercase letter, one lowercase letter, one symbol, and one number
  password += getRandomChar("ABCDEFGHIJKLMNOPQRSTUVWXYZ"); // Uppercase letter
  password += getRandomChar("abcdefghijklmnopqrstuvwxyz"); // Lowercase letter
  password += getRandomChar("!@$&*?"); // Symbol
  password += getRandomChar("0123456789"); // Number

  // Add the user's name to the password
  password += name.slice(0, 3); // Add the first three characters of the name (adjust as needed)

  // Fill the rest of the password with random characters
  for (let i = password.length; i < 8; i++) {
    password += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }

  // Shuffle the password to randomize the order
  password = password
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  console.log(password);
  setPassword(password);
};

function getRandomChar(characters) {
  return characters.charAt(Math.floor(Math.random() * characters.length));
}

export const calculatePasswordStrength = (password) => {
  let strength = 0;
  if (password.length >= 10) {
    strength += 10;
  }
  if (password.length >= 9) {
    strength += 10;
  }
  if (password.length >= 8) {
    strength += 10;
  }
  if (password.length >= 7) {
    strength += 10;
  }
  if (password.length >= 6) {
    strength += 10;
  }
  if (password.length >= 5) {
    strength += 10;
  }
  if (/[A-Z]/.test(password)) {
    strength += 10;
  }
  if (/[a-z]/.test(password)) {
    strength += 10;
  }
  if (/[0-9]/.test(password)) {
    strength += 10;
  }
  if (/[!@#$%^&*]/.test(password)) {
    strength += 10;
  }
  return strength;
};

// Function to determine the strength label
export const getStrengthLabel = (strength) => {
  if (strength < 20) {
    return "Very Poor";
  } else if (strength < 40) {
    return "Poor";
  } else if (strength < 60) {
    return "Average";
  } else if (strength < 80) {
    return "Better";
  } else if (strength < 100) {
    return "Strong";
  } else {
    return "Too Strong";
  }
};

// Function to determine the color based on strength
export const getStrengthColor = (strength) => {
  if (strength < 10) {
    return "red";
  } else if (strength < 20) {
    return "darkred";
  } else if (strength < 30) {
    return "orangered";
  } else if (strength < 40) {
    return "darkorange";
  } else if (strength < 50) {
    return "orange";
  } else if (strength < 60) {
    return "gold";
  } else if (strength < 70) {
    return "darkkhaki";
  } else if (strength < 80) {
    return "lightgreen";
  } else {
    return "green";
  }
};

export const handleSortClick = (dispatch, sortBy, name) => {
  // console.log(name);
  const namearr = name?.split("-");
  const sortByarr = sortBy?.split("-");
  console.log("namearr =",namearr);
  console.log("sortByarr =",sortByarr);
  // console.log(sortByarr);
  if (sortByarr && sortByarr.length > 0 && namearr[0] === sortByarr[0]) {
    if (sortByarr[1] === "asc") {
      dispatch(setSortName(`${namearr[0]}-desc`));
    } else {
      console.log("condition 3");
      dispatch(setSortName(`${namearr[0]}-asc`));
    }
  } else {
    dispatch(setSortName(`${name}-desc`));
  }
};
