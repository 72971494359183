import { api, authApi, getAuthorizationHeader } from "../../Api/api";
import {
  ADD_ONE_SCHOOL_PIECHART_FAIL,
  ADD_ONE_SCHOOL_PIECHART_SUCCESS,
  ADD_SCHOOL_FAIL,
  ADD_SCHOOL_PIECHART_FAIL,
  ADD_SCHOOL_PIECHART_SUCCESS,
  ADD_SCHOOL_SUCCESS,
  GET_ONE_SCHOOL_FAIL,
  GET_ONE_SCHOOL_SUCCESS,
  GET_SCHOOLS_FAIL,
  GET_SCHOOLS_NAME_LIST_FAIL,
  GET_SCHOOLS_NAME_LIST_SUCCESS,
  GET_SCHOOLS_SUCCESS,
} from "../contants/schoolConstants";
import { pageLoader } from "./userActions";

export const addSchool =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.post("/school-admin/create", userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_SCHOOL_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: ADD_SCHOOL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const updateSchool =
  (id, userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.patch(`/school-admin/update`, userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_SCHOOL_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: ADD_SCHOOL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const changeSchoolStatus =
  (id, array, userEntry, setErrorAlert, setSuccessAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.patch(`/school-admin/school-status`, userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_SCHOOL_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        dispatch(getAllSchools(setErrorAlert));
        dispatch(getSchoolsPieChart(setErrorAlert, array));
      }
    } catch (error) {
      dispatch({
        type: ADD_SCHOOL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const deleteSchool =
  (id, array, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.delete(`/school-admin/delete/?Id=${id}`, {}, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_SCHOOL_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        dispatch(getAllSchools(setErrorAlert));
        dispatch(getSchoolsPieChart(setErrorAlert, array));
      }
    } catch (error) {
      dispatch({
        type: ADD_SCHOOL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getAllSchools = (setErrorAlert, status, search, sort, page) => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/school-admin/list?search=${search || ""}&status=${status || 'all'}&page=${page || 1}&limit=10&sort=${sort || ""}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      }
    });
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_SCHOOLS_SUCCESS,
        payload: data.data[0],
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SCHOOLS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
}

export const getSchoolNamesList = (setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/school-admin/get-school-names`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      }
    });
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_SCHOOLS_NAME_LIST_SUCCESS,
        payload: data.data[0],
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SCHOOLS_NAME_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
}

export const getSchoolsPieChart = (setErrorAlert, array) => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/school-admin/school-piechart`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    let newData = [];
    Object.keys(data.data)?.map((item) => {
      const obj = array.find((element) => {
        if (element.key === item) {
          return element
        }
      })
      newData.push({
        ...obj,
        number: data.data[item]
      })
    })

    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: ADD_SCHOOL_PIECHART_SUCCESS,
        payload: newData,
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_SCHOOL_PIECHART_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
}

export const getOneSchoolsPieChart = (id, setErrorAlert, array) => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/school-admin/one-school-piechart/${id}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    let newData = [];
    Object.keys(data.data)?.map((item) => {
      const obj = array.find((element) => {
        if (element.key === item) {
          return element
        }
      })
      newData.push({
        ...obj,
        number: data.data[item]
      })
    })

    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: ADD_ONE_SCHOOL_PIECHART_SUCCESS,
        payload: newData,
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_ONE_SCHOOL_PIECHART_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
}

export const getOneSchools = (id, setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/school-admin/school/?Id=${id}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_ONE_SCHOOL_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ONE_SCHOOL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
}
