import { authApi, getAuthorizationHeader } from "../../Api/api";
import {
  ADD_STUDENT_FAIL,
  ADD_STUDENT_PIECHART_FAIL,
  ADD_STUDENT_PIECHART_SUCCESS,
  ADD_STUDENT_SUCCESS,
  GET_ONE_STUDENT_FAIL,
  GET_ONE_STUDENT_SUCCESS,
  GET_STUDENTS_FAIL,
  GET_STUDENTS_SUCCESS,
} from "../contants/studentConstants";
import { pageLoader } from "./userActions";

export const addStudent =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.post("/student-admin/create", userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      console.log(data);
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_STUDENT_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        console.log("success", data);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: ADD_STUDENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const updateStudent =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.patch("/student-admin/update", userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      console.log(data);
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_STUDENT_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        console.log("success", data);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: ADD_STUDENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getAllStudents =
  (setErrorAlert, search, isSchool, userEntry, sort, page, active) =>
  async (dispatch) => {
    try {
      const { data } = await authApi.post(
        `/student-admin/get-students/?search=${search || ""}&page=${
          page || 1
        }&limit=10&isSchool=${isSchool}&sort=${sort || ""}&active=${
          active !== "all" && active !== "undefined" && active !== undefined
            ? active
            : ""
        }`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: GET_STUDENTS_SUCCESS,
          payload: data.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_STUDENTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getOneStudent = (id, setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/student-admin/get-student/${id}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_ONE_STUDENT_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ONE_STUDENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
};

export const deleteStudent =
  (id, setSuccessAlert, setErrorAlert, array) => async (dispatch) => {
    try {
      const { data } = await authApi.delete(
        `/student-admin/delete-student/?Id=${id}`,
        {},
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_STUDENT_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        dispatch(getAllStudents(setErrorAlert, "", true));
        dispatch(getStudentPiechart(setErrorAlert, array));
      }
    } catch (error) {
      dispatch({
        type: ADD_STUDENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getStudentPiechart =
  (setErrorAlert, array) => async (dispatch) => {
    try {
      const { data } = await authApi.get(`/student-admin/student-piechart`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      console.log(data);
      console.log(array);
      let newData = [];
      Object.keys(data.data)?.map((item) => {
        const obj = array.find((element) => {
          if (element.key === item) {
            return element;
          }
        });
        newData.push({
          ...obj,
          number: data.data[item],
        });
      });

      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_STUDENT_PIECHART_SUCCESS,
          payload: newData,
        });
      }
    } catch (error) {
      dispatch({
        type: ADD_STUDENT_PIECHART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const changeStudentStatus =
  (id, userEntry, setErrorAlert, setSuccessAlert, array) =>
  async (dispatch) => {
    try {
      const { data } = await authApi.patch(
        `/student-admin/student-status`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_STUDENT_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        dispatch(getAllStudents(setErrorAlert, "", true));
        dispatch(getStudentPiechart(setErrorAlert, array));
      }
    } catch (error) {
      dispatch({
        type: ADD_STUDENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };
