import React from "react";
import { useEffect } from "react";
import classes from "./classManagement.module.css";
import { classManagementDetails } from "../../../Utilities/TableDummyDatas";
import { useState } from "react";
import { BrudCrumsNextIcon, EditPenIcon } from "../../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import { Card, Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import AlertBox from "../../../Components/AlertBox";
import { getOneClass } from "../../../Redux/actions/classActions";

const ClassDetailsViewPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, oneClass } = useSelector((state) => state.classDatas);
  const [errorAlert, setErrorAlert] = useState(false);
  const [schoolName, setSchoolName] = useState("");

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    dispatch(pageLoader(true));
    dispatch(getOneClass(arr[arr.length - 1], setErrorAlert));
  }, []);

  const handleNavigateclassManageMennt = () => {
    navigate("/class-management");
    dispatch(setHeaderName("class-management"));
  };

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  const handleEditClassDetails = () => {
    dispatch(setHeaderName("edit-class"));
    navigate(`/edit-class/${oneClass._id}`);
  };

  return (
    <div>
      <div className={`${classes.BrudcrumbContainer}`}>
        <div className={classes.brudcrumbs}>
          <span
            className={classes.TableHeaderText}
            onClick={() => handleNavigateclassManageMennt()}
          >
            Class Management
          </span>
          <span>
            <BrudCrumsNextIcon />
          </span>
          {oneClass && (
            <span
              className={`${classes.TableBodyText} ${classes.HeadingText}`}
              style={{ textTransform: "capitalize" }}
            >
              {oneClass?.grade.charAt(0).toUpperCase() +
                oneClass?.grade.slice(1).toLowerCase().replaceAll("_", " ")}
            </span>
          )}
        </div>
        <div
          className={classes.brudcrumbs}
          onClick={() => {
            handleEditClassDetails();
          }}
        >
          <span>
            <EditPenIcon />
          </span>
          <span className={classes.TableBodyText}>Edit Details</span>
        </div>
      </div>
      <Card
        className="mt-3"
        style={{
          padding: 16,
        }}
      >
        <Typography className={classes.PageMaindiv}>Class Details</Typography>
        <Row className="mt-3">
          <Col md={4}>
            <Typography className={classes.textLable}>School Name</Typography>
            <Typography
              className={classes.MenuItemText}
              style={{ marginLeft: 0 }}
            >
              {oneClass?.school?.name}
            </Typography>
          </Col>
          <Col md={4}>
            <Typography className={classes.textLable}>Terminology</Typography>
            <Typography
              className={classes.MenuItemText}
              style={{ marginLeft: 0 }}
            >
              {oneClass?.type.charAt(0).toUpperCase() +
                oneClass?.type.slice(1).toLowerCase().replaceAll("_", " ")}
            </Typography>
          </Col>
          <Col md={4}>
            <Typography className={classes.textLable}>Grade name</Typography>
            <Typography
              className={classes.MenuItemText}
              style={{ marginLeft: 0, textTransform: "capitalize" }}
            >
              {oneClass?.grade.charAt(0).toUpperCase() +
                oneClass?.grade.slice(1).toLowerCase().replaceAll("_", " ")}
            </Typography>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={4}>
            <Typography className={classes.textLable}>Section</Typography>
            <Typography
              className={classes.MenuItemText}
              style={{ marginLeft: 0, textTransform: "capitalize" }}
            >
              {oneClass?.terminology.map((item, index) => {
                console.log(oneClass?.terminology?.length - 1);
                if (index !== oneClass?.terminology?.length - 1) {
                  return (
                    item.section.charAt(0).toUpperCase() +
                    item.section.slice(1).toLowerCase().replaceAll("_", " ") +
                    ", "
                  );
                } else {
                  return (
                    item.section.charAt(0).toUpperCase() +
                    item.section.slice(1).toLowerCase().replaceAll("_", " ")
                  );
                }
              })}
            </Typography>
          </Col>
        </Row>
      </Card>
      {errorAlert && error ? (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      ) : null}
    </div>
  );
};

export default ClassDetailsViewPage;
