import {
  HEADER_NAME,
  PAGE_LOADER,
  PAGINATION_VALUE,
  SHOW_ERROR,
  SORT_BY_VALUE,
  TOGGLE_SIDEBAR,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_OTP,
  SHOW_LOGOUT_MODAL,
  ALL_PAYMENT_LIST,
} from "../contants/userConstants";

const initialState = {
  loading: false,
  userInfo: null,
  error: null,
  success: null,
  headerName: "dashboard",
  toggleSideBar: false,
  showLogoutModal: false,
  showError: false,
  sortBy: null,
  page: 1,
  paymentList: null,
};

export const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_LOADER:
      return { ...state, loading: action.payload };
    case USER_LOGIN_SUCCESS:
      return { ...state, userInfo: action.payload, error: null };
    case USER_LOGIN_FAIL:
      return { ...state, error: action.payload };
    case HEADER_NAME:
      return { ...state, headerName: action.payload };
    case TOGGLE_SIDEBAR:
      return { ...state, toggleSideBar: action.payload };
    case SHOW_ERROR:
      return { ...state, showError: action.payload };
    case USER_RESET_PASSWORD_FAIL:
      return { ...state, error: action.payload };
    case USER_RESET_PASSWORD_SUCCESS:
      return { ...state, error: null, success: action.payload };
    case SORT_BY_VALUE:
      return { ...state, sortBy: action.payload };
    case PAGINATION_VALUE:
      return { ...state, page: action.payload };
    case FORGOT_PASSWORD_OTP:
      return {
        ...state, forgot_otp: action.payload
      };
    case SHOW_LOGOUT_MODAL:
      return {
        ...state, showLogoutModal: action.payload
      }
    case ALL_PAYMENT_LIST:
      return {
        ...state, paymentList: action.payload
      }
    default:
      return state;
  }
};
