import React, { useEffect, useState } from "react";
import classes from "./index.module.css";
import { Button } from "@mui/material";
import Offcanvas from "react-bootstrap/Offcanvas";
import { MenuBarIcons } from "../Assets/Icons/icons";
import { Typography } from "@mui/material";
import mainLogo from "../Assets/Images/MainLogo.png";
import SideNavBar from "./SideNavBar";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderName, setToggleSidebar } from "../Redux/actions/userActions";

const Header = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { headerName, toggleSideBar } = useSelector((state) => state.userLogin);

  const handleClose = (e) => {
    setShow(false);
  };
  const handleShow = () => {
    console.log(!toggleSideBar);
    dispatch(setToggleSidebar(!toggleSideBar));
    setShow(true);
  };

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    if (arr[1] === "cources") {
      dispatch(setHeaderName("Courses/Games"));
    } else {
      dispatch(setHeaderName(arr[1]));
    }
  }, [window.location.pathname]);

  return (
    <div>
      <div className={classes.HeaderMaindiv}>
        <Button variant="text" onClick={handleShow} style={{ minWidth: 0 }}>
          <MenuBarIcons />
        </Button>
        <Offcanvas
          show={show}
          onHide={handleClose}
          className={classes.offcanvas}
          scroll={false}
          backdrop={false}
        >
          <Offcanvas.Header closeButton closeVariant="white">
            <Offcanvas.Title>
              <img
                src={mainLogo}
                alt="Metaverse Logo"
                className={`${classes.imageLogo}`}
              />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <SideNavBar device={"mobile"} handleClose={handleClose} />
          </Offcanvas.Body>
        </Offcanvas>

        <Typography className={classes.HeaderText}>
          {headerName?.replaceAll("-", " ")}
        </Typography>
      </div>
    </div>
  );
};

export default Header;
