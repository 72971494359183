import {
  TextField,
  Typography,
  InputAdornment,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import classes from "./courses.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import DefaultPagination from "../../../Components/Pagination";
import {
  CourseTableData,
  schoolDetails,
} from "../../../Utilities/TableDummyDatas";
import CourseListItem from "./CourseListItem";
import { getAllCourses } from "../../../Redux/actions/courseAction";
import AlertBox from "../../../Components/AlertBox";
import NodataFound from "../../../Components/nodataFound";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import { setPageValue } from "../../../Redux/actions/userActions";

const CourseTablePage = ({ topic, gradeLevel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isActive, setIsActive] = useState("all");
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const { error, success, coursesList, totalCount } = useSelector(
    (state) => state.courseDatas
  );
  const { page, sortBy } = useSelector((state) => state.userLogin);

  useEffect(() => {
    dispatch(setPageValue(1));
  }, []);

  const handleChange = (e) => {
    setIsActive(e.target.value);
  };

  useEffect(() => {
    dispatch(
      getAllCourses(
        setErrorAlert,
        topic,
        page,
        sortBy,
        search,
        isActive,
        gradeLevel
      )
    );
  }, [dispatch, topic, page, sortBy, search, isActive, gradeLevel, search]);

  const CloseAlert = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <Typography className={`mt-2 ${classes.TotalText}`}>
            Total Games <span>{totalCount}</span>
          </Typography>
        </Col>
        <Col md={6}>
          <div className={`${classes.FilterSubContainer} mt-0`}>
            <div>
              <TextField
                sx={{
                  "& legend": {
                    display: "none",
                  },
                  "& fieldset": {
                    top: 0,
                  },
                }}
                fullWidth
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                className={classes.searchBar}
                id="firstNameinput"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="small"
              />
            </div>
            <div>
              <TextField
                select
                value={isActive}
                onChange={handleChange}
                sx={{
                  "& legend": {
                    display: "none",
                  },
                  "& fieldset": {
                    top: 0,
                  },
                }}
                size="small"
                className={
                  isActive === "all"
                    ? `${classes.defaultMenuItem} ${classes.schoolFilterDropdown}`
                    : `${classes.schoolFilterDropdown}`
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography className={classes.SortByLable}>
                        Sort by:
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="all">
                  <em>All</em>
                </MenuItem>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </TextField>
            </div>
          </div>
        </Col>
      </Row>
      <Card className="mt-4">
        {totalCount && totalCount > 0 ? (
          <div>
            <div className={`mb-3 ${classes.TableContainer}`}>
              <Table className={classes.TableClass}>
                <TableHead>
                  <TableRow className={classes.TableHeaderRow}>
                    <TableCell flex={1} style={{ minWidth: 125 }}>
                      <span className={classes.TableHeaderText}>Game Name</span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "gameInfo.name")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("gameInfo.name") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: 125 }}>
                      <span className={classes.TableHeaderText}>
                        Subscription
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(
                            dispatch,
                            sortBy,
                            "subscriptionInfo.subscription_name"
                          )
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes(
                          "subscriptionInfo.subscription_name"
                        ) ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: 125 }}>
                      <span className={classes.TableHeaderText}>
                        Grade Level
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "gradeLevel")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("gradeLevel") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: 135 }}>
                      <span className={classes.TableHeaderText}>
                        Total Diamond
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "gameInfo.diamonds")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("gameInfo.diamonds") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: 125 }}>
                      <span className={classes.TableHeaderText}>
                        No. of Level
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "level")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("level") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1}>
                      <span className={classes.TableHeaderText}>Status</span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "isActive")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("isActive") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell style={{ width: "60px" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coursesList &&
                    coursesList.length > 0 &&
                    coursesList.map((item, index) => {
                      return <CourseListItem item={item} key={index} />;
                    })}
                </TableBody>
              </Table>
            </div>
            <DefaultPagination count={totalCount} />
          </div>
        ) : (
          <NodataFound text={"Please add Games to view in this Topic"} />
        )}
      </Card>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox type="success" message={success} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default CourseTablePage;
