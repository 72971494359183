import { ADD_SUBSCRIPTION_FAIL, ADD_SUBSCRIPTION_SUCCESS, GET_ALL_SUBSCRIPTION_FAIL, GET_ALL_SUBSCRIPTION_SUCCESS, GET_ONE_SUBSCRIPTION_FAIL, GET_ONE_SUBSCRIPTION_SUCCESS } from "../contants/subscriptionContants";

const initialState = {
    error: null,
    success: null,
    subscriptionList: [],
    oneSubscription: null,
    totalCount: 0,
};

export const subscriptionReducers = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SUBSCRIPTION_FAIL:
            return { ...state, error: action.payload };
        case ADD_SUBSCRIPTION_SUCCESS:
            return { ...state, error: null, success: action.payload };
        case GET_ALL_SUBSCRIPTION_SUCCESS:
            return { ...state, error: null, subscriptionList: action.payload.subscriptions, totalCount: action.payload.totalCount };
        case GET_ALL_SUBSCRIPTION_FAIL:
            return { ...state, error: action.payload, totalCount: 0 };
        case GET_ONE_SUBSCRIPTION_SUCCESS:
            return { ...state, error: null, oneSubscription: action.payload };
        case GET_ONE_SUBSCRIPTION_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
}