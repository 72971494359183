import { ActiveSchoolsIcon, DashboardStudentIcon, InactiveSchoolsIcon } from "../../Assets/Icons/icons";
import {
    ADD_STUDENT_FAIL,
    ADD_STUDENT_PIECHART_FAIL,
    ADD_STUDENT_PIECHART_SUCCESS,
    ADD_STUDENT_SUCCESS,
    GET_ONE_STUDENT_FAIL,
    GET_ONE_STUDENT_SUCCESS,
    GET_STUDENTS_FAIL,
    GET_STUDENTS_SUCCESS
} from "../contants/studentConstants";

const initialState = {
    error: null,
    success: null,
    studentsList: [],
    oneStudent: null,
    totalCount: 0,
    piechartData: [
        {
            icon: <DashboardStudentIcon />,
            title: "Total Students",
            number: "0",
            key: "totalStudents",
        },
        {
            icon: <ActiveSchoolsIcon />,
            title: "Active Students",
            number: "0",
            key: "activeStudents",
        },
        {
            icon: <InactiveSchoolsIcon />,
            title: "Inactive Students",
            number: "0",
            key: "inactiveStudents",
        },
    ]
};

export const studentReducers = (state = initialState, action) => {
    switch (action.type) {
        case ADD_STUDENT_FAIL:
            return { ...state, error: action.payload };
        case ADD_STUDENT_SUCCESS:
            return { ...state, error: null, success: action.payload };
        case GET_STUDENTS_SUCCESS:
            return { ...state, error: null, studentsList: action.payload.students, totalCount: action.payload.totalCount };
        case GET_STUDENTS_FAIL:
            return { ...state, error: action.payload };
        case GET_ONE_STUDENT_SUCCESS:
            return { ...state, error: null, oneStudent: action.payload };
        case GET_ONE_STUDENT_FAIL:
            return { ...state, error: action.payload };
        case ADD_STUDENT_PIECHART_FAIL:
            return { ...state, error: action.payload };
        case ADD_STUDENT_PIECHART_SUCCESS:
            return { ...state, error: null, piechartData: action.payload };
        default:
            return state;
    }
};