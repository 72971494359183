import React, { useState, useEffect } from "react";
import { StudentStatisticData } from "../../../Utilities/StatisticConstants";
import StatisticsCard from "../../../Components/statisticsCard";
import { Card, Typography, Box, Tab, Tabs, Button } from "@mui/material";
import PropTypes from "prop-types";
import classes from "./student.module.css";
import StudentsTable from "./StudentsTable";
import { RoundAddIcon } from "../../../Assets/Icons/icons";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllClasses } from "../../../Redux/actions/classActions";
import {
  getAllSchools,
  getSchoolNamesList,
} from "../../../Redux/actions/schoolActions";
import AlertBox from "../../../Components/AlertBox";
import { getStudentPiechart } from "../../../Redux/actions/studentAction";
import { PAGINATION_VALUE } from "../../../Redux/contants/userConstants";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StudentsIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [errorAlert, setErrorAlert] = useState(false);
  const { error, piechartData } = useSelector((state) => state.studentDatas);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        index === value
          ? `${classes.selectedTab} ${classes.TabsLableHeader}`
          : classes.TabsLableHeader,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch({
      type: PAGINATION_VALUE,
      payload: 1,
    });
  };

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  const handleAddStudentClick = () => {
    dispatch(setHeaderName("add-student"));
    if (value === 0) {
      navigate("/add-student/school");
    } else {
      navigate("/add-student/individual");
    }
  };

  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(getSchoolNamesList(setErrorAlert));
    dispatch(getAllClasses(setErrorAlert, "", "all"));
    dispatch(getStudentPiechart(setErrorAlert, piechartData));
  }, []);

  return (
    <div>
      <StatisticsCard data={piechartData} />
      <Card className={`mt-3`}>
        <div style={{ padding: "24px 24px 0" }}>
          <Typography className={classes.StudentPageMainHeading}>
            All student Data
          </Typography>
          <Box
            sx={{
              width: "100%",
              marginTop: 2,
              position: "relative",
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "var(--forgot-password-text-color)",
                    height: "4px",
                  },
                }}
                className={classes.tabsContainer}
                aria-label="basic tabs example"
              >
                <Tab label="Subscription by School" {...a11yProps(0)} />
                <Tab label="Subscription by Individual" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel
              value={value}
              index={0}
              className={`${classes.tabPanel} mb-2`}
            ></CustomTabPanel>
            <CustomTabPanel
              value={value}
              index={1}
              className={`${classes.tabPanel} mb-2`}
            ></CustomTabPanel>
            <div className={classes.addBtnContainer}>
              <Button
                variant="contained"
                className={`d-flex align-items-center ${classes.searchButton}`}
                onClick={() => handleAddStudentClick()}
              >
                <span style={{ marginRight: "10px" }}>
                  <RoundAddIcon />
                </span>
                <span className={classes.mainBtnText}>Add New Student</span>
              </Button>
            </div>
          </Box>
        </div>
        <StudentsTable value={value} />
        {errorAlert && error && (
          <AlertBox type="error" message={error} stateName={CloseAlert} />
        )}
      </Card>
    </div>
  );
};

export default StudentsIndex;
