import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userReducers } from "./reducers/userReducers";
import { schoolReducers } from "./reducers/schoolReducers";
import { classReducers } from "./reducers/classRedusers";
import { studentReducers } from "./reducers/studentReducers";
import { subscriptionReducers } from "./reducers/subscriptionReducers";
import { teacherReducer } from "./reducers/teacherReducers";
import { courseReducer } from "./reducers/courseReducers";
import { roleManagementReducers } from "./reducers/roleManagementReducers";
import { dashboardReducers } from "./reducers/dashboardReducers";
import { couponReducers } from "./reducers/couponReducer";

const reducer = combineReducers({
  userLogin: userReducers,
  dashboardDatas: dashboardReducers,
  schoolDatas: schoolReducers,
  classDatas: classReducers,
  studentDatas: studentReducers,
  subscriptionDatas: subscriptionReducers,
  teacherDatas: teacherReducer,
  courseDatas: courseReducer,
  roleDatas: roleManagementReducers,
  couponDatas: couponReducers,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
