import {
  Box,
  Typography,
  MenuItem,
  Card,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { BrudCrumsNextIcon } from "../../../Assets/Icons/icons";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./coupon.module.css";
import Footer from "../../../Components/Footer";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Col, Row } from "react-bootstrap";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import {
  addNewCoupon,
  updateNewCoupon,
} from "../../../Redux/actions/couponsAction";
import { useDispatch } from "react-redux";
import AlertBox from "../../../Components/AlertBox";
import { generateRandomString } from "../../../Utilities/commonFunctions";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

function AddCoupon() {
  const [inputs, setInputs] = useState({
    coupon_name: "",
    coupon_code: "",
    user_type: "",
    discount: null,
    start_date: null,
    end_date: null,
  });
  const [addSuccess, setAddSuccess] = useState("");
  const [addError, setAddError] = useState("");
  const [errorHelperText, setErrorHelperText] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  let single_coupon = location?.state?.singleCoupon;
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setErrorHelperText(false);
    if (name === "coupon_code") {
      let _sliced = value.slice(0, 10);
      setInputs({ ...inputs, coupon_code: _sliced });
    } else {
      setInputs({ ...inputs, [name]: value });
    }
  };

  const handleSubmit = () => {
    let userEntry = {
      ...inputs,
    };
    userEntry["start_date"] = userEntry["start_date"]
      ? userEntry["start_date"]
      : "";
    userEntry["end_date"] = userEntry["end_date"] ? userEntry["end_date"] : "";
    if (single_coupon) {
      dispatch(
        updateNewCoupon(
          single_coupon?._id,
          userEntry,
          setAddSuccess,
          setAddError
        )
      );
    } else {
      dispatch(addNewCoupon(userEntry, setAddSuccess, setAddError));
    }
  };
  const handleReloadDatas = () => {};
  const handleSnackClose = () => {
    setAddSuccess("");
    setAddError("");
    navigate("/coupons");
  };

  const handleTenDigit = () => {
    let ten_digit = generateRandomString(10);
    setInputs({
      ...inputs,
      coupon_code: ten_digit?.toUpperCase() ?? "",
    });
  };

  useEffect(() => {
    if (single_coupon) {
      setInputs({
        ...inputs,
        coupon_name: single_coupon?.coupon_name ?? "",
        coupon_code: single_coupon?.coupon_code ?? "",
        user_type: single_coupon?.user_type ?? "",
        discount: single_coupon?.discount ?? null,
        start_date: single_coupon?.start_date ?? null,
        end_date: single_coupon?.end_date ?? null,
      });
    } else {
      let ten_digit = generateRandomString(10);
      setInputs({
        ...inputs,
        coupon_code: ten_digit?.toUpperCase() ?? "",
      });
    }
  }, []);
  return (
    <Box>
      <div className={classes.brudcrumbs}>
        <span
          className={classes.TableHeaderText}
          onClick={() => navigate("/coupons")}
        >
          Coupons
        </span>
        <span>
          <BrudCrumsNextIcon />
        </span>
        <span className={`${classes.TableBodyText} ${classes.HeadingText}`}>
          {single_coupon ? `Edit Coupon` : "Add Coupon"}
        </span>
      </div>
      <ValidatorForm
        onSubmit={handleSubmit}
        useref="form"
        className={classes.AddStudentForm}
      >
        <Card className={`mt-3`} style={{ padding: "20px", textAlign: "left" }}>
          <Typography className={classes.StudentPageMainHeading}>
            Coupon Details
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Row className="mt-3">
              <Col md={4}>
                <div>
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingText}`}
                  >
                    Coupon Name
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="studentnameInput"
                    fullWidth
                    placeholder="Enter Coupon name"
                    name="coupon_name"
                    className={classes.TextInput}
                    value={inputs.coupon_name}
                    onChange={handleInputs}
                    size="small"
                    validators={["required"]}
                    errorMessages={["Coupon Name is required"]}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingText}`}
                  >
                    Coupon Code
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="studentnameInput"
                    fullWidth
                    placeholder="Enter Coupon code"
                    name="coupon_code"
                    className={classes.TextInput}
                    value={inputs.coupon_code}
                    onChange={handleInputs}
                    size="small"
                    validators={["required"]}
                    errorMessages={["Coupon Code is required"]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.passwordInputAdornment}
                        >
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTenDigit}
                            edge="end"
                          >
                            <RefreshOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingText}`}
                  >
                    Discount
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="studentnameInput"
                    fullWidth
                    placeholder="Enter Discount"
                    name="discount"
                    className={classes.TextInput}
                    value={inputs.discount}
                    onChange={handleInputs}
                    size="small"
                    validators={["required"]}
                    errorMessages={["Discount is required"]}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingText}`}
                  >
                    User Type
                  </Typography>
                  <TextValidator
                    select
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="studentnameInput"
                    fullWidth
                    placeholder="Select grade"
                    // className={
                    //   intrest === "0"
                    //     ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                    //     : `${classes.DropdownTextInput}`
                    // }
                    name="user_type"
                    value={inputs.user_type}
                    onChange={handleInputs}
                    size="small"
                    validators={["required"]} // Add this line
                    errorMessages={["Interested Section is required"]}
                    error={errorHelperText}
                    helperText={
                      errorHelperText && "Interested Section is required"
                    }
                  >
                    <MenuItem value="0" style={{ display: "none" }}>
                      Select User Type
                    </MenuItem>
                    {["B2B", "B2C"].map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </TextValidator>
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingText}`}
                  >
                    From date (Optional)
                  </Typography>
                  <DesktopDatePicker
                    className={`${classes.datePickerInput}`}
                    // slots={{
                    //   openPickerIcon: DatePickerCalendarIcon,
                    // }}
                    value={
                      inputs.start_date
                        ? dayjs(inputs.start_date)
                        : inputs.start_date
                    }
                    onChange={(newValue) =>
                      setInputs({ ...inputs, start_date: newValue })
                    }
                    required
                    format="DD-MM-YYYY"
                  />
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingText}`}
                  >
                    To date (Optional)
                  </Typography>
                  <DesktopDatePicker
                    className={`${classes.datePickerInput}`}
                    // slots={{
                    //   openPickerIcon: DatePickerCalendarIcon,
                    // }}
                    value={
                      inputs.end_date ? dayjs(inputs.end_date) : inputs.end_date
                    }
                    onChange={(newValue) =>
                      setInputs({ ...inputs, end_date: newValue })
                    }
                    required
                    minDate={inputs?.start_date && dayjs(inputs?.start_date)}
                    format="DD-MM-YYYY"
                  />
                </div>
              </Col>
            </Row>
          </LocalizationProvider>
        </Card>
        <Footer
          callback={() => navigate("/coupons")}
          edit={single_coupon ? true : false}
        />
        {/* {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )} */}
        {addSuccess && (
          <AlertBox
            type="success"
            message={addSuccess}
            stateName={handleSnackClose}
            callback={handleReloadDatas}
          />
        )}
      </ValidatorForm>
    </Box>
  );
}

export default AddCoupon;
