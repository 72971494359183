import {
  ActiveSchoolsIcon,
  ActiveTeacherStatIcon,
  DashboardCourseIcon,
  DashboardMenuStatisticIcon,
  DashboardPaymentIcon,
  DashboardSchoolIcon,
  DashboardStudentIcon,
  InactiveSchoolsIcon,
  InactiveTeacherStatIcon,
  NewSchoolsIcon,
  PaymentParentIcon,
  PaymentStatIcon,
  RoleManagementSchoolIcon,
  SchoolCourseIcon,
  SchoolStudentIcon,
  SchoolTeacherIcon,
  TotalTeachersStatIcon,
} from "../Assets/Icons/icons";
import moment from "moment";

export const DashBoardStatisticData = [
  {
    icon: <DashboardSchoolIcon />,
    title: "Total School",
    number: "50",
  },
  {
    icon: <DashboardStudentIcon />,
    title: "Total Student",
    number: "6000",
  },
  {
    icon: <DashboardCourseIcon />,
    title: "Total Games / Course",
    number: "100",
  },
  {
    icon: <DashboardPaymentIcon />,
    title: "Earning",
    number: "96000",
  },
];

export const range = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  "Last 7 Days": [moment().subtract(6, "days"), moment()],
  "Last 30 Days": [moment().subtract(29, "days"), moment()],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment()
      .subtract(1, "month")
      .startOf("month"),
    moment()
      .subtract(1, "month")
      .endOf("month")
  ],
  "Last Year": [
    moment()
      .subtract(1, "year")
      .startOf("year"),
    moment()
      .subtract(1, "year")
      .endOf("year")
  ]
};

export const SchoolStatisticData = [
  {
    icon: <DashboardSchoolIcon />,
    title: "Total School",
    number: 0,
  },
  {
    icon: <ActiveSchoolsIcon />,
    title: "Active School",
    number: 0,
  },
  {
    icon: <InactiveSchoolsIcon />,
    title: "Inactive School",
    number: 0,
  },
  {
    icon: <NewSchoolsIcon />,
    title: "New School(This Month)",
    number: 0,
  },
];

export const SchoolDetailsPageStatisTicData = [
  {
    icon: <SchoolStudentIcon />,
    title: "Total Student",
    number: "200",
  },
  {
    icon: <SchoolTeacherIcon />,
    title: "Teachers",
    number: "30",
  },
  {
    icon: <SchoolCourseIcon />,
    title: "Games/ Course added",
    number: "30",
  },
];

export const StudentStatisticData = [
  {
    icon: <DashboardStudentIcon />,
    title: "Total Student",
    number: "6000",
  },
  {
    icon: <ActiveSchoolsIcon />,
    title: "Active Students",
    number: "5400",
  },
  {
    icon: <InactiveSchoolsIcon />,
    title: "Inactive Students",
    number: "600",
  },
];

export const TeachersStatisticData = [
  {
    icon: <TotalTeachersStatIcon />,
    title: "Total Teacher",
    number: "14000",
  },
  {
    icon: <ActiveTeacherStatIcon />,
    title: "Active Teacher",
    number: "13000",
  },
  {
    icon: <InactiveTeacherStatIcon />,
    title: "Inactive Teacher",
    number: "1000",
  },
];

export const CourseStatisticData = [
  {
    icon: <SchoolCourseIcon />,
    title: "Total Games",
    number: "30",
  },
  {
    icon: <ActiveSchoolsIcon />,
    title: "Active Games",
    number: "28",
  },
  {
    icon: <InactiveSchoolsIcon />,
    title: "Inactive Games",
    number: "02",
  },
];

export const RoleManagementStatisticData = [
  {
    icon: <TotalTeachersStatIcon />,
    title: "Metaverse",
    number: "30",
  },
  {
    icon: <RoleManagementSchoolIcon />,
    title: "Schools",
    number: "24",
  },
  {
    icon: <DashboardMenuStatisticIcon />,
    title: "Others",
    number: "04",
  },
]

export const PaymentStatisticData = [
  {
    icon: <DashboardSchoolIcon />,
    title: "School Subscription",
    number: "0",
  },
  {
    icon: <PaymentParentIcon />,
    title: "Parents Subscription",
    number: "0",
  },
  {
    icon: <PaymentStatIcon />,
    title: "Individual Subscription",
    number: "0",
  },
]
