import React, { useState } from "react";
import classes from "./subscription.module.css";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputAdornment,
  TextField,
  Button,
} from "@mui/material";
import {
  RoundAddIcon,
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import SubscriptionListItem from "./subscriptionListItem";
import DefaultPagination from "../../../Components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  pageLoader,
  setHeaderName,
  setPageValue,
} from "../../../Redux/actions/userActions";
import { useEffect } from "react";
import { getAllSubscriptions } from "../../../Redux/actions/subscriptionAction";
import AlertBox from "../../../Components/AlertBox";
import { handleSortClick } from "../../../Utilities/commonFunctions";

const SubscriptionMangement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const { error, subscriptionList, totalCount } = useSelector(
    (state) => state.subscriptionDatas
  );
  const { sortBy, page } = useSelector((state) => state.userLogin);

  useEffect(() => {
    dispatch(setPageValue(1));
  }, []);

  const handleAddSubscriptionClick = () => {
    dispatch(setHeaderName("Add Subscription"));
    navigate("/add-subscription");
  };

  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(getAllSubscriptions(setErrorAlert, search, sortBy, page));
  }, [dispatch, search, sortBy, page]);

  const handleSearch = () => {
    dispatch(pageLoader(true));
    dispatch(getAllSubscriptions(setErrorAlert, search, sortBy, 1));
  };

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  return (
    <div>
      <Card>
        <div style={{ padding: "20px" }}>
          <div className={`${classes.FilterContainerMain}`}>
            <div className={classes.HeadertextFeildContainer}>
              <div>
                <TextField
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                  }}
                  fullWidth
                  placeholder="Search by Subscription Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  className={`${classes.TextInput} ${classes.searchBar}`}
                  id="firstNameinput"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  size="small"
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  className={classes.searchButton}
                  onClick={() => handleSearch()}
                >
                  <span className={classes.nrmlBtnText}>Search</span>
                </Button>
              </div>
            </div>
            <div
              className={`d-flex align-items-center ${classes.btnContainer}`}
              style={{
                columnGap: "10px",
              }}
            >
              <div
                className={`${classes.DividerClasses} ${classes.vertical}`}
              ></div>
              <Button
                variant="contained"
                className={`d-flex align-items-center ${classes.searchButton}`}
                onClick={() => handleAddSubscriptionClick()}
              >
                <span style={{ marginRight: "10px" }}>
                  <RoundAddIcon />
                </span>
                <span className={classes.mainBtnText}>Add Subscription</span>
              </Button>
            </div>
          </div>
        </div>
        <div className={`${classes.TableContainer}`}>
          <Table className={classes.TableClass}>
            <TableHead>
              <TableRow className={classes.TableHeaderRow}>
                <TableCell flex={1} className={classes.firstCell}>
                  <span className={classes.TableHeaderText}>#No</span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 160 }}>
                  <span className={classes.TableHeaderText}>
                    Subscription Name
                  </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "subscription_name")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("subscription_name") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 125 }}>
                  <span className={classes.TableHeaderText}>Duration</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "duration")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("duration") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 125 }}>
                  <span className={classes.TableHeaderText}>Price</span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "price")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("price") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 125 }}>
                  <span className={classes.TableHeaderText}>Type</span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "type")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("type") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell style={{ width: "60px" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionList?.map((item, index) => {
                return (
                  <SubscriptionListItem
                    item={item}
                    key={index}
                    index={index + 1 + (page - 1) * 10}
                  />
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div className={`mt-3`}>
          <DefaultPagination count={totalCount} />
        </div>
      </Card>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default SubscriptionMangement;
