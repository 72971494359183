import React, { useRef, useState } from "react";
import { Modal, Button, Typography, Link, Box } from "@mui/material";
import { CloseIcon, SuccessIcon } from "../Assets/Icons/icons";
import classes from "./index.module.css";

const SuccessModal = ({ modalClose, width, height, type, callback }) => {
  const rootRef = useRef(null);
  const [modalToggle, setModalToggle] = useState(true);

  function closeModal(data) {
    modalClose(false);
    setModalToggle(false);
  }

  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => closeModal()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          top: "10%",
          height: "fit-content",
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{ minWidth: width, height: height }}
          className={"loginModalWidth"}
        >
          <div className={classes.forgetPasswordmodalclose}>
            <Button
              variant="text"
              sx={{ minWidth: "unset" }}
              className={"closebtn"}
              onClick={() => closeModal()}
            >
              <CloseIcon />
            </Button>
          </div>
          {type === "forgotPassword" && (
            <div className={classes.ModelContentContainer}>
              <span>
                <SuccessIcon />
              </span>
              <Typography className={classes.successModalHeading}>
                Your password has been reset Successfully!
              </Typography>
              <Typography className={classes.successModalSubHeading}>
                Redirecting to Login Page...{" "}
              </Typography>
            </div>
          )}
          {type === "logout" && (
            <div className={classes.ModelContentContainer}>
              <Typography className={`mt-3 ${classes.successModalHeading}`}>
                Are you sure you want to Logout?
              </Typography>
              <div className={classes.logoutBtnContainer}>
                <Button
                  variant="outlined"
                  style={{ color: "var(--forgot-password-text-color)" }}
                  onClick={() => closeModal()}
                >
                  No, Stay
                </Button>
                <Button
                  variant="contained"
                  style={{ background: "var(--forgot-password-text-color)" }}
                  onClick={() => callback()}
                >
                  Yes, Logout{" "}
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default SuccessModal;
