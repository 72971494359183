import { authApi, getAuthorizationHeader } from "../../Api/api";
import { GET_SCHOOL_ROLES_FAIL, GET_SCHOOL_ROLES_SUCCESS } from "../contants/roleManagementConstants";
import { ADD_DESIGNATION_FAIL, ADD_DESIGNATION_SUCCESS, ADD_TEACHER_FAIL, ADD_TEACHER_SUCCESS, GET_ALL_DESIGNATIONS_FAIL, GET_ALL_DESIGNATIONS_SUCCESS, GET_ONE_TEACHER_FAIL, GET_ONE_TEACHER_SUCCESS, GET_TEACHERS_FAIL, GET_TEACHERS_SUCCESS, TEACHER_PIECHART_FAIL, TEACHER_PIECHART_SUCCESS } from "../contants/teacherConstants";
import { pageLoader } from "./userActions";

export const addTeacher =
    (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
        try {
            const { data } = await authApi.post("/teacher-admin/create",
                userEntry,
                {
                    headers: {
                        Authorization: getAuthorizationHeader(),
                    },
                }
            );
            console.log(data);
            dispatch(pageLoader(false));
            if (data.success) {
                dispatch({
                    type: ADD_TEACHER_SUCCESS,
                    payload: data.message,
                });
                setSuccessAlert(true);
                console.log("success", data);
            }
        } catch (error) {
            console.log("error", error);
            dispatch({
                type: ADD_TEACHER_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            dispatch(pageLoader(false));
            setErrorAlert(true);
        }
    };

export const updateTeacher =
    (id, userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
        try {
            const { data } = await authApi.patch(`/teacher-admin/teacher-update/${id}`, userEntry, {
                headers: {
                    Authorization: getAuthorizationHeader(),
                },
            });
            dispatch(pageLoader(false));
            if (data.success) {
                dispatch({
                    type: ADD_TEACHER_SUCCESS,
                    payload: data.message,
                });
                setSuccessAlert(true);
            }
        } catch (error) {
            dispatch({
                type: ADD_TEACHER_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            dispatch(pageLoader(false));
            setErrorAlert(true);
        }
    };

export const getAllDesignations = (setErrorAlert) => async (dispatch) => {
    try {
        const { data } = await authApi.get("designation/designation-all", {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: GET_ALL_DESIGNATIONS_SUCCESS,
                payload: data.data,
            });
            console.log("success", data);
        }
    } catch (error) {
        console.log("error", error);
        dispatch({
            type: GET_ALL_DESIGNATIONS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}

export const getAllTeachers = (setErrorAlert, search, school, designation, sort, page) => async (dispatch) => {
    try {
        const { data } = await authApi.get(`teacher-admin/list?page=${page || 1}&limit=10&search=${search}&school=${school !== "select" ? school : ""}&designation=${designation !== "select" ? designation : ""}&sort=${sort || ""}`, {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: GET_TEACHERS_SUCCESS,
                payload: data.data,
            });
            console.log("success", data);
        }
    } catch (error) {
        console.log("error", error);
        dispatch({
            type: GET_TEACHERS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}

export const getOneTeacher = (id, setErrorAlert) => async (dispatch) => {
    try {
        const { data } = await authApi.get(`/teacher-admin/get-teacher/${id}`, {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: GET_ONE_TEACHER_SUCCESS,
                payload: data.data[0],
            });
        }
    } catch (error) {
        dispatch({
            type: GET_ONE_TEACHER_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}

export const deleteTeacher =
    (id, setSuccessAlert, setErrorAlert, array) => async (dispatch) => {
        try {
            const { data } = await authApi.delete(`/teacher-admin/teacher-delete/${id}`, {}, {
                headers: {
                    Authorization: getAuthorizationHeader(),
                },
            });
            dispatch(pageLoader(false));
            if (data.success) {
                dispatch({
                    type: ADD_TEACHER_SUCCESS,
                    payload: data.message,
                });
                dispatch(getAllTeachers(setErrorAlert, "", "", ""));
                dispatch(getTeacherPiechart(setErrorAlert, array));
                setSuccessAlert(true);
            }
        } catch (error) {
            dispatch({
                type: ADD_TEACHER_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            dispatch(pageLoader(false));
            setErrorAlert(true);
        }
    };

export const getTeacherPiechart = (setErrorAlert, array) => async (dispatch) => {
    try {
        const { data } = await authApi.get(`/teacher-admin/teacher-piechart`, {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        console.log(data);
        console.log(array);
        let newData = [];
        Object.keys(data.data)?.map((item) => {
            const obj = array.find((element) => {
                if (element.key === item) {
                    return element
                }
            })
            newData.push({
                ...obj,
                number: data.data[item]
            })
        })

        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: TEACHER_PIECHART_SUCCESS,
                payload: newData,
            });
        }
    } catch (error) {
        dispatch({
            type: TEACHER_PIECHART_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}

export const changeTeacherStatus =
    (userEntry, setErrorAlert, setSuccessAlert, array) => async (dispatch) => {
        try {
            const { data } = await authApi.patch(`/teacher-admin/teacher-status`, userEntry, {
                headers: {
                    Authorization: getAuthorizationHeader(),
                },
            });
            dispatch(pageLoader(false));
            if (data.success) {
                dispatch({
                    type: ADD_TEACHER_SUCCESS,
                    payload: data.message,
                });
                setSuccessAlert(true);
                dispatch(getAllTeachers(setErrorAlert, "", "", ""));
                dispatch(getTeacherPiechart(setErrorAlert, array));
            }
        } catch (error) {
            dispatch({
                type: ADD_TEACHER_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            dispatch(pageLoader(false));
            setErrorAlert(true);
        }
    };

export const addNewDesignation =
    (userEntry, setErrorAlert, setSuccessAlert) => async (dispatch) => {
        try {
            const { data } = await authApi.post(`/designation/create-designation`, userEntry, {
                headers: {
                    Authorization: getAuthorizationHeader(),
                },
            });
            dispatch(pageLoader(false));
            if (data.success) {
                dispatch({
                    type: ADD_DESIGNATION_SUCCESS,
                    payload: data.message,
                });
                setSuccessAlert(true);
                dispatch(getAllDesignations(setErrorAlert));
            }
        } catch (error) {
            dispatch({
                type: ADD_DESIGNATION_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
            dispatch(pageLoader(false));
            setErrorAlert(true);
        }
    };

export const getSchoolRoles = (id, setErrorAlert) => async (dispatch) => {
    try {
        const { data } = await authApi.get(`/roles-admin/school-roles/${id}`, {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: GET_SCHOOL_ROLES_SUCCESS,
                payload: data.data,
            });
        }
    } catch (error) {
        dispatch({
            type: GET_SCHOOL_ROLES_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
};