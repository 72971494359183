import { authApi, authImageApi, getAuthorizationHeader } from "../../Api/api";
import { ADD_CLASS_FAIL, GET_ONE_CLASS_FAIL } from "../contants/classConstants";
import {
  ADD_COURSE_FAIL,
  ADD_COURSE_SUCCESS,
  GET_COURSES_FAIL,
  GET_COURSES_SUCCESS,
  GET_COURSE_PIECHART_FAIL,
  GET_COURSE_PIECHART_SUCCESS,
  GET_GAMES_LIST_FAIL,
  GET_GAMES_LIST_SUCCESS,
  GET_ONE_COURSE_SUCCESS,
} from "../contants/courseConstants";
import { pageLoader } from "./userActions";

export const addNewCourse =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.post("/courses/create-course", userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_COURSE_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: ADD_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const updateCourse =
  (id, userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.patch(
        `/courses/update-course/${id}`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_COURSE_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
      }
    } catch (error) {
      dispatch({
        type: ADD_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const deleteCourse =
  (id, setErrorAlert, setSuccessAlert, topic, array) => async (dispatch) => {
    try {
      const { data } = await authApi.delete(
        `/courses/course-delete/${id}`,
        {},
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_COURSE_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        dispatch(getAllCourses(setErrorAlert, topic));
        dispatch(getCoursePiechart(setErrorAlert, array));
      }
    } catch (error) {
      dispatch({
        type: ADD_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const changeCourseStatus =
  (id, topic, setSuccessAlert, setErrorAlert, array) => async (dispatch) => {
    try {
      const { data } = await authApi.patch(`/courses/course-status/${id}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_COURSE_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        dispatch(getAllCourses(setErrorAlert, topic));
        dispatch(getCoursePiechart(setErrorAlert, array));
      }
    } catch (error) {
      dispatch({
        type: ADD_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getAllCourses =
  (setErrorAlert, topic, page, sort, search, isActive, gradeLevel) =>
  async (dispatch) => {
    try {
      const { data } = await authApi.get(
        `/courses/courses-all/${topic}?page=${page || 1}&limit=${10}&sort=${
          sort || ""
        }&search=${search || ""}&status=${
          isActive !== "all" ? isActive : ""
        }&grade=${gradeLevel !== "select" ? gradeLevel : ""}`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: GET_COURSES_SUCCESS,
          payload: data.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_COURSES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
    }
  };

export const getOneCourse = (id, setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/courses/courses-me/${id}`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_ONE_COURSE_SUCCESS,
        payload: data.data[0],
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ONE_CLASS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
};

export const FileUpload = (file) => async (dispatch) => {
  const formData = new FormData();
  console.log("file ===> ", file);

  formData.append("file", file);
  const { data } = await authImageApi.post("/courses/upload-file", formData);
  return data.data;
};

export const GameFileUpload = (file) => async (dispatch) => {
  const formData = new FormData();
  console.log("file ===> ", file);

  formData.append("gameZipFile", file);
  const { data } = await authImageApi.post(
    "/games/upload-game-files",
    formData,
    {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    }
  );
  return data.data;
};

export const addNewGame =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.post("/games/create-game", userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_COURSE_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        dispatch(getAllGamesList());
      }
    } catch (error) {
      dispatch({
        type: ADD_COURSE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getAllGamesList = () => async (dispatch) => {
  try {
    const { data } = await authApi.get("/games/games-all", {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_GAMES_LIST_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_GAMES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
  }
};

export const getCoursePiechart = (setErrorAlert, array) => async (dispatch) => {
  try {
    console.log(array);
    const { data } = await authApi.get(`/courses/course-piechart`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    console.log(data);
    console.log(array);
    let newData = [];
    Object.keys(data.data)?.map((item) => {
      const obj = array?.find((element) => {
        if (element.key === item) {
          return element;
        }
      });
      newData.push({
        ...obj,
        number: data.data[item],
      });
    });

    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_COURSE_PIECHART_SUCCESS,
        payload: newData,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_COURSE_PIECHART_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
};
