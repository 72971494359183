import React, { useEffect } from "react";
import { Pagination } from "@mui/material";
import classes from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setPageValue } from "../Redux/actions/userActions";

const DefaultPagination = ({ count }) => {
  const { page } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();

  const handleChange = (event, value) => {
    console.log(value);
    dispatch(setPageValue(value));
  };

  useEffect(() => {
    // This will update the component whenever the page value in Redux changes.
  }, [page]);

  return (
    <div className="mb-3 d-flex justify-content-center">
      <Pagination
        count={Math.ceil(count / 10)}
        page={page} // Control the selected page number using Redux state
        defaultPage={1}
        showFirstButton
        className={classes.PaginationBtns}
        onChange={handleChange}
        showLastButton
        color="primary"
      />
    </div>
  );
};

export default DefaultPagination;
