import {
  TableCell,
  TableRow,
  Typography,
  Chip,
  Button,
  MenuItem,
  Menu,
} from "@mui/material";
import classes from "./courses.module.css";
import React, { useState } from "react";
import {
  ActiveItemIcon,
  DeleteBinIcon,
  EditPenIcon,
  InactiveItemIcon,
  ReminderClockIcon,
  SwitchIcon,
  ThreeDotMenuIcon,
} from "../../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { StyledMenu } from "../../../Components/CommonMenu";
import SchoolActionModals from "../../../Components/SchoolActionModals";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import {
  changeCourseStatus,
  deleteCourse,
  getAllCourses,
} from "../../../Redux/actions/courseAction";
import AlertBox from "../../../Components/AlertBox";

const CourseListItem = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalToggle, setModalToggle] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [type, setType] = useState("");
  const [height, setHeight] = useState(0);
  const { error, success, piechartData } = useSelector(
    (state) => state.courseDatas
  );
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditCourseDetails = (e) => {
    handleClose(e);
    dispatch(setHeaderName("edit-game-details"));
    navigate(`/edit-game-details/${item?._id}`);
  };

  const handleDelete = (event) => {
    handleClose(event);
    setType("delete");
    setHeight(205);
    setModalToggle(true);
  };

  const handleActive = (event) => {
    handleClose(event);
    setType("active/inactive");
    setHeight(205);
    setModalToggle(true);
  };

  const handleReloadDatas = () => {
    dispatch(getAllCourses(setErrorAlert, item.topic));
  };

  const CloseAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
  };

  const modalClose = () => {
    setModalToggle(false);
  };

  const handleViewSchoolDetails = () => {
    dispatch(setHeaderName("view-game"));
    navigate(`/view-game/${item._id}`);
  };

  const handleDeleteCourse = () => {
    modalClose();
    dispatch(pageLoader(true));
    dispatch(
      deleteCourse(
        item._id,
        setErrorAlert,
        setSuccessAlert,
        item.topic,
        piechartData
      )
    );
    modalClose();
  };

  const handleActiveCourse = () => {
    modalClose();
    dispatch(pageLoader(true));
    dispatch(
      changeCourseStatus(
        item._id,
        item.topic,
        setSuccessAlert,
        setErrorAlert,
        piechartData
      )
    );
    modalClose();
  };

  return (
    <React.Fragment>
      <TableRow
        key={item.id}
        className={classes.TableBodyRow}
        onClick={() => handleViewSchoolDetails()}
      >
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item?.gameInfo?.name}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item?.subscriptionInfo?.subscription_name}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography
            className={classes.TableBodyText}
            style={{ textTransform: "capitalize" }}
          >
            {item?.gradeLevel.charAt(0).toUpperCase() +
              item?.gradeLevel?.slice(1).toLowerCase().replaceAll("_", " ")}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item?.gameInfo?.diamonds}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item?.level.length}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Chip
            icon={item.isActive ? <ActiveItemIcon /> : <InactiveItemIcon />}
            style={{
              background: item.isActive
                ? "var(--active-chip-background)"
                : "var(--inactive-chip-background)",
            }}
            label={item.isActive ? "Active" : "Inactive"}
            className={classes.StatusChip}
          />
        </TableCell>
        <TableCell style={{ width: "fit-content" }}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
            variant="text"
            style={{
              minWidth: 0,
            }}
          >
            <ThreeDotMenuIcon />
          </Button>
          {item?.gameInfo?.name !== "Demo" && (
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={handleEditCourseDetails}
                disableRipple
                style={{ height: "40px" }}
              >
                <EditPenIcon />
                <span className={classes.MenuItemText}>Edit Details</span>
              </MenuItem>
              <MenuItem
                onClick={handleDelete}
                disableRipple
                style={{ height: "40px" }}
              >
                <DeleteBinIcon />
                <span className={classes.MenuItemText}>Remove from Topic</span>
              </MenuItem>
              <MenuItem
                onClick={handleActive}
                disableRipple
                style={{ height: "40px" }}
              >
                <SwitchIcon />
                <span className={classes.MenuItemText}>Active/Inactive</span>
              </MenuItem>
            </StyledMenu>
          )}
        </TableCell>
      </TableRow>
      {modalToggle && (
        <SchoolActionModals
          type={type}
          height={height}
          item={item}
          modalClose={modalClose}
          name={item.gameInfo.name}
          callback={
            type === "delete"
              ? handleDeleteCourse
              : type === "active/inactive"
              ? handleActiveCourse
              : null
          }
        />
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox
          type="success"
          message={success}
          stateName={CloseAlert}
          callback={handleReloadDatas}
        />
      )}
    </React.Fragment>
  );
};

export default CourseListItem;
