import React, { useEffect } from "react";
import classes from "./student.module.css";
import {
  BrudCrumsNextIcon,
  CloseEyeIcon,
  ContentCopyIcon,
  OpenEyeIcon,
  RefreshIcon,
} from "../../../Assets/Icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import {
  Card,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import copy from "copy-to-clipboard";
import { useState } from "react";
import SchoolDropDown from "../../../Components/schoolDropDown";
import { Col, Row } from "react-bootstrap";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Alphabetic,
  Numeric,
  generatePassword,
} from "../../../Utilities/commonFunctions";
import Footer from "../../../Components/Footer";
import {
  addStudent,
  getOneStudent,
  updateStudent,
} from "../../../Redux/actions/studentAction";
import AlertBox from "../../../Components/AlertBox";
import { getAllSubscriptions } from "../../../Redux/actions/subscriptionAction";
import { getAllClasses } from "../../../Redux/actions/classActions";
import { getAllSchools } from "../../../Redux/actions/schoolActions";
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';

const AddStudent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedSchool, setSelectedSchool] = useState("select");
  const [studentName, setStudentName] = useState("");
  const [age, setAge] = useState("");
  const [subscribeBy, setSubscribeBy] = useState("SCHOOL");
  const [subscriptionLevel, setSubscriptionLevel] = useState("0");
  const [showPassword, setShowPassword] = useState(false);
  const [grade, setGrade] = useState("0");
  const [section, setSection] = useState("0");
  const [intrest, setIntrest] = useState("0");
  const [parentName, setParentName] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [parentMobile, setParentMobile] = useState("");
  const [accountEmail, setAccountEmail] = useState("");
  const [generateClicked, setGenerateClicked] = useState(false);
  const [password, setPassword] = useState("");
  const [showSchool, setShowSchool] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorHelperText, setErrorHelperText] = useState(false);
  const [errorHelperText2, setErrorHelperText2] = useState(false);
  const [errorHelperText3, setErrorHelperText3] = useState(false);
  const [accountEmailError, setAccountEmailError] = useState(true);
  const [notEqual, setNotEqual] = useState(false);
  const [defaultSuccess, setDefaultSuccess] = useState("");
  const [defaultSuccessMessage, setDefaultSuccessMessage] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [countryCode, setCountryCode] = useState("1");
  const [selectedCountry, setSelectedCountry] = useState("us");
  const { success, error, oneStudent } = useSelector(
    (state) => state.studentDatas
  );
  const { subscriptionList } = useSelector((state) => state.subscriptionDatas);
  const { classesList } = useSelector((state) => state.classDatas);
  const { schoolsNameList } = useSelector((state) => state.schoolDatas);
  const { page } = useSelector((state) => state.userLogin);
  const adminSchool = JSON.parse(localStorage.getItem("Adminschool"));

  useEffect(() => {
    if (adminSchool && !showSchool) {
      console.log("While Console");
      setSelectedSchool(adminSchool._id);
      setSubscribeBy("PARENT");
    }
  }, [adminSchool, !showSchool]);

  useEffect(() => {
    if (selectedSchool !== "select" && schoolsNameList.length > 0) {
      dispatch(pageLoader(true));
      dispatch(getAllClasses(setErrorAlert, "", selectedSchool));
      const scho = schoolsNameList.find((item) => item._id === selectedSchool);
      console.log("scho?.subscription_id =",scho);
      if (scho) {
        // setSubscriptionLevel(scho?.subscription_id);
      }
    }
  }, [selectedSchool, dispatch, schoolsNameList, subscribeBy]);

  useEffect(() => {
    if (window.location.pathname.includes("edit")) {
      console.log("oneStudent =",oneStudent)
      setSelectedSchool(oneStudent?.school);
      setSubscribeBy(oneStudent?.subscribed_by);
      setStudentName(`${oneStudent?.first_name} ${oneStudent?.last_name}`);
      setAge(oneStudent?.age);
      setSubscriptionLevel(oneStudent?.subscription);
      setGrade(oneStudent?.gradeId);
      setSection(oneStudent?.section);
      setIntrest(oneStudent?.interest);
      setParentName(oneStudent?.parent?.name);
      setParentEmail(oneStudent?.parent?.email);
      setParentMobile(oneStudent?.parent?.phone);
      setAccountEmail(oneStudent?.parent?.email);
      setShowSchool(oneStudent?.gst_type === "B2B");
      setCountryCode(oneStudent?.parent?.country_code);
      setSelectedCountry(oneStudent?.parent?.country_id);
    }
  }, [oneStudent, selectedSchool]); 
  console.log("show school =",showSchool);
  console.log("subscriptionLevel =",subscriptionLevel);
  console.log("schoolsNameList =",schoolsNameList);
  const copyTrst = (text) => {
    copy(text);
    setDefaultSuccess(true);
    setDefaultSuccessMessage("Password has Copied Successfully");
  };

  const handleGeneratePasswordclick = () => {
    setGenerateClicked(true);
    generatePassword(studentName, setPassword);
  };

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    if (window.location.pathname.includes("edit")) {
      const path = window.location.pathname;
      const arr = path.split("/");
      dispatch(pageLoader(true));
      dispatch(getOneStudent(arr[arr.length - 1], setErrorAlert));
    } else {
      if (arr[arr.length - 1] === "school") {
        setSubscribeBy("SCHOOL");
        setShowSchool(true);
      } else {
        setSubscribeBy("PARENT");
        setShowSchool(false);
      }
    }
    dispatch(getAllSubscriptions(setErrorAlert));
    dispatch(getAllSchools(setErrorAlert));
  }, [dispatch]);

  const handleRadioChange = (event) => {
    setSubscribeBy(event.target.value);
  };

  const handleChange = (e) => {
    setSelectedSchool(e.target.value);
    setGrade("0");
    setSection("0");
  };

  const handleNavigateStudent = () => {
    dispatch(setHeaderName("students"));
    navigate("/students");
  };

  const handleSubmit = () => {
    console.log("Console Triggers");
    // if (parentMobile?.length != 10) {
    //   setIsValid(false);
    // } else 
    if (!isValid) {
      setIsValid(false);
    }
    else if (parentEmail !== accountEmail) {
      setAccountEmailError(true);
    }
    else if (intrest === "0") {
      setErrorHelperText(true);
    }
    else if (grade === "0") {
      setErrorHelperText2(true);
    }
    else if (section === "0") {
      setErrorHelperText3(true);
    }
    else if (
      intrest !== "0" &&
      grade !== "0" &&
      section !== "0" &&
      parentEmail === accountEmail
    ) {
      const userEntry = {
        name: studentName,
        age: age.toString(),
        subscribed_by: subscribeBy,
        // subscription_id: subscription_id,
        subscription: subscriptionLevel,
        gradeId: grade,
        interest: intrest,
        parent_name: parentName,
        gst_type: showSchool ? "B2B" : "B2C",
        email: parentEmail,
        phone: parentMobile,
        section: section,
        country_code: countryCode,
        country_id: selectedCountry,
      };
      dispatch(pageLoader(true));
      if (window.location.pathname.includes("edit")) {
        userEntry.Id = oneStudent._id;
        dispatch(updateStudent(userEntry, setSuccessAlert, setErrorAlert));
      } else {
        userEntry.password = password;
        userEntry.school = selectedSchool;
        dispatch(addStudent(userEntry, setSuccessAlert, setErrorAlert));
      }
    }
  };

  const CloseAlert = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
    setDefaultSuccess();
  };

  const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
    setSelectedCountry(countryData.iso2);
    setParentMobile("");
  }

  const handlePhoneNumberChange = (isValidNum, value, country) => {
    setIsValid(isValidNum);
    setCountryCode(country?.dialCode);
    setSelectedCountry(country?.iso2);
    setParentMobile(value);
    console.log('isValid:', isValid);
    console.log('Phone number value:', value);
    console.log('Selected country:', country);
  };

  // useEffect(() => {
  //   classesList.map((item) => item.grade === grade =>item.terminology)
  // },[grade])

  return (
    <div>
      <div className={classes.brudcrumbs}>
        <span
          className={classes.TableHeaderText}
          onClick={() => handleNavigateStudent()}
        >
          Students
        </span>
        <span>
          <BrudCrumsNextIcon />
        </span>
        <span className={`${classes.TableBodyText} ${classes.HeadingText}`}>
          {window.location.pathname.includes("edit")
            ? `${oneStudent?.first_name} ${oneStudent?.last_name}`
            : "Add Student"}
        </span>
      </div>
      <ValidatorForm
        onSubmit={handleSubmit}
        useref="form"
        className={classes.AddStudentForm}
      >
        {showSchool && (
          <Card
            className={`mt-3`}
            style={{ padding: "20px", textAlign: "left" }}
          >
            <Typography className={classes.StudentPageMainHeading}>
              Select School
            </Typography>
            <Row>
              <Col md={4}>
                <div className="mt-3">
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingText}`}
                  >
                    Select School Name
                  </Typography>
                  <SchoolDropDown
                    value={selectedSchool}
                    handleChange={handleChange}
                    width={"100%"}
                    edit={window.location.pathname.includes("edit")}
                  />
                </div>
              </Col>
            </Row>
          </Card>
        )}
        <Card className={`mt-3`} style={{ padding: "20px", textAlign: "left" }}>
          <Typography className={classes.StudentPageMainHeading}>
            Student Details
          </Typography>
          <Row className="mt-3">
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Student Name
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="studentnameInput"
                  fullWidth
                  placeholder="Enter student name"
                  className={classes.TextInput}
                  value={studentName}
                  onChange={(e) => {
                    setStudentName(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    Alphabetic(e);
                  }}
                  size="small"
                  validators={["required", "matchRegexp:[A-Za-z]"]} // Add this line
                  errorMessages={[
                    "Student Name is required",
                    "Not a valid Student Name",
                  ]}
                />
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Age
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="ageInput"
                  fullWidth
                  placeholder="Enter age"
                  className={classes.TextInput}
                  inputProps={{ maxLength: 2 }}
                  value={age}
                  onChange={(e) => {
                    setAge(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    Numeric(e);
                  }}
                  size="small"
                  validators={["required"]} // Add this line
                  errorMessages={["Age is required"]}
                />
              </div>
            </Col>
            {showSchool && (
              <Col md={4}>
                <div>
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingText}`}
                  >
                    Subscription level Selected by
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={subscribeBy}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="SCHOOL"
                      className={classes.FormRadio}
                      control={<Radio />}
                      label="School"
                    />
                    <FormControlLabel
                      value="PARENT"
                      className={classes.FormRadio}
                      control={<Radio />}
                      label="Parent"
                    />
                  </RadioGroup>
                </div>
              </Col>
            )}
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Select Subscription Level
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                    background: "#fff",
                  }}
                  hiddenLabel
                  id="subscriptonLevelInput"
                  fullWidth
                  select
                  value={subscriptionLevel}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSubscriptionLevel(e.target.value);
                  }}
                  size="small"
                  className={
                    subscriptionLevel === "0"
                      ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                      : `${classes.DropdownTextInput}`
                  }
                  validators={["required"]} // Add this line
                  errorMessages={["Subscription Level is required"]}
                >
                  <MenuItem value="0" style={{ display: "none" }}>
                    Select subscription Level
                  </MenuItem>
                  {showSchool
                    ? schoolsNameList
                        .find((item) => item._id === selectedSchool)
                        ?.Subscriptions?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item._id}>
                              {item.subscription_name}
                            </MenuItem>
                          );
                        })
                    : subscriptionList
                        .filter(
                          (item) => item.type === "B2C" || item.type === "BOTH"
                        )
                        .map((item, index) => {
                          return (
                            <MenuItem key={index} value={item._id}>
                              {item.subscription_name}
                            </MenuItem>
                          );
                        })}
                </TextValidator>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Grade
                </Typography>
                <TextValidator
                  select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="gradeInput"
                  fullWidth
                  placeholder="Select grade"
                  className={
                    grade === "0"
                      ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                      : `${classes.DropdownTextInput}`
                  }
                  value={grade}
                  onChange={(e) => {
                    setGrade(e.target.value);
                    setErrorHelperText2(false);
                  }}
                  size="small"
                  validators={["required"]} // Add this line
                  errorMessages={["Section is required"]}
                  error={errorHelperText2}
                  helperText={errorHelperText2 && "Section is required"}
                >
                  <MenuItem value="" style={{ display: "none" }}>
                    Select Grade
                  </MenuItem>
                  {classesList &&
                    classesList.length > 0 &&
                    classesList.map((item, index) => {
                      const isActive = item.terminology.some(
                        (section) => section.isActive
                      );
                      if (isActive) {
                        return (
                          <MenuItem
                            key={index}
                            value={item._id}
                            style={{ textTransform: "capitalize" }}
                          >
                            {item.grade?.charAt(0).toUpperCase() +
                              item.grade
                                ?.slice(1)
                                .toLowerCase()
                                .replaceAll("_", " ")}
                          </MenuItem>
                        );
                      }
                    })}
                </TextValidator>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Section
                </Typography>
                <TextValidator
                  select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="sectionInput"
                  fullWidth
                  className={
                    section === "0"
                      ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                      : `${classes.DropdownTextInput}`
                  }
                  value={section}
                  onChange={(e) => {
                    setSection(e.target.value);
                    setErrorHelperText3(false);
                  }}
                  size="small"
                  validators={["required"]} // Add this line
                  errorMessages={["Section is required"]}
                  error={errorHelperText3}
                  helperText={errorHelperText3 && "Section is required"}
                >
                  <MenuItem value="" style={{ display: "none" }}>
                    Select Section
                  </MenuItem>
                  {/* {classesList
                      .filter((item) => item._id === grade)
                      ?.map((item, index) => {
                        console.log("Items ", item);
                        return (
                          <MenuItem
                            key={index}
                            value={item.terminology.section}
                            style={{ textTransform: "capitalize" }}
                          >
                            {item.section?.charAt(0).toUpperCase() +
                              item.section
                                ?.slice(1)
                                .toLowerCase()
                                .replaceAll("_", " ")}
                          </MenuItem>
                        );
                      })} */}
                  {classesList
                    .filter((item) => item._id === grade)
                    ?.map((item, index) => {
                      return item.terminology.map(
                        (sectionObj, sectionIndex) => (
                          <MenuItem
                            key={`${index}-${sectionIndex}`}
                            value={sectionObj.section}
                            style={{ textTransform: "capitalize" }}
                          >
                            {sectionObj.section?.charAt(0).toUpperCase() +
                              sectionObj.section
                                ?.slice(1)
                                .toLowerCase()
                                .replaceAll("_", " ")}
                          </MenuItem>
                        )
                      );
                    })}
                </TextValidator>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Interested Section
                </Typography>
                <TextValidator
                  select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="studentnameInput"
                  fullWidth
                  placeholder="Select grade"
                  className={
                    intrest === "0"
                      ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                      : `${classes.DropdownTextInput}`
                  }
                  value={intrest}
                  onChange={(e) => {
                    setIntrest(e.target.value);
                    setErrorHelperText(false);
                  }}
                  size="small"
                  validators={["required"]} // Add this line
                  errorMessages={["Interested Section is required"]}
                  error={errorHelperText}
                  helperText={
                    errorHelperText && "Interested Section is required"
                  }
                >
                  <MenuItem value="0" style={{ display: "none" }}>
                    Select Interested Section
                  </MenuItem>
                  {["block_coding", "python_coding", "react_coding"].map(
                    (item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={item}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.replace("_", " ")}
                        </MenuItem>
                      );
                    }
                  )}
                </TextValidator>
              </div>
            </Col>
          </Row>
        </Card>
        <Card className={`mt-3`} style={{ padding: "20px", textAlign: "left",overflow:"visible" }}>
          <Typography className={classes.StudentPageMainHeading}>
            Parents Details
          </Typography>
          <Row className="mt-3">
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Parent Name
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="parentnameInput"
                  fullWidth
                  placeholder="Enter parent name"
                  className={classes.TextInput}
                  value={parentName}
                  onChange={(e) => {
                    setParentName(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    Alphabetic(e);
                  }}
                  size="small"
                  validators={["required"]} // Add this line
                  errorMessages={["Parent Name is required"]}
                />
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Parent Email ID
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="parentemailInput"
                  fullWidth
                  placeholder="Enter parent email ID"
                  className={classes.TextInput}
                  value={parentEmail}
                  onChange={(e) => {
                    setParentEmail(e.target.value);
                    if (accountEmail !== parentEmail) {
                      setGenerateClicked(false);
                    }
                  }}
                  size="small"
                  validators={["required", "isEmail"]} // Add this line
                  errorMessages={[
                    "Parent Email ID is required",
                    "Enter a valid email ID",
                  ]}
                />
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Parent Contact Number
                </Typography>
                {/* <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="parentMobileInput"
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                  placeholder="Enter Parent Contact Number"
                  className={classes.TextInput}
                  value={parentMobile}
                  onChange={(e) => {
                    setParentMobile(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    Numeric(e);
                  }}
                  size="small"
                  validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                  errorMessages={[
                    "Parent Contact Number is required",
                    "Enter a valid contact number",
                  ]}
                /> */}
                {selectedCountry && <div className={classes.new_countrycode_field} >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control custom-placeholder"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      value={parentMobile}
                      defaultCountry={selectedCountry}
                    />
                    {!isValid && <p className={classes.err_text}>Enter Valid number</p>}
                  </div>}
              </div>
            </Col>
          </Row>
        </Card>
        <Card
          className={`mt-3`}
          style={{ padding: "20px", textAlign: "left", marginBottom: "60px" }}
        >
          <Typography className={classes.StudentPageMainHeading}>
            Account Details
          </Typography>
          <Row className="mt-3">
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Account Email ID
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="accountEmailInput"
                  fullWidth
                  placeholder="Enter account email ID"
                  className={classes.TextInput}
                  value={accountEmail}
                  onChange={(e) => {
                    setAccountEmail(e.target.value);
                    setAccountEmailError(parentEmail === e.target.value);
                    if (e.target.value !== parentEmail) {
                      setGenerateClicked(false);
                    }
                  }}
                  size="small"
                  validators={["required", "isEmail"]} // Add this line
                  errorMessages={[
                    "Account Email ID is required",
                    "Enter a valid email ID",
                  ]}
                  error={!accountEmailError}
                  helperText={
                    !accountEmailError
                      ? "Account Email ID does not match with Parent Email ID"
                      : ""
                  }
                />
              </div>
            </Col>
            {window.location.pathname.includes("edit") ? //       }} //         marginBottom: "15px", //         "& fieldset": { top: 0 }, //         "& legend": { display: "none" }, //       sx={{ //     <TextValidator //     </Typography> //       Password //     > //       className={`${classes.TableBodyText} ${classes.HeadingTextLable}`} //     <Typography //   <div style={{ position: "relative" }}> // <Col md={4}>
            //       id="outlined-adornment-password"
            //       type={showPassword ? "text" : "password"}
            //       fullWidth
            //       placeholder="Enter Password"
            //       className={classes.TextInput}
            //       size="small"
            //       value={password}
            //       onChange={(e) => setPassword(e.target.value)}
            //     />
            //     <div className={classes.showPassword}>
            //       {showPassword ? (
            //         <Button
            //           variant="text"
            //           style={{ minWidth: "unset" }}
            //           onClick={() => setShowPassword(false)}
            //         >
            //           <OpenEyeIcon />
            //         </Button>
            //       ) : (
            //         <Button
            //           variant="text"
            //           style={{ minWidth: "unset" }}
            //           onClick={() => setShowPassword(true)}
            //         >
            //           <CloseEyeIcon />
            //         </Button>
            //       )}
            //     </div>
            //   </div>
            // </Col>
            null : (
              <Col md={3}>
                <Button
                  className={
                    parentEmail !== accountEmail ||
                    parentEmail === "" ||
                    accountEmail === ""
                      ? `${classes.PassgenerateFunction} ${classes.disabledBtn}`
                      : classes.PassgenerateFunction
                  }
                  disabled={
                    parentEmail !== accountEmail ||
                    parentEmail === "" ||
                    accountEmail === ""
                  }
                  variant="contained"
                  onClick={() => {
                    handleGeneratePasswordclick();
                  }}
                >
                  Generate Password
                </Button>
              </Col>
            )}
          </Row>
          {generateClicked && accountEmail === parentEmail ? (
            <div>
              <Typography className={`mt-3 ${classes.passwordSentence}`}>
                Metaverse create a uniq password for you. Once you are done,
                Click the copy button
              </Typography>
              <Row className="mt-2">
                <Col xs={10} md={4} style={{ position: "relative" }}>
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingTextLable}`}
                  >
                    Password
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="outlined-adornment-password"
                    type={"text"}
                    fullWidth
                    placeholder="Enter Password"
                    className={classes.TextInput}
                    size="small"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    validators={["required"]}
                    errorMessages={["Password is required"]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              generatePassword(studentName, setPassword)
                            }
                            edge="end"
                          >
                            <RefreshIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className={classes.copyIcon}>
                    <Button
                      variant="text"
                      onClick={() => {
                        copyTrst(password);
                      }}
                    >
                      <ContentCopyIcon />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Typography className={classes.NoteHeader}>
                Note:{" "}
                <span className={classes.Notetext}>
                  Once you click on submit your access ID and password send to
                  the parent email ID
                </span>
              </Typography>
            </div>
          ) : null}
        </Card>
        <Footer
          callback={handleNavigateStudent}
          edit={window.location.pathname.includes("edit")}
        />
      </ValidatorForm>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox
          type="success"
          message={success}
          stateName={CloseAlert}
          callback={handleNavigateStudent}
        />
      )}
      {defaultSuccess && defaultSuccessMessage && (
        <AlertBox
          type="success"
          message={defaultSuccessMessage}
          stateName={CloseAlert}
        />
      )}
    </div>
  );
};

export default AddStudent;
