import React, { useState } from "react";
import classes from "./subscription.module.css";
import {
  TableRow,
  TableCell,
  Typography,
  Button,
  MenuItem,
} from "@mui/material";
import {
  DeleteBinIcon,
  EditPenIcon,
  ThreeDotMenuIcon,
} from "../../../Assets/Icons/icons";
import { StyledMenu } from "../../../Components/CommonMenu";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import { deleteSubscription } from "../../../Redux/actions/subscriptionAction";
import AlertBox from "../../../Components/AlertBox";
import { useNavigate } from "react-router-dom";
import SchoolActionModals from "../../../Components/SchoolActionModals";

const SubscriptionListItem = ({ item, key, index }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [modalToggle, setModalToggle] = useState(false);
  const [type, setType] = useState("");
  const [height, setHeight] = useState(0);
  const open = Boolean(anchorEl);
  const { error, success } = useSelector((state) => state.subscriptionDatas);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const CloseAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
  };

  const modalClose = () => {
    setModalToggle(false);
  };

  const handleEditSubscriptionDetails = (e) => {
    e.stopPropagation();
    dispatch(setHeaderName("edit-school-details"));
    navigate(`/edit-subscription-details/${item._id}`);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    setType("delete");
    setHeight(205);
    setModalToggle(true);
    handleClose(event);
  };

  const handleDeleteSubscription = (e) => {
    dispatch(pageLoader(true));
    dispatch(deleteSubscription(item._id, setSuccessAlert, setErrorAlert));
    modalClose();
  };

  return (
    <React.Fragment>
      <TableRow key={item.id} className={classes.TableBodyRow}>
        <TableCell flex={1} className={classes.firstCell}>
          <Typography
            className={classes.TableBodyText}
          >{`#${index.toLocaleString("en-US", {
            minimumIntegerDigits: 3,
            useGrouping: false,
          })}`}</Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item.subscription_name}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item.duration}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item.price}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>{item.type}</Typography>
        </TableCell>
        <TableCell flex={1}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
            variant="text"
            style={{
              minWidth: 0,
            }}
          >
            <ThreeDotMenuIcon />
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              disableRipple
              style={{ height: "40px" }}
              onClick={handleEditSubscriptionDetails}
            >
              <EditPenIcon />
              <span className={classes.MenuItemText}>Edit Details</span>
            </MenuItem>
            <MenuItem
              onClick={handleDelete}
              disableRipple
              style={{ height: "40px" }}
            >
              <DeleteBinIcon />
              <span className={classes.MenuItemText}>Delete</span>
            </MenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>
      {modalToggle && (
        <SchoolActionModals
          type={type}
          height={height}
          modalClose={modalClose}
          item={item}
          name={item.subscription_name}
          callback={type === "delete" && handleDeleteSubscription}
        />
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox type="success" message={success} stateName={CloseAlert} />
      )}
    </React.Fragment>
  );
};

export default SubscriptionListItem;
