import React from "react";
import classes from "./courses.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import { BrudCrumsNextIcon, EditPenIcon } from "../../../Assets/Icons/icons";
import { Typography, Card } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import gameImage from "../../../Assets/Images/gameImage.png";
import fileIcon from "../../../Assets/Images/phpFileIcon.png";
import { useEffect } from "react";
import { getOneCourse } from "../../../Redux/actions/courseAction";
import { useState } from "react";
import AlertBox from "../../../Components/AlertBox";

const ViewgameDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorAlert, setErrorAlert] = useState(false);
  const { error, oneCourse } = useSelector((state) => state.courseDatas);

  const handleNavigateCourse = () => {
    dispatch(setHeaderName("Courses/Games"));
    navigate("/cources");
  };

  useEffect(() => {
    const path = window.location.pathname.split("/");
    dispatch(pageLoader(true));
    dispatch(getOneCourse(path[path.length - 1], setErrorAlert));
  }, [dispatch]);

  useEffect(() => {
    if (oneCourse) {
      dispatch(setHeaderName(oneCourse?.gameInfo?.name));
    }
  }, [oneCourse]);

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  const handleEditCourseDetails = () => {
    dispatch(setHeaderName("edit-game-details"));
    navigate(`/edit-game-details/${oneCourse._id}`);
  };

  return (
    <div>
      <div className={`${classes.BrudcrumbContainer}`}>
        <div className={classes.brudcrumbs}>
          <span
            className={classes.TableHeaderText}
            onClick={() => handleNavigateCourse()}
          >
            Courses/Games
          </span>
          <span>
            <BrudCrumsNextIcon />
          </span>
          <span className={classes.TableBodyText}>view Games</span>
        </div>
        <div
          className={classes.brudcrumbs}
          onClick={() => {
            handleEditCourseDetails();
          }}
        >
          <span>
            <EditPenIcon />
          </span>
          <span className={classes.TableBodyText}>Edit Details</span>
        </div>
      </div>
      <Row className="mt-4">
        <Col md={4}>
          <Typography
            className={`${classes.formLable} ${classes.formLableLight} mb-1`}
          >
            Subscription Level
          </Typography>
          <Typography
            className={classes.MenuItemText}
            style={{ marginLeft: 0 }}
          >
            {oneCourse?.subscriptionInfo?.subscription_name}
          </Typography>
        </Col>
        <Col md={4}>
          <Typography
            className={`${classes.formLable} ${classes.formLableLight} mb-1`}
          >
            Classroom Grade Level
          </Typography>
          <Typography
            className={classes.MenuItemText}
            style={{ marginLeft: 0, textTransform: "capitalize" }}
          >
            {oneCourse?.gradeLevel?.charAt(0).toUpperCase() +
              oneCourse?.gradeLevel
                ?.slice(1)
                .toLowerCase()
                .replaceAll("_", " ")}
          </Typography>
        </Col>
        <Col md={4}>
          <Typography
            className={`${classes.formLable} ${classes.formLableLight} mb-1`}
          >
            Topic
          </Typography>
          <Typography
            className={classes.MenuItemText}
            style={{ marginLeft: 0, textTransform: "capitalize" }}
          >
            {oneCourse?.topic.replaceAll("_", " ")}
          </Typography>
        </Col>
      </Row>
      <Card className={`mt-3`} style={{ padding: "20px" }}>
        <Typography className={classes.headingText}>Game & Topic</Typography>
        <Row className="mt-3">
          <Col md={4}>
            <Typography
              className={`${classes.formLable} ${classes.formLableLight}`}
            >
              Game Name
            </Typography>
            <Typography
              className={classes.MenuItemText}
              style={{ marginLeft: 0 }}
            >
              {oneCourse?.gameInfo?.name}
            </Typography>
          </Col>
          <Col md={4} style={{ textAlign: "left" }}>
            <Typography
              className={`${classes.formLable} ${classes.formLableLight}`}
            >
              Cover Image
            </Typography>
            <img
              src={`${process.env.REACT_APP_API_URL}${oneCourse?.cover_image}`}
              alt="GameCoverImage"
              className={`${classes.gameImage} mt-2`}
            />
          </Col>
        </Row>
      </Card>
      <Card className={`mt-3`} style={{ padding: "20px" }}>
        <Typography className={classes.headingText}>Level Details</Typography>
        {oneCourse?.level?.map((item, index) => {
          return (
            <Row className="mt-3">
              <Col md={4}>
                <Typography
                  className={`${classes.formLable} ${classes.formLableLight}`}
                >
                  Level Name
                </Typography>
                <Typography
                  className={classes.MenuItemText}
                  style={{ marginLeft: 0 }}
                >
                  {item?.level_name?.split(" ")[1]}
                </Typography>
              </Col>
              <Col md={4} style={{ textAlign: "left" }}>
                <Typography
                  className={`${classes.formLable} ${classes.formLableLight}`}
                >
                  Level File*
                </Typography>
                <Card
                  className="d-flex align-items-center mt-2"
                  style={{ padding: "10px", width: "80%" }}
                >
                  <img
                    src={fileIcon}
                    alt="GameImage"
                    className={classes.fileExtension}
                  />
                  <Typography
                    className={classes.MenuItemText}
                    style={{ marginLeft: 10 }}
                  >
                    {item?.file_name}
                  </Typography>
                </Card>
              </Col>
            </Row>
          );
        })}
      </Card>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default ViewgameDetails;
