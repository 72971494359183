import {
  TableCell,
  TableRow,
  Typography,
  Button,
  MenuItem,
} from "@mui/material";
import classes from "./roleManagement.module.css";
import React, { useState } from "react";
import {
  ActivePersonIcon,
  EditPenIcon,
  InactivePersonIcon,
  ThreeDotMenuIcon,
} from "../../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { StyledMenu } from "../../../Components/CommonMenu";
import SchoolActionModals from "../../../Components/SchoolActionModals";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import AlertBox from "../../../Components/AlertBox";
import { changeRoleStatus } from "../../../Redux/actions/roleManagementAction";

const RoleManagementListItem = ({ item, index, value, topic }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalToggle, setModalToggle] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [type, setType] = useState("");
  const [height, setHeight] = useState(0);
  const open = Boolean(anchorEl);
  const { error, success } = useSelector((state) => state.roleDatas);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const CloseAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
  };

  const handleChangeStatus = () => {
    dispatch(pageLoader(true));
    dispatch(changeRoleStatus(item._id, setErrorAlert, setSuccessAlert, topic));
    modalClose();
  };

  const modalClose = () => {
    setModalToggle(false);
  };

  const handleStatus = (event) => {
    handleClose(event);
    setType("active/inactive");
    setHeight(205);
    setModalToggle(true);
  };

  const handleNavigateEditDetails = (event) => {
    handleClose(event);
    dispatch(setHeaderName("edit-role"));
    navigate(`/edit-role/${item._id}`);
  };

  return (
    <React.Fragment>
      <TableRow key={item.id} className={classes.TableBodyRow}>
        <TableCell flex={1}>
          <Typography
            className={classes.TableBodyText}
          >{`#${index.toLocaleString("en-US", {
            minimumIntegerDigits: 3,
            useGrouping: false,
          })}`}</Typography>
        </TableCell>
        {value !== 1 && (
          <TableCell flex={1}>
            <Typography className={classes.TableBodyText}>
              {item.name}
            </Typography>
          </TableCell>
        )}
        <TableCell flex={1}>
          <Typography
            className={classes.TableBodyText}
            style={{ textTransform: "capitalize" }}
          >
            {item.designationInfo?.name.replaceAll("_", " ").toLowerCase()}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item.accessInfo?.name}
          </Typography>
        </TableCell>
        <TableCell style={{ width: "fit-content" }}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
            variant="text"
            style={{
              minWidth: 0,
            }}
          >
            <ThreeDotMenuIcon />
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleNavigateEditDetails}
              disableRipple
              style={{ height: "40px" }}
            >
              <EditPenIcon />
              <span
                className={classes.MenuItemText}
                style={{ marginLeft: "9px" }}
              >
                Edit Details
              </span>
            </MenuItem>
            <MenuItem
              onClick={handleStatus}
              disableRipple
              style={{ height: "40px" }}
            >
              {item.isActive ? <InactivePersonIcon /> : <ActivePersonIcon />}
              <span
                className={classes.MenuItemText}
                style={{ marginLeft: "9px" }}
              >
                {item.isActive ? "Deactivate" : "Activate"}
              </span>
            </MenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>
      {modalToggle && (
        <SchoolActionModals
          type={type}
          height={height}
          modalClose={modalClose}
          item={item}
          name={item.name}
          callback={handleChangeStatus}
        />
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox type="success" message={success} stateName={CloseAlert} />
      )}
    </React.Fragment>
  );
};

export default RoleManagementListItem;
