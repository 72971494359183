import { authApi, authImageApi, getAuthorizationHeader } from "../../Api/api";
import {
  GET_COUPONSES_FAIL,
  GET_COUPONSES_SUCCESS,
} from "../contants/couponsConstants";
import { pageLoader } from "./userActions";

export const getAllCoupons = () => async (dispatch) => {
  try {
    const { data } = await authApi.get(`/coupon/coupons-all`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_COUPONSES_SUCCESS,
        payload: data?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_COUPONSES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
  }
};

export const addNewCoupon =
  (userEntry, setAddSuccess, setAddError) => async (dispatch) => {
    try {
      const { data } = await authApi.post("/coupon/coupon-create", userEntry, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      dispatch(pageLoader(false));
      if (data.success) {
        setAddSuccess(data.message);
      }
    } catch (error) {
      dispatch(pageLoader(false));
      setAddError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

export const updateNewCoupon =
  (coupon_id, userEntry, setAddSuccess, setAddError) => async (dispatch) => {
    try {
      const { data } = await authApi.put(
        `/coupon/coupon-update/${coupon_id}`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        setAddSuccess(data.message);
      }
    } catch (error) {
      dispatch(pageLoader(false));
      setAddError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

export const deleteCoupon =
  (coupon_id, setAddSuccess, setAddError) => async (dispatch) => {
    try {
      const { data } = await authApi.delete(
        `/coupon/coupon-delete/${coupon_id}`,
        {},
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      dispatch(pageLoader(false));
      if (data.success) {
        setAddSuccess(data.message);
        dispatch(getAllCoupons());
      }
    } catch (error) {
      setAddError(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
      dispatch(pageLoader(false));
    }
  };
