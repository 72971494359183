module.exports = {
    ADD_COURSE_SUCCESS: "ADD_COURSE_SUCCESS",
    ADD_COURSE_FAIL: "ADD_COURSE_FAIL",
    GET_COURSES_SUCCESS: "GET_COURSES_SUCCESS",
    GET_COURSES_FAIL: "GET_COURSES_FAIL",
    GET_ONE_COURSE_SUCCESS: "GET_ONE_COURSE_SUCCESS",
    GET_ONE_COURSE_FAIL: "GET_ONE_COURSE_FAIL",
    COURSE_PIECHART_SUCCESS: "COURSE_PIECHART_SUCCESS",
    COURSE_PIECHART_FAIL: "COURSE_PIECHART_FAIL",
    GET_GAMES_LIST_SUCCESS: "GET_GAMES_LIST_SUCCESS",
    GET_GAMES_LIST_FAIL: "GET_GAMES_LIST_FAIL",
    GET_COURSE_PIECHART_SUCCESS: "GET_COURSE_PIECHART_SUCCESS",
    GET_COURSE_PIECHART_FAIL: "GET_COURSE_PIECHART_FAIL",
}