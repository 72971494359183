import { TextField, MenuItem, Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./index.module.css";
import { schoolDetails } from "../Utilities/TableDummyDatas";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader } from "../Redux/actions/userActions";
import {
  getAllSchools,
  getSchoolNamesList,
} from "../Redux/actions/schoolActions";

const SchoolDropDown = ({ handleChange, value, width, edit }) => {
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const { sucess, error, schoolsNameList } = useSelector(
    (state) => state.schoolDatas
  );

  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(getSchoolNamesList(setErrorAlert));
  }, []);

  const renderMenuItems = (item, index) => {
    return (
      <MenuItem key={index} value={item._id}>
        {item.name}
      </MenuItem>
    );
  };

  return (
    <div>
      <TextField
        select
        value={value}
        onChange={handleChange}
        sx={{
          "& legend": {
            display: "none",
          },
          "& fieldset": {
            top: 0,
          },
          width: width,
          background: edit ? "#DADAE1" : "#fff",
          height: "40px",
        }}
        size="small"
        disabled={edit}
        className={
          value === "select"
            ? `${classes.defaultMenuItem} ${classes.schoolFilterDropdown}`
            : `${classes.schoolFilterDropdown}`
        }
      >
        <MenuItem
          key={"select"}
          value={"select"}
          className={classes.defaultMenuItem}
        >
          {"Select School"}
        </MenuItem>
        {schoolsNameList &&
          schoolsNameList.length > 0 &&
          schoolsNameList.map((item, index) => {
            return renderMenuItems(item, index);
          })}
      </TextField>
    </div>
  );
};

export default SchoolDropDown;
