import React, { useState } from "react";
import classes from "./payment.module.css";
import StatisticsCard from "../../../Components/statisticsCard";
import { PaymentStatisticData } from "../../../Utilities/StatisticConstants";
import {
  Card,
  InputAdornment,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
} from "@mui/material";
import {
  RoundAddIcon,
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import DefaultPagination from "../../../Components/Pagination";
import PaymentListItem from "./paymentListItem";
import { getAllPayments, getPaymentsCounts, setPageValue } from "../../../Redux/actions/userActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { paymentTableData } from "../../../Utilities/TableDummyDatas";
import { handleSortClick } from "../../../Utilities/commonFunctions";

const PaymentIndex = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [topCount,setTopCount] = useState(PaymentStatisticData);
  const [paymentCount,setPaymentCount] = useState(null);
  const [collectionType, setCollectionType] = useState("All");
  const [data, setData] = useState([]);
  const { paymentList,sortBy,page } = useSelector((state) => state.userLogin);
  console.log("paymentCount =",paymentCount)
  const handleAddRoleClick = () => {};

  function handleGetPaymentList() {
    let userEntry = {
      page,
      search,
      collectionType,
      sortBy
    };

    dispatch(getAllPayments(userEntry));
  }

  function handleGetPaymentCounts() {
    dispatch(getPaymentsCounts(setPaymentCount));
  }

  const handleChangeDesignation = (e) => {
    console.log(e.target.value);
    setCollectionType(e.target.value);
  };

  useEffect(() => {
    dispatch(setPageValue(1));
    handleGetPaymentList();
  }, [collectionType,sortBy,search]);

  useEffect(() => {
    handleGetPaymentList();
  }, [page]);

  useEffect(() => {
    dispatch(setPageValue(1));
    handleGetPaymentCounts();
  }, []);

  useEffect(()=>{
    if (paymentCount) {
      let _existing = [...topCount];
      _existing[0].number = paymentCount?.schoolPayment;
      _existing[1].number = paymentCount?.parentPayment;
      _existing[2].number = paymentCount?.individualPayment;

      setTopCount(_existing);
    } 
  },[paymentCount])
  

  return (
    <div>
      <StatisticsCard data={topCount} />
      <Card className="mt-3">
        <div style={{ padding: "20px" }}>
          <div className={`${classes.FilterContainerMain}`}>
            <div className={classes.HeadertextFeildContainer}>
              <div>
                <TextField
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                  }}
                  fullWidth
                  placeholder="Search by Name, Subscription Level"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  className={`${classes.TextInput} ${classes.searchBar}`}
                  id="firstNameinput"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  size="small"
                />
              </div>
              <div>
                <Button variant="contained" className={classes.searchButton}>
                  <span className={classes.nrmlBtnText}>Search</span>
                </Button>
              </div>
            </div>
            <div
              className="d-flex align-items-center"
              style={{
                columnGap: "10px",
              }}
            >
              <div
                className={`${classes.DividerClasses} ${classes.vertical}`}
              ></div>
              <Select
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  marginBottom: "0px",
                  height: "40px",
                }}
                select
                id="teachernameInput"
                fullWidth
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Set your desired max height here
                    },
                  },
                }}
                className={
                  collectionType === "select"
                    ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                    : `${classes.DropdownTextInput}`
                }
                value={collectionType}
                onChange={handleChangeDesignation}
                size="small"
              >
                <MenuItem value="select" style={{ display: "none" }}>
                  Select Collection Type
                </MenuItem>
                {["All","Success", "Pending"].map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item}
                    >
                      {item === "Success" ? "Paid" : item === "Pending" ? "In Progress" : item}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
        <div className={`${classes.TableContainer}`}>
          <Table className={classes.TableClass}>
            <TableHead>
              <TableRow className={classes.TableHeaderRow}>
                <TableCell
                  flex={1}
                  className={classes.firstCell}
                  style={{ minWidth: 85 }}
                >
                  <span className={classes.TableHeaderText}>#No</span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 140 }}>
                  <span className={classes.TableHeaderText}>
                    Collection Type
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 140 }}>
                  <span className={classes.TableHeaderText}>Collected By</span>
                  <span onClick={() =>
                          handleSortClick(dispatch, sortBy, "name")
                        }>
                    {sortBy?.includes("desc") &&
                        sortBy?.includes("name") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 125 }}>
                  <span className={classes.TableHeaderText}>Date</span>
                  <span onClick={() =>
                          handleSortClick(dispatch, sortBy, "updatedAt")
                        }>
                    {sortBy?.includes("desc") &&
                        sortBy?.includes("updatedAt") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 165 }}>
                  <span className={classes.TableHeaderText}>
                    Subscription Level
                  </span>
                  <span onClick={() =>
                          handleSortClick(dispatch, sortBy, "subscriptionName")
                        }>
                    {sortBy?.includes("desc") &&
                        sortBy?.includes("subscriptionName") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 125 }}>
                  <span className={classes.TableHeaderText}>Amount</span>
                  <span onClick={() =>
                          handleSortClick(dispatch, sortBy, "order_amount")
                        }>
                    {sortBy?.includes("desc") &&
                        sortBy?.includes("order_amount") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                  </span>
                </TableCell>
                <TableCell flex={1}>
                  <span className={classes.TableHeaderText}>Status</span>
                  <span onClick={() =>
                          handleSortClick(dispatch, sortBy, "order_status")
                        }>
                    {sortBy?.includes("desc") &&
                        sortBy?.includes("order_status") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                  </span>
                </TableCell>
                {/* <TableCell style={{ width: "60px" }}></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(paymentList?.payments) && 
              paymentList?.payments?.map((item, index) => {
                return <PaymentListItem item={item} key={index} index={index + 1 + (page - 1) * 10} />;
              })}
            </TableBody>
          </Table>
        </div>
       {Array.isArray(paymentList?.payments) &&
       paymentList?.payments?.length > 0 &&
       <div className={`mt-3`}>
          <DefaultPagination count={paymentList?.totalCount} />
        </div>}
      </Card>
    </div>
  );
};

export default PaymentIndex;
