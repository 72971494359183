import React, { useState, useEffect } from "react";
import classes from "./subscription.module.css";
import { BrudCrumsNextIcon } from "../../../Assets/Icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import {
  Card,
  Typography,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Footer from "../../../Components/Footer";
import { AlphaNumeric, Numeric } from "../../../Utilities/commonFunctions";
import AlertBox from "../../../Components/AlertBox";
import {
  addNewSubscription,
  getOneSubscription,
  updateSubscription,
} from "../../../Redux/actions/subscriptionAction";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

const AddSubscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [subscriptionName, setSubscriptionName] = useState("");
  const [duration, setDuration] = useState("select");
  const [priceType, setPriceType] = useState("");
  const [features, setFeatures] = useState([""]);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [helperTextError, setHelperTextError] = useState(false);
  const [price, setPrice] = useState("");
  const { error, success, oneSubscription } = useSelector(
    (state) => state.subscriptionDatas
  );

  const handleNavigateSubscription = () => {
    dispatch(setHeaderName("subscription-management"));
    navigate("/subscription-management");
  };

  useEffect(() => {
    if (window.location.pathname.includes("edit")) {
      const path = window.location.pathname;
      const arr = path.split("/");
      dispatch(pageLoader(true));
      dispatch(getOneSubscription(arr[arr.length - 1], setErrorAlert));
    }
  }, [dispatch]);

  useEffect(() => {
    if (oneSubscription && window.location.pathname.includes("edit")) {
      setSubscriptionName(oneSubscription?.subscription_name);
      setDuration(oneSubscription?.duration);
      setPriceType(oneSubscription?.type);
      setPrice(oneSubscription?.price);
      setFeatures(
        oneSubscription?.features && oneSubscription?.features?.length === 0
          ? [""]
          : oneSubscription?.features
      );
    }
  }, [oneSubscription]);

  const CloseAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
  };

  const getIndays = (duration) => {
    let noofDays;
    if (duration === "1 Month") {
      noofDays = 30;
    } else if (duration === "3 Months") {
      noofDays = 90;
    } else if (duration === "6 Months") {
      noofDays = 180;
    } else if (duration === "1 Year") {
      noofDays = 365;
    }
    return noofDays;
  };

  const handleSubmit = () => {
    if (duration === "select") {
      setHelperTextError(true);
    } else {
      const userEntry = {
        subscription_name: subscriptionName,
        duration: duration,
        days: getIndays(duration),
        type: priceType,
        price: price,
        features: features,
      };
      dispatch(pageLoader(true));
      if (window.location.pathname.includes("edit")) {
        dispatch(
          updateSubscription(
            oneSubscription?._id,
            userEntry,
            setSuccessAlert,
            setErrorAlert
          )
        );
      } else {
        dispatch(addNewSubscription(userEntry, setSuccessAlert, setErrorAlert));
      }
    }
  };

  const handleChangeduration = (event) => {
    setDuration(event.target.value);
    setHelperTextError(false);
  };

  const handleChangepriceType = (event) => {
    setPriceType(event.target.value);
  };

  const handleAddFeatureField = () => {
    let existing_features = [...features];
    existing_features.push("");
    setFeatures(existing_features);
  };

  const handleDelFeatureField = (i) => {
    let existing_features = [...features];
    existing_features.splice(i, 1);
    setFeatures(existing_features);
  };

  const handleFeatureChange = (e, i) => {
    let existing_features = [...features];
    let _val = e.target.value;
    existing_features[i] = _val;
    setFeatures(existing_features);
  };

  return (
    <div>
      <div className={classes.brudcrumbs}>
        <span
          className={classes.TableHeaderText}
          onClick={() => handleNavigateSubscription()}
        >
          Subscription Mangement
        </span>
        <span>
          <BrudCrumsNextIcon />
        </span>
        <span className={`${classes.TableBodyText} ${classes.HeadingText}`}>
          Add Subscription
        </span>
      </div>
      <ValidatorForm
        onSubmit={handleSubmit}
        useref="form"
        className={classes.AddStudentForm}
      >
        <Card className={`mt-3`} style={{ padding: "20px", textAlign: "left" }}>
          <Typography className={classes.MainHeading1}>
            Subscription Details
          </Typography>
          <Row className="mt-3">
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Subscription Name
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="subscriptionnameInput"
                  fullWidth
                  placeholder="Enter Subscription name"
                  className={classes.TextInput}
                  value={subscriptionName}
                  onChange={(e) => {
                    setSubscriptionName(e.target.value);
                  }}
                  onKeyPress={(e) => AlphaNumeric(e)}
                  size="small"
                  validators={["required", "matchRegexp:[A-Za-z]"]} // Add this line
                  errorMessages={[
                    "Subscription Name is required",
                    "Subscription Name is not Valid",
                  ]}
                />
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Duration
                </Typography>
                <TextValidator
                  select
                  value={duration}
                  onChange={handleChangeduration}
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                  }}
                  size="small"
                  className={
                    duration === "select"
                      ? `${classes.defaultMenuItem} ${classes.schoolFilterDropdown}`
                      : `${classes.schoolFilterDropdown}`
                  }
                  fullWidth
                  error={helperTextError}
                  helperText={
                    helperTextError
                      ? "Please Select Any one of the Duration"
                      : ""
                  }
                >
                  <MenuItem
                    key={"select"}
                    value={"select"}
                    className={classes.defaultMenuItem}
                  >
                    {"Select Duration"}
                  </MenuItem>
                  {["1 Month", "3 Months", "6 Months", "1 Year"].map(
                    (item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    }
                  )}
                </TextValidator>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Price
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="priceInput"
                  fullWidth
                  placeholder="Enter Price"
                  className={classes.TextInput}
                  value={price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    Numeric(e);
                  }}
                  size="small"
                  validators={["required"]} // Add this line
                  errorMessages={["Price is required"]}
                />
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Billing Type
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={priceType}
                  onChange={handleChangepriceType}
                >
                  {["B2B", "B2C", "BOTH"].map((item, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        value={item}
                        control={<Radio />}
                        label={item}
                        className={classes.radioBtn}
                      />
                    );
                  })}
                </RadioGroup>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={5}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Features
                </Typography>
                <Box>
                  {Array.isArray(features) &&
                    features.map((datas, index) => (
                      <Box className={classes.add_features}>
                        <TextValidator
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                            marginBottom: "15px",
                            width: "100%",
                          }}
                          id="subscriptionnameInput"
                          fullWidth
                          placeholder="Enter Features"
                          className={classes.TextInput}
                          value={datas}
                          onChange={(e) => {
                            handleFeatureChange(e, index);
                          }}
                          onKeyPress={(e) => AlphaNumeric(e)}
                          size="small"
                          validators={["required"]} // Add this line
                          errorMessages={["Subscription Name is required"]}
                        />
                        {index === 0 ? (
                          <AddOutlinedIcon
                            className={classes.add_btn}
                            onClick={handleAddFeatureField}
                          />
                        ) : (
                          <RemoveCircleOutlineOutlinedIcon
                            className={classes.del_btn}
                            onClick={() => handleDelFeatureField(index)}
                          />
                        )}
                      </Box>
                    ))}
                </Box>
              </div>
            </Col>
          </Row>
        </Card>
        <Footer
          callback={handleNavigateSubscription}
          edit={window.location.pathname.includes("edit")}
        />
      </ValidatorForm>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox
          type="success"
          message={success}
          stateName={CloseAlert}
          callback={handleNavigateSubscription}
        />
      )}
    </div>
  );
};

export default AddSubscription;
