import {
  Card,
  Divider,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  DashboardCalendarIcon,
  PinkDotIcon,
  PurpleDotIcon,
  SkyBlueDotIcon,
  YellowDotIcon,
} from "../../../Assets/Icons/icons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import classes from "./dashboard.module.css";
import ReactApexChart from "react-apexcharts";
import {
  FirstGraphoptions,
  SecondGraphoptions,
} from "../../../Utilities/graphContants";
import StatisticsCard from "../../../Components/statisticsCard";
import {
  DashBoardStatisticData,
  range,
} from "../../../Utilities/StatisticConstants";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardFirstPiechart } from "../../../Redux/actions/dashboardAction";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [errorAlert, setErrorAlert] = useState(false);
  const [defaultError, setDefaultError] = useState("");
  const { firstPieChart } = useSelector((state) => state.dashboardDatas);

  const handleEvent = (event, picker) => {
    console.log("start: ", picker.startDate._d);
    console.log("end: ", picker.endDate._d);
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
  };

  useEffect(() => {
    if (fromDate !== undefined && toDate !== undefined) {
      setSelectedDate(
        `${moment(fromDate).format("DD MMM YYYY")} to ${moment(toDate).format(
          "DD MMM YYYY"
        )}`
      );
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    dispatch(getDashboardFirstPiechart(setErrorAlert, firstPieChart, navigate));
  }, []);

  useEffect(() => {}, []);

  return (
    <div>
      <StatisticsCard data={firstPieChart} />
      <div className={`mt-3`}>
        <Row className="justify-content-md-between">
          <Col md={5}>
            <Card className={classes.PiechartCard1}>
              <Typography className={classes.cardHeaderText}>
                Portal Traffic
              </Typography>
              <Typography className={`mt-3 ${classes.cardSubHeaderText}`}>
                Unique Visitors
              </Typography>
              <Typography className={`mt-1 ${classes.cardNumberText}`}>
                3,456
              </Typography>
              <div className={`mt-2 ${classes.bar_clipper}`}>
                <div className={classes.bar_wrapper}>
                  <span
                    className={classes.bar1}
                    style={{ width: "50%" }}
                  ></span>
                  <span
                    className={classes.bar2}
                    style={{ width: "27%" }}
                  ></span>
                  <span
                    className={classes.bar3}
                    style={{ width: "16%" }}
                  ></span>
                  <span className={classes.bar4} style={{ width: "7%" }}></span>
                </div>
              </div>
              <div className={`mt-3 ${classes.barDetailsContainer}`}>
                <div className={classes.statusBar}>
                  <div className={classes.statusBarIconContainer}>
                    <YellowDotIcon />
                    <Typography className={classes.cardNrmlTexts}>
                      Direct
                    </Typography>
                  </div>
                  <Typography className={classes.cardNrmlTexts}>
                    13,900
                  </Typography>
                  <Typography className={classes.cardNrmlTexts}>50%</Typography>
                </div>
                <Divider
                  sx={{
                    borderStyle: "dashed",
                    borderColor: "var(--login-sub-header-text-color)",
                  }}
                />
                <div className={classes.statusBar}>
                  <div className={classes.statusBarIconContainer}>
                    <PurpleDotIcon />
                    <Typography className={classes.cardNrmlTexts}>
                      Search
                    </Typography>
                  </div>
                  <Typography className={classes.cardNrmlTexts}>
                    7,564
                  </Typography>
                  <Typography className={classes.cardNrmlTexts}>27%</Typography>
                </div>
                <Divider
                  sx={{
                    borderStyle: "dashed",
                    borderColor: "var(--login-sub-header-text-color)",
                  }}
                />
                <div className={classes.statusBar}>
                  <div className={classes.statusBarIconContainer}>
                    <SkyBlueDotIcon />
                    <Typography className={classes.cardNrmlTexts}>
                      Referrals
                    </Typography>
                  </div>
                  <Typography className={classes.cardNrmlTexts}>
                    4,876
                  </Typography>
                  <Typography className={classes.cardNrmlTexts}>8%</Typography>
                </div>
                <Divider
                  sx={{
                    borderStyle: "dashed",
                    borderColor: "var(--login-sub-header-text-color)",
                  }}
                />
                <div className={classes.statusBar}>
                  <div className={classes.statusBarIconContainer}>
                    <PinkDotIcon />
                    <Typography className={classes.cardNrmlTexts}>
                      Social
                    </Typography>
                  </div>
                  <Typography className={classes.cardNrmlTexts}>578</Typography>
                  <Typography className={classes.cardNrmlTexts}>7%</Typography>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={7}>
            <Card
              className={`mb-3 ${classes.PiechartCard1} ${classes.PiechartCard2}`}
              style={{ position: "relative" }}
            >
              <Typography className={classes.cardHeaderText}>
                Earnings
              </Typography>
              <div>
                <DateRangePicker
                  initialSettings={{ ranges: range }}
                  alwaysShowCalendars={true}
                  onApply={handleEvent}
                  className={classes.daterangepicker}
                >
                  <div>
                    <TextField
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      size="small"
                      placeholder="Today"
                      value={selectedDate}
                      className={classes.dashboardDateRangePicker}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="start"
                            >
                              <DashboardCalendarIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </DateRangePicker>
              </div>
              <div
                className={`mt-3 ${classes.statusBarIconContainer}`}
                style={{ columnGap: "30px" }}
              >
                <div className={classes.statusBarIconContainer}>
                  <YellowDotIcon />
                  <div>
                    <Typography className={classes.cardSubHeaderText}>
                      Subscription Collection
                    </Typography>
                    <Typography className={`mt-2 ${classes.MoneyBoldText}`}>
                      £76,000
                    </Typography>
                  </div>
                </div>
                <div className={classes.statusBarIconContainer}>
                  <PurpleDotIcon />
                  <div>
                    <Typography className={classes.cardSubHeaderText}>
                      Other Collection
                    </Typography>
                    <Typography className={`mt-2 ${classes.MoneyBoldText}`}>
                      £20,000
                    </Typography>
                  </div>
                </div>
              </div>
              <div>
                <ReactApexChart
                  className={classes.firstGraphChart}
                  options={FirstGraphoptions.options}
                  series={FirstGraphoptions.series}
                  type="area"
                  height={250}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <div>
        <Card className={classes.PiechartCard1}>
          <Typography className={classes.cardHeaderText}>
            School Survey
          </Typography>
          <div
            className={`mt-3 ${classes.statusBarIconContainer}`}
            style={{ columnGap: "30px" }}
          >
            <div className={classes.statusBarIconContainer}>
              <PurpleDotIcon />
              <div>
                <Typography className={classes.cardSubHeaderText}>
                  Active
                </Typography>
                <Typography className={`mt-2 ${classes.MoneyBoldText}`}>
                  35
                </Typography>
              </div>
            </div>
            <div className={classes.statusBarIconContainer}>
              <PinkDotIcon />
              <div>
                <Typography className={classes.cardSubHeaderText}>
                  Inactive
                </Typography>
                <Typography className={`mt-2 ${classes.MoneyBoldText}`}>
                  15
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <ReactApexChart
              className={classes.secondGraphChart}
              options={SecondGraphoptions.options}
              series={SecondGraphoptions.series}
              type="area"
              height={250}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Home;
