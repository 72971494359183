import React, { useEffect } from 'react'
import classes from "./layout.module.css"
import { Outlet, useNavigate } from "react-router-dom";
import SideNavBar from '../Components/SideNavBar';
import Header from '../Components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';
import SuccessModal from '../Components/successModal';
import { setShowLogoutModal } from '../Redux/actions/userActions';


const DefaultLayout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, toggleSideBar, showLogoutModal } = useSelector(state => state.userLogin);
    useEffect(() => {
        const token = localStorage.getItem("userToken");
        if (!token) {
            navigate("/login")
        }
    }, [navigate])


    const handleLogout = () => {
        localStorage.removeItem("userToken");
        dispatch(setShowLogoutModal(false));
        navigate("/login");
    };
    const modalClose = () => {
        dispatch(setShowLogoutModal(false));
    }

    return (
        <div className={classes.DefaultLayoutMaindiv}>
            <div className={classes.sideNavBarParent}
                style={{
                    width: toggleSideBar ? "240px" : "max-content",
                }}>
                <SideNavBar device={"desktop"} />
            </div>
            <div className={toggleSideBar ? classes.headerParent : `${classes.headerParent} ${classes.toggledHeaderParent}`}>
                <Header />
            </div>
            <div className={toggleSideBar ? classes.MainBodyChildDiv : `${classes.MainBodyChildDiv} ${classes.toggledMainBodyChildDiv}`}>
                <div>
                    <Outlet />
                </div>
            </div>
            {loading &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            {showLogoutModal && (
                <SuccessModal
                    modalClose={modalClose}
                    width={335}
                    height={153}
                    type={"logout"}
                    callback={handleLogout}
                />
            )}
        </div>
    )
}

export default DefaultLayout