import { api, authApi, getAuthorizationHeader } from "../../Api/api";
import {
  ALL_PAYMENT_LIST,
  FORGOT_PASSWORD_OTP,
  HEADER_NAME,
  PAGE_LOADER,
  PAGINATION_VALUE,
  SHOW_ERROR,
  SHOW_LOGOUT_MODAL,
  SORT_BY_VALUE,
  TOGGLE_SIDEBAR,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_SUCCESS,
} from "../contants/userConstants";

export const loginUser =
  (userEntry, navigate, errorAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await api.post("/super-admin/admin-login", {
        ...userEntry,
      });
      console.log(data?.data.token);
      localStorage.setItem("userToken", JSON.stringify(data?.data.token));
      localStorage.setItem("Adminschool", JSON.stringify(data?.data.school));
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data?.message,
      });
      dispatch(pageLoader(false));
      navigate("/dashboard");
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const forgotPasswordEmailsend =
  (userEntry, setOtpScreen, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await api.post("/super-admin/forgot-password", {
        ...userEntry,
      });
      console.log(data);
      if (data.success) {
        dispatch(pageLoader(false));
        setOtpScreen(true);
        dispatch({
          type: FORGOT_PASSWORD_OTP,
          payload: data.data,
        });
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const VerifyOtp =
  (userEntry, setOtpScreen, setCreatepasswordScreen, setErrorAlert) =>
  async (dispatch) => {
    try {
      const { data } = await api.post("/super-admin/verifyOtp", {
        ...userEntry,
      });
      console.log(data);
      dispatch(pageLoader(false));
      if (data.data.isVerified) {
        localStorage.setItem("passwordToken", JSON.stringify(data.data.token));
        setOtpScreen(false);
        setCreatepasswordScreen(true);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const resetForgetPassword =
  (userEntry, setSuccessModal, setErrorAlert) => async (dispatch) => {
    try {
      const token = JSON.parse(localStorage.getItem("passwordToken"));
      const { data } = await api.post(
        "/super-admin/reset-password",
        { ...userEntry },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      dispatch(pageLoader(false));
      if (data.success) {
        localStorage.removeItem("passwordToken");
        setSuccessModal(true);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      setErrorAlert(true);
      dispatch(pageLoader(false));
    }
  };

export const setHeaderName = (name) => async (dispatch) => {
  dispatch({
    type: HEADER_NAME,
    payload: name,
  });
};

export const pageLoader = (value) => async (dispatch) => {
  dispatch({
    type: PAGE_LOADER,
    payload: value,
  });
};

export const setToggleSidebar = (value) => async (dispatch) => {
  dispatch({
    type: TOGGLE_SIDEBAR,
    payload: value,
  });
};

export const resetPassword = (userEntry, setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.post(
      "/super-admin/reset-user-password",
      userEntry,
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      }
    );
    console.log(data);
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: USER_RESET_PASSWORD_SUCCESS,
        payload: data.message,
      });
    }
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    setErrorAlert(true);
    dispatch(pageLoader(false));
  }
};

export const setSortName = (value) => async (dispatch) => {
  dispatch({
    type: SORT_BY_VALUE,
    payload: value,
  });
};

export const setPageValue = (value) => async (dispatch) => {
  dispatch({
    type: PAGINATION_VALUE,
    payload: value,
  });
};

export const setShowLogoutModal = (value) => async (dispatch) => {
  dispatch({
    type: SHOW_LOGOUT_MODAL,
    payload: value,
  });
};

export const getAllPayments = (userEntry) => async (dispatch) => {
  try {
    const { data } = await authApi.get(
      `/payment/list?page=${userEntry?.page}&limit=10&search=${userEntry?.search}&status=${userEntry?.collectionType === "All" ? "" : userEntry?.collectionType?.toUpperCase()}&sort=${userEntry?.sortBy ? userEntry?.sortBy : "updatedAt-desc"}`,
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      }
    );
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: ALL_PAYMENT_LIST,
        payload: data?.data,
      });
    }
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: ALL_PAYMENT_LIST,
      payload:{payments:[],totalCount:0},
    });
    
    dispatch(pageLoader(false));
  }
};

export const getPaymentsCounts = (setPaymentCount) => async (dispatch) => {
  try {
    const { data } = await authApi.get(
      `/payment/piechart`,
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      }
    );
    dispatch(pageLoader(false));
    if (data.success) {
      setPaymentCount(data?.data);
    }
  } catch (error) {
    setPaymentCount(null);
    
    dispatch(pageLoader(false));
  }
};