module.exports = {
    ADD_ACCESS_LEVEL_SUCCESS: "ADD_ACCESS_LEVEL_SUCCESS",
    ADD_ACCESS_LEVEL_FAIL: "ADD_ACCESS_LEVEL_FAIL",
    GET_ALL_ACCESS_LEVEL_SUCCESS: "GET_ALL_ACCESS_LEVEL_SUCCESS",
    GET_ALL_ACCESS_LEVEL_FAIL: "GET_ALL_ACCESS_LEVEL_FAIL",
    ADD_ROLES_SUCCESS: "ADD_ROLES_SUCCESS",
    ADD_ROLES_FAIL: "ADD_ROLES_FAIL",
    GET_ALL_ROLES_SUCCESS: "GET_ALL_ROLES_SUCCESS",
    GET_ALL_ROLES_FAIL: "GET_ALL_ROLES_FAIL",
    GET_ONE_ROLE_SUCCESS: "GET_ONE_ROLE_SUCCESS",
    GET_ONE_ROLE_FAIL: "GET_ONE_ROLE_FAIL",
    SET_PIECHART_SUCCESS: "SET_PIECHART_SUCCESS",
    SET_PIECHART_FAIL: "SET_PIECHART_FAIL",
    GET_SCHOOL_ROLES_SUCCESS: "GET_SCHOOL_ROLES_SUCCESS",
    GET_SCHOOL_ROLES_FAIL: "GET_SCHOOL_ROLES_FAIL",
}