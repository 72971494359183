import React, { useState } from "react";
import {
  Card,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import classes from "./classManagement.module.css";
import {
  RoundAddIcon,
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  pageLoader,
  setHeaderName,
  setPageValue,
} from "../../../Redux/actions/userActions";
import ClassListItem from "./classListItem";
import DefaultPagination from "../../../Components/Pagination";
import SchoolDropDown from "../../../Components/schoolDropDown";
import AlertBox from "../../../Components/AlertBox";
import { useEffect } from "react";
import { getAllClasses } from "../../../Redux/actions/classActions";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import NodataFound from "../../../Components/nodataFound";

const ClassManagementIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [school, setSchool] = useState("select");
  const [defaultError, setDefaultError] = useState("");
  const { classesList, error, totalCount } = useSelector(
    (state) => state.classDatas
  );
  const { sortBy, page } = useSelector((state) => state.userLogin);
  const CloseAlert = () => {
    setErrorAlert(false);
  };

  useEffect(() => {
    dispatch(setPageValue(1));
  }, [dispatch]);

  const handleChange = (e) => {
    console.log(e.target.value);
    setSchool(e.target.value);
  };

  useEffect(() => {
    if (school !== "select") {
      dispatch(pageLoader(true));
      dispatch(
        getAllClasses(
          setErrorAlert,
          search.replaceAll(" ", "_"),
          school,
          sortBy,
          page
        )
      );
    }
  }, [school, sortBy, page, search, dispatch]);

  const handleSeachOption = () => {
    if (school !== "select") {
      dispatch(pageLoader(true));
      dispatch(getAllClasses(setErrorAlert, search, school, sortBy, page));
    } else {
      setErrorAlert(true);
      setDefaultError("Please Select Any School");
    }
  };

  const handleAddClassClick = () => {
    if (school === "select") {
      setErrorAlert(true);
      setDefaultError("Please Select Any School");
    } else {
      dispatch(setHeaderName("add-class"));
      navigate(`/add-class/${school}`);
    }
  };

  return (
    <div>
      <Card className={classes.CardContainer}>
        <div style={{ padding: "20px" }}>
          <Typography className={classes.PageMaindiv}>
            All Class Details
          </Typography>
          <div className={`mt-4 ${classes.FilterContainerMain}`}>
            <div className={classes.HeadertextFeildContainer}>
              <div>
                <SchoolDropDown
                  value={school}
                  handleChange={handleChange}
                  width={"250px"}
                />
              </div>
              <div>
                <TextField
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                  }}
                  fullWidth
                  placeholder="Search by Class name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  className={`${classes.TextInput} ${classes.searchBar}`}
                  id="firstNameinput"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  size="small"
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  className={classes.searchButton}
                  onClick={handleSeachOption}
                >
                  <span className={classes.nrmlBtnText}>Search</span>
                </Button>
              </div>
            </div>
            <div
              className={`d-flex align-items-center ${classes.btnContainer}`}
              style={{
                columnGap: "10px",
              }}
            >
              <div
                className={`${classes.DividerClasses} ${classes.vertical}`}
              ></div>
              <Button
                variant="contained"
                className={`d-flex align-items-center ${classes.searchButton}`}
                onClick={() => handleAddClassClick()}
              >
                <span style={{ marginRight: "10px" }}>
                  <RoundAddIcon />
                </span>
                <span className={classes.mainBtnText}>Add New</span>
              </Button>
            </div>
          </div>
        </div>
        {totalCount && totalCount > 0 && school !== "select" ? (
          <div className={`mt-3 ${classes.TableContainer}`}>
            <Table className={classes.TableClass}>
              <TableHead>
                <TableRow className={classes.TableHeaderRow}>
                  <TableCell
                    flex={1}
                    className={classes.firstCell}
                    style={{ minWidth: 105 }}
                  >
                    <span className={classes.TableHeaderText}>Grade ID</span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "gradeId")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("gradeId") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: 125 }}>
                    <span className={classes.TableHeaderText}>
                      {classesList[0]?.type === "CLASS"
                        ? "Class Name"
                        : classesList[0]?.type === "GRADE"
                        ? "Grade Name"
                        : "YEARS"}
                    </span>
                    <span
                      onClick={() => handleSortClick(dispatch, sortBy, "grade")}
                    >
                      {sortBy?.includes("desc") && sortBy?.includes("grade") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: 130 }}>
                    <span className={classes.TableHeaderText}>
                      Section Name
                    </span>
                    <span
                      onClick={() =>
                        handleSortClick(dispatch, sortBy, "terminology.section")
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("terminology.section") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1} style={{ minWidth: 130 }}>
                    <span className={classes.TableHeaderText}>
                      Total Student
                    </span>
                    <span
                      onClick={() =>
                        handleSortClick(
                          dispatch,
                          sortBy,
                          "terminology.numberOfStudent"
                        )
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("terminology.numberOfStudent") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={1}>
                    <span className={classes.TableHeaderText}>Status</span>
                    <span
                      onClick={() =>
                        handleSortClick(
                          dispatch,
                          sortBy,
                          "terminology.isActive"
                        )
                      }
                    >
                      {sortBy?.includes("desc") &&
                      sortBy?.includes("terminology.isActive") ? (
                        <SortIconOpposite />
                      ) : (
                        <SortIcon />
                      )}
                    </span>
                  </TableCell>
                  <TableCell flex={0}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classesList
                  .filter((item) => item.schoolId === school)
                  .map((item, index) => {
                    return <ClassListItem item={item} key={index} />;
                  })}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div>
            <NodataFound text={"Please select a School "} />
          </div>
        )}
        {totalCount && totalCount > 0 && school !== "select" ? (
          <div className={`mt-3`}>
            <DefaultPagination count={totalCount} />
          </div>
        ) : null}
      </Card>
      {errorAlert && error ? (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      ) : defaultError && defaultError !== "" ? (
        <AlertBox type="error" message={defaultError} stateName={CloseAlert} />
      ) : null}
    </div>
  );
};

export default ClassManagementIndex;
