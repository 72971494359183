import React, { useState } from "react";
import classes from "./school.module.css";
import {
  BrudCrumsNextIcon,
  CloseEyeIcon,
  ContentCopyIcon,
  OpenEyeIcon,
  RefreshIcon,
  RoundAddPurpleIcon,
} from "../../../Assets/Icons/icons";
import copy from "copy-to-clipboard";
import {
  Card,
  MenuItem,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Row, Col, Button } from "react-bootstrap";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Numeric, generatePassword } from "../../../Utilities/commonFunctions";
import Footer from "../../../Components/Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import {
  addSchool,
  getOneSchools,
  updateSchool,
} from "../../../Redux/actions/schoolActions";
import AlertBox from "../../../Components/AlertBox";
import { useEffect } from "react";
import { getAllSubscriptions } from "../../../Redux/actions/subscriptionAction";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const AddSchoolPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [schoolName, setSchoolName] = useState("");
  const [address, setAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [noOfStudents, setNoOfStudents] = useState("");
  const [noOfTeachers, setNoOfTeachers] = useState("");
  const [subscriptionLevel, setSubscriptionLevel] = useState("0");
  const [batch, setBatch] = useState("0");
  const [defaultSuccess, setDefaultSuccess] = useState(false);
  const [defaultSuccessMessage, setDefaultSuccessMessage] = useState("");
  const [generateClicked, setGenerateClicked] = useState(false);
  const [accountEmail, setAccountEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [accountEmailError, setAccountEmailError] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [countryCode, setCountryCode] = useState("1");
  const [selectedCountry, setSelectedCountry] = useState("us");
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [defaultError, setDefaultError] = useState("");
  const { error, success, oneSchool } = useSelector(
    (state) => state.schoolDatas
  );
  const { error: subscriptionError, subscriptionList } = useSelector(
    (state) => state.subscriptionDatas
  );

  useEffect(() => {
    if (window.location.pathname.includes("edit")) {
      const path = window.location.pathname;
      const arr = path.split("/");
      dispatch(pageLoader(true));
      dispatch(getOneSchools(arr[arr.length - 1], setErrorAlert));
    }
    dispatch(getAllSubscriptions(setErrorAlert, "", "", 1, 50));
  }, [dispatch]);

  const handleGeneratePasswordclick = () => {
    setGenerateClicked(true);
    generatePassword(schoolName, setPassword);
  };

  const copyTrst = (text) => {
    copy(text);
    setDefaultSuccess(true);
    setDefaultSuccessMessage("Password has Copied Successfully");
  };

  useEffect(() => {
    if (oneSchool && window.location.pathname.includes("edit")) {
      setSchoolName(oneSchool.name);
      setAddress(oneSchool.address);
      setMobileNumber(oneSchool.user?.phone);
      setEmail(oneSchool.user?.email);
      setAccountEmail(oneSchool.user?.email)
      setNoOfStudents(oneSchool.numberOfStudent);
      setNoOfTeachers(oneSchool.numberOfTeacher);
      setSubscriptionLevel(oneSchool?.subscription_id);
      setBatch(oneSchool.batch);
      setCountryCode(oneSchool.user?.country_code);
      setSelectedCountry(oneSchool.user?.country_id);
    }
  }, [oneSchool]);

  const handleNavigateSubscription = () => {
    dispatch(setHeaderName("add-subscription"));
    navigate("/add-subscription");
  };

  const handleSubmit = () => {
    console.log(noOfStudents, noOfTeachers);
    console.log("isValid =",isValid);
    console.log("mobileNumber =",mobileNumber?.length != 10);
    // if (mobileNumber?.length != 10) {
    //   setIsValid(false);
    // } else
     if (!isValid) {
      setIsValid(false);
    }
    else if (subscriptionLevel === "0") {
      setErrorAlert(true);
      setDefaultError("Please Select Subscription Level");
    } else if (
      parseInt(noOfStudents) !== 0 &&
      parseInt(noOfTeachers) !== 0 &&
      email === accountEmail
    ) {
      let userEntry = {
        name: schoolName,
        email: email,
        phone: mobileNumber,
        address: address,
        numberOfStudent: noOfStudents,
        numberOfTeacher: noOfTeachers,
        country_code: countryCode,
        country_id: selectedCountry,
      };
      console.log(userEntry);
      dispatch(pageLoader(true));
      if (window.location.pathname.includes("edit")) {
        userEntry.Id = oneSchool._id;
        dispatch(
          updateSchool(
            oneSchool._id,
            userEntry,
            setSuccessAlert,
            setErrorAlert,
            navigate
          )
        );
      } else {
        userEntry.isActive = true;
        userEntry.subscription_id = subscriptionLevel;
        userEntry.password = password;
        userEntry.batch = batch !== "0" ? batch : "";
        dispatch(
          addSchool(userEntry, setSuccessAlert, setErrorAlert, navigate)
        );
      }
    }
  };

  const handleNavigateSchool = () => {
    dispatch(setHeaderName("school"));
    navigate("/school");
  };

  const CloseAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
    setDefaultSuccess(false);
    setDefaultSuccessMessage("");
  };

  const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
    setSelectedCountry(countryData.iso2);
    setMobileNumber("");
  }
  
  const handlePhoneNumberChange = (isValidNum, value, country) => {
    setIsValid(isValidNum);
    setCountryCode(country?.dialCode);
    setSelectedCountry(country?.iso2);
    setMobileNumber(value);
    console.log('isValid:', isValid);
    console.log('Phone number value:', value);
    console.log('Selected country:', country);
  };

  return (
    <div>
      <div className={classes.brudcrumbs}>
        <span
          className={classes.TableHeaderText}
          onClick={() => handleNavigateSchool()}
        >
          School
        </span>
        <span>
          <BrudCrumsNextIcon />
        </span>
        <span className={classes.TableBodyText}>
          {window.location.pathname.includes("edit")
            ? oneSchool?.name
            : "Add School"}
        </span>
      </div>
      <ValidatorForm
        onSubmit={handleSubmit}
        useref="form"
        className={`mt-3 ${classes.formContainer}`}
      >
        <Card
          style={{
            padding: "20px",
            overflow:"visible"
          }}
        >
          <Typography className={classes.SchoolsMainHeader}>
            School Details
          </Typography>

          <div className="mt-4">
            <Row>
              <Col md={4}>
                <div className="mb-1">
                  <Typography className={`${classes.TableBodyText} mb-1`}>
                    School Name
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="schoolNameInput"
                    fullWidth
                    className={classes.TextInput}
                    placeholder="Enter school name"
                    value={schoolName}
                    onChange={(e) => {
                      setSchoolName(e.target.value);
                    }}
                    size="small"
                    validators={["required", "matchRegexp:[A-Za-z]"]} // Add this line
                    errorMessages={[
                      "School Name is required",
                      "Invalid School Name",
                    ]}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-1">
                  <Typography className={`${classes.TableBodyText} mb-1`}>
                    Address
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="addressInput"
                    fullWidth
                    className={classes.TextInput}
                    placeholder="Enter full address"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    size="small"
                    validators={["required", "matchRegexp:[A-Za-z]"]} // Add this line
                    errorMessages={["Address is required", "Invalid Address"]}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-1">
                  <Typography className={`${classes.TableBodyText} mb-1`}>
                    Contact Number
                  </Typography>
                  {/* <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="mobileNumberInput"
                    fullWidth
                    className={classes.TextInput}
                    placeholder="Enter contact number"
                    value={mobileNumber}
                    inputProps={{ maxLength: 10 }}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                    }}
                    size="small"
                    validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                    errorMessages={[
                      "Contact Number is required",
                      "Enter a valid contact number",
                    ]}
                  /> */}
                {  selectedCountry && <div className={classes.new_countrycode_field} >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control custom-placeholder"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      value={mobileNumber}
                      defaultCountry={selectedCountry}
                    />
                    {!isValid && <p className={classes.err_text}>Enter Valid number</p>}
                  </div>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="mb-1">
                  <Typography className={`${classes.TableBodyText} mb-1`}>
                    Email ID
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="emailInput"
                    fullWidth
                    className={classes.TextInput}
                    placeholder="Enter school email ID"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    size="small"
                    validators={["required", "isEmail"]} // Add this line
                    errorMessages={["Email is required", "Invalid email"]}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-1">
                  <Typography className={`${classes.TableBodyText} mb-1`}>
                    No. of students
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="noOfStudentsInput"
                    fullWidth
                    className={classes.TextInput}
                    placeholder="Enter No. of students"
                    value={noOfStudents}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    onChange={(e) => {
                      setNoOfStudents(e.target.value);
                    }}
                    size="small"
                    validators={["required"]} // Add both "required" and custom validator
                    errorMessages={["No. of students is required"]} //
                    error={parseInt(noOfStudents) === 0}
                    helperText={
                      parseInt(noOfStudents) === 0
                        ? "No. of students should be greater than 0"
                        : ""
                    }
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-1">
                  <Typography className={`${classes.TableBodyText} mb-1`}>
                    No. of Teachers
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="noOfTeachersInput"
                    fullWidth
                    className={classes.TextInput}
                    placeholder="Enter No. of teachers"
                    value={noOfTeachers}
                    onChange={(e) => {
                      setNoOfTeachers(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    size="small"
                    validators={["required"]} // Add this line
                    errorMessages={["No. of teachers is required"]}
                    error={parseInt(noOfTeachers) === 0}
                    helperText={
                      parseInt(noOfTeachers) === 0
                        ? "No. of teachers should be greater than 0"
                        : ""
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Card>
        <Card
          className="mt-4"
          style={{
            padding: "20px",
          }}
        >
          <Typography className={classes.SchoolsMainHeader}>
            Subscription Details
          </Typography>

          <div className="mt-4">
            <Row>
              <Col md={4}>
                <div className="mb-1">
                  <Typography className={`${classes.TableBodyText} mb-1`}>
                    Select Subscription Level
                  </Typography>
                  {window.location.pathname.includes("edit") ? (
                    <Typography
                      className={classes.MenuItemText}
                      style={{
                        marginLeft: "0px",
                      }}
                    >
                      {oneSchool?.subscription_info?.subscription_name}
                    </Typography>
                  ) : (
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="subscriptonLevelInput"
                      fullWidth
                      select
                      value={subscriptionLevel}
                      onChange={(e) => {
                        setSubscriptionLevel(e.target.value);
                      }}
                      size="small"
                      className={
                        subscriptionLevel === "0"
                          ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                          : `${classes.DropdownTextInput}`
                      }
                      validators={["required"]} // Add this line
                      errorMessages={["Subscription Level is required"]}
                    >
                      <MenuItem value="0" style={{ display: "none" }}>
                        Select subscription Level
                      </MenuItem>
                      {subscriptionList
                        .filter(
                          (item) => item.type === "B2B" || item.type === "BOTH"
                        )
                        .map((item, index) => {
                          return (
                            <MenuItem key={index} value={item._id}>
                              {item.subscription_name}
                            </MenuItem>
                          );
                        })}
                    </TextValidator>
                  )}
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-1">
                  <Typography className={`${classes.TableBodyText} mb-1`}>
                    Select Batch (Optional)
                  </Typography>
                  {window.location.pathname.includes("edit") ? (
                    <Typography
                      className={classes.MenuItemText}
                      style={{
                        marginLeft: "0px",
                      }}
                    >
                      {oneSchool?.batch}
                    </Typography>
                  ) : (
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="batchInput"
                      select
                      fullWidth
                      className={
                        batch === "0"
                          ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                          : `${classes.DropdownTextInput}`
                      }
                      value={batch}
                      onChange={(e) => {
                        setBatch(e.target.value);
                      }}
                      size="small"
                      validators={["required"]} // Add this line
                      errorMessages={["Address is required"]}
                    >
                      <MenuItem value="0" style={{ display: "none" }}>
                        Select Batch
                      </MenuItem>
                      {["GRADE", "CLASS", "YEARS"].map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={item}
                            style={{ textTransform: "capitalize" }}
                          >
                            {item.toLowerCase()}
                          </MenuItem>
                        );
                      })}
                    </TextValidator>
                  )}
                </div>
              </Col>
            </Row>
            {/* {!window.location.pathname.includes("edit") && (
              <div className="d-flex">
                <Button
                  variant="text"
                  className={classes.AddButton}
                  onClick={handleNavigateSubscription}
                >
                  <span style={{ marginRight: "10px" }}>
                    <RoundAddPurpleIcon />
                  </span>
                  Add Subscription Level
                </Button>
              </div>
            )} */}
          </div>
        </Card>
        <Card className={`mt-3`} style={{ padding: "20px", textAlign: "left" }}>
          <Typography className={classes.StudentPageMainHeading}>
            Account Details
          </Typography>
          <Row className="mt-3">
            <Col md={4}>
              <div>
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText}`}
                >
                  Account Email ID
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="accountEmailInput"
                  fullWidth
                  placeholder="Enter account email ID"
                  className={classes.TextInput}
                  value={accountEmail}
                  onChange={(e) => {
                    setAccountEmail(e.target.value);
                    setAccountEmailError(email === e.target.value);
                    if (e.target.value !== email) {
                      setGenerateClicked(false);
                    }
                  }}
                  size="small"
                  validators={["required", "isEmail"]} // Add this line
                  errorMessages={[
                    "Account Email ID is required",
                    "Enter a valid email ID",
                  ]}
                  error={!accountEmailError}
                  helperText={
                    !accountEmailError
                      ? "Account Email ID does not match with Email ID"
                      : ""
                  }
                />
              </div>
            </Col>
            {window.location.pathname.includes("edit") ? (
              <Col md={4}>
                {/* <div style={{ position: "relative" }}>
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingTextLable}`}
                  >
                    Password
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    placeholder="Enter Password"
                    className={classes.TextInput}
                    size="small"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div> */}
              </Col>
            ) : (
              <Col md={3}>
                <Button
                  className={
                    email !== accountEmail ||
                    email === "" ||
                    accountEmail === ""
                      ? `${classes.PassgenerateFunction} ${classes.disabledBtn}`
                      : classes.PassgenerateFunction
                  }
                  disabled={
                    email !== accountEmail ||
                    email === "" ||
                    accountEmail === ""
                  }
                  variant="contained"
                  onClick={() => {
                    handleGeneratePasswordclick();
                  }}
                >
                  Generate Password
                </Button>
              </Col>
            )}
          </Row>
          {generateClicked && accountEmail === email ? (
            <div>
              <Typography className={`mt-3 ${classes.passwordSentence}`}>
                Metaverse create a uniq password for you. Once you are done,
                Click the copy button
              </Typography>
              <Row className="mt-2">
                <Col xs={10} md={4} style={{ position: "relative" }}>
                  <Typography
                    className={`${classes.TableBodyText} ${classes.HeadingTextLable}`}
                  >
                    Password
                  </Typography>
                  <TextValidator
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                    }}
                    id="outlined-adornment-password"
                    type={"text"}
                    fullWidth
                    placeholder="Enter Password"
                    className={classes.TextInput}
                    size="small"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    validators={["required"]}
                    errorMessages={["Password is required"]}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              generatePassword(schoolName, setPassword)
                            }
                            edge="end"
                          >
                            <RefreshIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className={classes.copyIcon}>
                    <Button
                      variant="text"
                      onClick={() => {
                        copyTrst(password);
                      }}
                    >
                      <ContentCopyIcon />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Typography className={classes.NoteHeader}>
                Note:{" "}
                <span className={classes.Notetext}>
                  Once you click on submit your access ID and password send to
                  the parent email ID
                </span>
              </Typography>
            </div>
          ) : null}
        </Card>
        <Footer
          callback={handleNavigateSchool}
          edit={window.location.pathname.includes("edit")}
        />
      </ValidatorForm>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {errorAlert && defaultError && (
        <AlertBox type="error" message={defaultError} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox
          type="success"
          message={success}
          stateName={CloseAlert}
          callback={handleNavigateSchool}
        />
      )}
      {defaultSuccess && defaultSuccessMessage && (
        <AlertBox
          type="success"
          message={defaultSuccessMessage}
          stateName={CloseAlert}
        />
      )}
    </div>
  );
};

export default AddSchoolPage;
