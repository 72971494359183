import { Navigate, createBrowserRouter } from "react-router-dom";
import GuestLayout from "./Layouts/guestLayout";
import Login from "./Pages/Auth/Login";
import ForgotPasswordIndex from "./Pages/Auth/forgotPasswordIndex";
import DefaultLayout from "./Layouts/defaultLayout";
import Home from "./Pages/Screens/Home/Home";
import SchoolIndex from "./Pages/Screens/Schools/schoolIndex";
import ClassManagementIndex from "./Pages/Screens/classManagement/classManagementIndex";
import StudentsIndex from "./Pages/Screens/Student/studentsIndex";
import TeachersIndex from "./Pages/Screens/Teachers/teachersIndex";
import CousesGamesIndex from "./Pages/Screens/CourseGames/CousesGamesIndex";
import ChatIndex from "./Pages/Screens/chat/chatIndex";
import PaymentIndex from "./Pages/Screens/payment/paymentIndex";
import RoleManagementIndex from "./Pages/Screens/roleManagement/roleManagement";
import AddSchoolPage from "./Pages/Screens/Schools/AddSchoolPage";
import SchoolDetailsViewPage from "./Pages/Screens/Schools/schoolDetailsViewPage";
import AddClassPage from "./Pages/Screens/classManagement/addClass";
import AddStudent from "./Pages/Screens/Student/addStudent";
import ClassDetailsViewPage from "./Pages/Screens/classManagement/classDetailsViewPage";
import StudentsViewPage from "./Pages/Screens/Student/StudentsViewPage";
import AddTeacher from "./Pages/Screens/Teachers/addTeacher";
import TeacherViewPage from "./Pages/Screens/Teachers/teacherViewPage";
import AddCourseScreen from "./Pages/Screens/CourseGames/AddCourseScreen";
import ViewgameDetails from "./Pages/Screens/CourseGames/viewgameDetails";
import AddNewRole from "./Pages/Screens/roleManagement/addNewRole";
import SubscriptionMangement from "./Pages/Screens/subscriptionManagement/subscriptionManagementIndex";
import AddSubscription from "./Pages/Screens/subscriptionManagement/addSubscription";
import Coupons from "./Pages/Screens/coupon/Coupons";
import AddCoupon from "./Pages/Screens/coupon/AddCoupon";

const Router = createBrowserRouter([
    {
        path: "/",
        element: <GuestLayout />,
        children: [
            {
                path: "/",
                element: <Navigate to="/login" />,
            },
            {
                path: "/login",
                element: <Login />,
            },
            {
                path: "/forgot-password",
                element: <ForgotPasswordIndex />,
            },
        ],
    },
    {
        path: "/",
        element: <DefaultLayout />,
        children: [
            {
                path: "/",
                element: <Navigate to="/dashboard" />,
            },
            {
                path: "/dashboard",
                element: <Home />,
            },
            {
                path: "/school",
                element: <SchoolIndex />,
            },
            {
                path: "/add-school",
                element: <AddSchoolPage />,
            },
            {
                path: "/edit-school-details/:id",
                element: <AddSchoolPage />,
            },
            {
                path: "/view-school-details/:id",
                element: <SchoolDetailsViewPage />,
            },
            {
                path: "/class-management",
                element: <ClassManagementIndex />,
            },
            {
                path: "/view-class/:id",
                element: <ClassDetailsViewPage />,
            },
            {
                path: "/add-class/:id",
                element: <AddClassPage />,
            },
            {
                path: "/edit-class/:id",
                element: <AddClassPage />,
            },
            {
                path: "/students",
                element: <StudentsIndex />,
            },
            {
                path: "/add-student/:name",
                element: <AddStudent />,
            },
            {
                path: "/edit-student-details/:id",
                element: <AddStudent />,
            },
            {
                path: "/view-student-details/:id",
                element: <StudentsViewPage />,
            },
            {
                path: "/teachers",
                element: <TeachersIndex />,
            },
            {
                path: "/add-teacher",
                element: <AddTeacher />,
            },
            {
                path: "/edit-teacher-details/:id",
                element: <AddTeacher />,
            },
            {
                path: "/view-teacher-details/:id",
                element: <TeacherViewPage />,
            },
            {
                path: "/cources",
                element: <CousesGamesIndex />,
            },
            {
                path: "/add-game",
                element: <AddCourseScreen />,
            },
            {
                path: "/edit-game-details/:id",
                element: <AddCourseScreen />,
            },
            {
                path: "/view-game/:id",
                element: <ViewgameDetails />,
            },
            {
                path: "/role-management",
                element: <RoleManagementIndex />,
            },
            {
                path: "/add-new-role/:id",
                element: <AddNewRole />,
            },
            {
                path: "/edit-role/:id",
                element: <AddNewRole />,
            },
            {
                path: "/chat",
                element: <ChatIndex />,
            },
            {
                path: "/coupons",
                element: <Coupons />,
            },
            {
                path: "/add-coupon",
                element: <AddCoupon />,
            },
            {
                path: "/payment",
                element: <PaymentIndex />,
            },
            {
                path: "/subscription-management",
                element: <SubscriptionMangement />,
            },
            {
                path: "/add-subscription",
                element: <AddSubscription />,
            },
            {
                path: "/edit-subscription-details/:id",
                element: <AddSubscription />,
            },
        ],
    },
]);

export default Router;