import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from "react-router-dom";

const GuestLayout = () => {
    const navigate = useNavigate();
    const { loading } = useSelector(state => state.userLogin)

    useEffect(() => {
        const token = localStorage.getItem("userToken");
        if (token) {
            navigate("/dashboard")
        }
    }, [])

    return (
        <div>
            <Outlet />
            {loading &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </div>
    );
}

export default GuestLayout