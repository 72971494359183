import { DashboardCourseIcon, DashboardPaymentIcon, DashboardSchoolIcon, DashboardStudentIcon } from "../../Assets/Icons/icons";
import { DASHBOARD_FIRST_PIECHART_FAIL, DASHBOARD_FIRST_PIECHART_SUCCESS } from "../contants/dashboardConstants";

const initialState = {
    error: null,
    firstPieChart: [
        {
            icon: <DashboardSchoolIcon />,
            title: "Total Schools",
            number: "0",
            key: "totalSchools",
            navigate: "/school",
        },
        {
            icon: <DashboardStudentIcon />,
            title: "Total Students",
            number: "0",
            key: "totalStudents",
            navigate: "/students",
        },
        {
            icon: <DashboardCourseIcon />,
            title: "Total Games / Courses",
            number: "0",
            key: "totalCourses",
            navigate: "/cources",
        },
        {
            icon: <DashboardPaymentIcon />,
            title: "Earnings",
            number: "96000",
            key: "totalEarning",
            navigate: "/payment",
        },
    ]
};

export const dashboardReducers = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_FIRST_PIECHART_SUCCESS:
            return { ...state, error: null, firstPieChart: action.payload };
        case DASHBOARD_FIRST_PIECHART_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};