module.exports = {
    schoolDetails: [
        {
            id: '#001',
            name: 'Robb International School',
            address: '6560 Braddock Rd, Alexandria, Virginia',
            studentsCount: 100,
            phone: '+44 8844585555',
            email: 'robbininternational@gmail.com',
            status: 'Active',
        },
        {
            id: '#002',
            name: 'Thomas Jefferson School',
            address: '72 Argyll St, Dunoon, Argyll',
            studentsCount: 400,
            phone: '+44 8844585555',
            email: 'thomasjefferson@gmail.com',
            status: 'Active',
        },
        {
            id: '#003',
            name: 'Academic Magnet School',
            address: '7 Crown St, Aberdeen, Aberdeen City',
            studentsCount: 600,
            phone: '+44 8844585555',
            email: 'academicmagnet@gmail.com',
            status: 'Active',
        },
        {
            id: '#004',
            name: 'Signature School',
            address: 'New Bridge Ln, Stockport, Cheshire',
            studentsCount: 606,
            phone: '+44 8844585555',
            email: 'signatureschool@gmail.com',
            status: 'Inactive',
        },
        {
            id: '#005',
            name: 'The Davidson Academy',
            address: '29 Bread St, Edinburgh, City of Edinburgh',
            studentsCount: 543,
            phone: '+44 8844585555',
            email: 'thedavidson@gmail.com',
            status: 'Active',
        },
        {
            id: '#006',
            name: 'Central Magnet School',
            address: 'Wreakes Ln, Dronfield, Derbyshire',
            studentsCount: 789,
            phone: '+44 8844585555',
            email: 'centralmagnet@gmail.com',
            status: 'Inactive',
        },
        {
            id: '#007',
            name: 'Robb International School',
            address: '5 N Rd, Newtownards, Down',
            studentsCount: 769,
            phone: '+44 8844585555',
            email: 'robbininternational@gmail.com',
            status: 'Active',
        },
    ],
    classManagementDetails: [
        {
            ID: "345667",
            Class: "A",
            Grade: "Grade 1",
            schoolName: 'Robb International School',
            Sections: [
                {
                    Section: "Section 1",
                    Value: 32,
                    Status: "Active"
                },
                {
                    Section: "Section 2",
                    Value: 45,
                    Status: "Active"
                },
                {
                    Section: "Section 3",
                    Value: 56,
                    Status: "Active"
                }
            ]
        },
        {
            ID: "765667",
            Class: "B",
            Grade: "Grade 2",
            schoolName: 'Robb International School',
            Sections: [
                {
                    Section: "Section 1",
                    Value: 34,
                    Status: "Active"
                },
                {
                    Section: "Section 2",
                    Value: 23,
                    Status: "Inactive"
                }
            ]
        },
        {
            ID: "445666",
            Class: "C",
            Grade: "Grade 3",
            schoolName: 'Robb International School',
            Sections: [
                {
                    Section: "Section 1",
                    Value: 44,
                    Status: "Inactive"
                },
                {
                    Section: "Section 2",
                    Value: 48,
                    Status: "Active"
                }
            ]
        }
    ],
    InduvidualstudentData: [
        {
            id: '#001',
            name: 'Desirae Aminoff',
            age: 8,
            grade: 'Grade 3',
            parentName: 'Maria Aminoff',
            level: 'Level 2',
            phone: '+44 8844585555',
            email: 'mariaaminoff@gmail.com',
            section: 'Class A'
        },
        {
            id: '#002',
            name: 'Jaxson Schleifer',
            age: 7,
            grade: 'Grade 2',
            parentName: 'Kylis Chleifer',
            level: 'Level 1',
            phone: '+44 7844585555',
            email: 'Kylischleifer@gmail.com',
            section: 'Class B'
        },
        {
            id: '#003',
            name: 'Marilyn Culhane',
            age: 10,
            grade: 'Grade 4',
            parentName: 'Jenny Nculhane',
            level: 'Level 3',
            phone: '+44 8844587676',
            email: 'jennynculhane@gmail.com',
            section: 'Class A'
        },
        {
            id: '#004',
            name: 'Kianna Rosser',
            age: 9,
            grade: 'Grade 4',
            parentName: 'Kinar Osser',
            level: 'Level 1',
            phone: '+44 8846543555',
            email: 'Kinarosser@gmail.com',
            section: 'Class B'
        },
        {
            id: '#005',
            name: 'Skylar Rhiel',
            age: 7,
            grade: 'Grade 2',
            parentName: 'Rosh Anhiel',
            level: 'Level 4',
            phone: '+44 8645383838',
            email: 'Roshanhiel@gmail.com',
            section: 'Class A'
        },
        {
            id: '#006',
            name: 'Anika Workman',
            age: 7,
            grade: 'Grade 2',
            parentName: 'Maya Workman',
            level: 'Level 2',
            phone: '+44 7845362789',
            email: 'Mayaworkman@gmail.com',
            section: 'Class B'
        }
    ],
    SchoolStudentData: [
        {
            id: '001',
            name: 'Ethan Johnson',
            age: 8,
            grade: 'Grade 3',
            schoolName: 'Pinecrest Elementary School',
            class: 'Class A',
            phone: '+44 7890123456',
            email: 'ethan.johnson@example.com',
            status: 'Active',
            section: 'Class A'
        },
        {
            id: '002',
            name: 'Olivia Smith',
            age: 7,
            grade: 'Grade 2',
            schoolName: 'Meadowbrook Primary School',
            class: 'Class B',
            phone: '+44 9876543210',
            email: 'olivia.smith@example.com',
            status: 'Active',
            section: 'Class B'
        },
        {
            id: '003',
            name: 'Noah Brown',
            age: 9,
            grade: 'Grade 4',
            schoolName: 'Hillside Elementary School',
            class: 'Class A',
            phone: '+44 7654321098',
            email: 'noah.brown@example.com',
            status: 'Active',
            section: 'Class A'
        },
        {
            id: '004',
            name: 'Ava Wilson',
            age: 7,
            grade: 'Grade 2',
            schoolName: 'Crestwood Primary School',
            class: 'Class B',
            phone: '+44 1234567890',
            email: 'ava.wilson@example.com',
            status: 'Active',
            section: 'Class B'
        },
        {
            id: '005',
            name: 'Liam Lee',
            age: 8,
            grade: 'Grade 3',
            schoolName: 'Maplewood Elementary School',
            class: 'Class A',
            phone: '+44 8765432109',
            email: 'liam.lee@example.com',
            status: 'Active',
            section: 'Class A'
        },
        {
            id: '006',
            name: 'Sophia Davis',
            age: 7,
            grade: 'Grade 2',
            schoolName: 'Sunrise Primary School',
            class: 'Class B',
            phone: '+44 1098765432',
            email: 'sophia.davis@example.com',
            status: 'Active',
            section: 'Class B'
        },
        {
            id: '007',
            name: 'Jackson Martinez',
            age: 10,
            grade: 'Grade 4',
            schoolName: 'Riverside Elementary School',
            class: 'Class A',
            phone: '+44 4567890123',
            email: 'jackson.martinez@example.com',
            status: 'Active',
            section: 'Class A'
        },
        {
            id: '008',
            name: 'Isabella White',
            age: 8,
            grade: 'Grade 3',
            schoolName: 'Brookside Elementary School',
            class: 'Class B',
            phone: '+44 2345678901',
            email: 'isabella.white@example.com',
            status: 'Active',
            section: 'Class B'
        }
    ],
    TeachersDummyData: [
        {
            id: "#001",
            name: "Miracle Dias",
            position: "Teacher",
            school: "Robb International School",
            phoneNumber: "+44 8844585555",
            email: "miracledias@robb.com",
            status: "Active"
        },
        {
            id: "#002",
            name: "Dulce Westervelt",
            position: "Sr. Teacher",
            school: "Robb International School",
            phoneNumber: "+44 7844585555",
            email: "dulcewestervelt@robb.com",
            status: "Inactive"
        },
        {
            id: "#003",
            name: "John Doe",
            position: "Teacher",
            school: "Robb International School",
            phoneNumber: "+44 1234567890",
            email: "johndoe@robb.com",
            status: "Active"
        },
        {
            id: "#004",
            name: "Jane Smith",
            position: "Sr. Teacher",
            school: "Robb International School",
            phoneNumber: "+44 9876543210",
            email: "janesmith@robb.com",
            status: "Inactive"
        },
        {
            id: "#005",
            name: "Michael Johnson",
            position: "Teacher",
            school: "Robb International School",
            phoneNumber: "+44 5555555555",
            email: "michaeljohnson@robb.com",
            status: "Active"
        },
        {
            id: "#006",
            name: "Emily Davis",
            position: "Sr. Teacher",
            school: "Robb International School",
            phoneNumber: "+44 6666666666",
            email: "emilydavis@robb.com",
            status: "Inactive"
        },
        {
            id: "#007",
            name: "Robert Brown",
            position: "Teacher",
            school: "Robb International School",
            phoneNumber: "+44 7777777777",
            email: "robertbrown@robb.com",
            status: "Active"
        },
        {
            id: "#008",
            name: "Sarah Wilson",
            position: "Sr. Teacher",
            school: "Robb International School",
            phoneNumber: "+44 8888888888",
            email: "sarahwilson@robb.com",
            status: "Inactive"
        }
    ],
    CourseTableData: [
        {
            id: "#001",
            gameName: "Picture puzzles",
            totalDimonds: 100,
            no_levels: 4,
            status: "Active"
        },
        {
            id: "#002",
            gameName: "Word Search",
            totalDimonds: 80,
            no_levels: 3,
            status: "Active"
        },
        {
            id: "#003",
            gameName: "Crossword Puzzle",
            totalDimonds: 120,
            no_levels: 5,
            status: "Inactive"
        },
        {
            id: "#004",
            gameName: "Sudoku",
            totalDimonds: 90,
            no_levels: 6,
            status: "Active"
        },
        {
            id: "#005",
            gameName: "Maze Runner",
            totalDimonds: 150,
            no_levels: 8,
            status: "Active"
        },
        {
            id: "#006",
            gameName: "Math Challenge",
            totalDimonds: 110,
            no_levels: 7,
            status: "Inactive"
        }
    ],
    MetaverseTableData: [
        {
            id: "#001",
            name: "Terry Baptista",
            designation: "Admin",
            accessLevel: 1,
            status: "Active"
        },
        {
            id: "#002",
            name: "John Doe",
            designation: "Customer care",
            accessLevel: 2,
            status: "Active"
        },
        {
            id: "#003",
            name: "Alice Smith",
            designation: "Marking",
            accessLevel: 3,
            status: "Active"
        },
        {
            id: "#004",
            name: "Bob Johnson",
            designation: "Data Entry",
            accessLevel: 2,
            status: "Active"
        },
        {
            id: "#005",
            name: "Sarah Brown",
            designation: "Super Admin",
            accessLevel: 3,
            status: "Active"
        },
        {
            id: "#006",
            name: "David Wilson",
            designation: "Finance Manager",
            accessLevel: 1,
            status: "Active"
        },
    ],
    schoolTableData: [
        {
            id: "#001",
            name: "Terry Baptista",
            designation: "Teacher",
            accessLevel: 1,
            status: "Active"
        },
        {
            id: "#002",
            name: "John Doe",
            designation: "School Admin",
            accessLevel: 2,
            status: "Active"
        },
        {
            id: "#003",
            name: "Alice Smith",
            designation: "Supervisor",
            accessLevel: 3,
            status: "Active"
        },
        {
            id: "#004",
            name: "Bob Johnson",
            designation: "Data Entry",
            accessLevel: 2,
            status: "Active"
        },
        {
            id: "#005",
            name: "Sarah Brown",
            designation: "Sr. Teacher",
            accessLevel: 3,
            status: "Active"
        },
    ],
    otherTableData: [
        {
            id: "#001",
            name: "Terry Baptista",
            designation: "Directors",
            accessLevel: 1,
            status: "Active"
        },
        {
            id: "#002",
            name: "John Doe",
            designation: "Sr. Engineer",
            accessLevel: 2,
            status: "Active"
        },
        {
            id: "#003",
            name: "Alice Smith",
            designation: "Sr. Engineer",
            accessLevel: 1,
            status: "Active"
        },
        {
            id: "#004",
            name: "Bob Johnson",
            designation: "Financial Expert",
            accessLevel: 2,
            status: "Active"
        },
    ],
    paymentTableData: [
        {
            id: '#001',
            Collection_Type: 'Subscription',
            Collection_by: 'School | Robbs International School',
            Date: '27 Feb 2023',
            Subscription_Level: 'Level 2',
            Amount: '£100',
            Status: 'Paid'
        },
        {
            id: '#002',
            Collection_Type: 'Subscription',
            Collection_by: 'Parent | Maria Aminoff',
            Date: '20 Feb 2023',
            Subscription_Level: 'Level 1',
            Amount: '£50',
            Status: 'Paid'
        },
        {
            id: '#003',
            Collection_Type: 'Subscription',
            Collection_by: 'School | Thomas Jefferson School',
            Date: '11 Feb 2023',
            Subscription_Level: 'Level 3',
            Amount: '£250',
            Status: 'Paid'
        },
        {
            id: '#004',
            Collection_Type: 'Subscription',
            Collection_by: 'Parent | Jenny Nculhane',
            Date: '04 Feb 2023',
            Subscription_Level: 'Level 3',
            Amount: '£250',
            Status: 'In progress'
        },
        {
            id: '#005',
            Collection_Type: 'Other',
            Collection_by: 'Advertisement',
            Date: '31 Jan 2023',
            Subscription_Level: '-',
            Amount: '£500',
            Status: 'In progress'
        },
        {
            id: '#006',
            Collection_Type: 'Other',
            Collection_by: 'Advertisement',
            Date: '23 Jan 2023',
            Subscription_Level: '-',
            Amount: '£500',
            Status: 'Unpaid'
        },
        {
            id: '#007',
            Collection_Type: 'Subscription',
            Collection_by: 'School | Signature School',
            Date: '15 Jan 2023',
            Subscription_Level: 'Level 1',
            Amount: '£50',
            Status: 'Paid'
        }
    ]
}