import React, { useEffect } from "react";
import classes from "./teacher.module.css";
import StatisticsCard from "../../../Components/statisticsCard";
import { TeachersStatisticData } from "../../../Utilities/StatisticConstants";
import {
  Typography,
  Card,
  TextField,
  InputAdornment,
  Button,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuList,
  Select,
} from "@mui/material";
import { useState } from "react";
import {
  RoundAddIcon,
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import SchoolDropDown from "../../../Components/schoolDropDown";
import DefaultPagination from "../../../Components/Pagination";
import TeacherTableListItem from "./teacherTableListItem";
import Checkbox from "@mui/material/Checkbox";
import {
  setHeaderName,
  setPageValue,
} from "../../../Redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllDesignations,
  getAllTeachers,
  getTeacherPiechart,
} from "../../../Redux/actions/teacherActions";
import AlertBox from "../../../Components/AlertBox";
import NodataFound from "../../../Components/nodataFound";
import { handleSortClick } from "../../../Utilities/commonFunctions";

const TeachersIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [designation, setDesignation] = useState("select");
  const [school, setSchool] = useState("select");
  const [checked, setChecked] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const { error, designations, teachersList, piechartData, totalCount } =
    useSelector((state) => state.teacherDatas);
  const { sortBy, page } = useSelector((state) => state.userLogin);

  useEffect(() => {
    dispatch(setPageValue(1));
  }, []);

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
    renderTabledata();
  };

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  useEffect(() => {
    dispatch(setPageValue(1));
  }, [search]);

  useEffect(() => {
    dispatch(
      getAllTeachers(setErrorAlert, search, school, designation, sortBy, page)
    );
    dispatch(getAllDesignations(setErrorAlert));
    dispatch(getTeacherPiechart(setErrorAlert, piechartData));
  }, [dispatch, school, designation, sortBy, page, search]);

  const handleChange = (e) => {
    console.log(e.target.value);
    setSchool(e.target.value);
  };

  const handleChangeDesignation = (e) => {
    console.log(e.target.value);
    setDesignation(e.target.value);
  };

  const handleAddTeacherClick = () => {
    console.log("clicked");
    dispatch(setHeaderName("add-teacher"));
    navigate("/add-teacher");
  };

  const handleSearchClick = () => {
    dispatch(
      getAllTeachers(setErrorAlert, search, school, designation, sortBy, page)
    );
    dispatch(getAllDesignations(setErrorAlert));
  };

  const renderTabledata = () => {
    if (teachersList && teachersList.length > 0) {
      return teachersList.map((item, index) => {
        return (
          <TeacherTableListItem item={item} key={index} status={checked} />
        );
      });
    }
  };

  return (
    <div>
      <StatisticsCard data={piechartData} />
      <Card className="mt-3">
        <div style={{ padding: "20px" }}>
          <Typography className={classes.MainHeading1}>
            All Teacher Data
          </Typography>
          <div className={`${classes.FilterMainContainer} mt-3`}>
            <div className={`${classes.FilterSubContainer}`}>
              <div>
                <TextField
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                  }}
                  fullWidth
                  placeholder="Search by Teacher Name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  className={classes.searchBar}
                  id="firstNameinput"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  size="small"
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  className={classes.searchButton}
                  style={{
                    width: "110px",
                    height: "40px",
                  }}
                  onClick={() => {
                    handleSearchClick();
                  }}
                >
                  <span className={classes.nrmlBtnText}>Search</span>
                </Button>
              </div>
              <div>
                <SchoolDropDown
                  value={school}
                  handleChange={handleChange}
                  width={"200px"}
                />
              </div>
              <div>
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                    height: "40px",
                    width: "170px",
                  }}
                  select
                  id="teachernameInput"
                  fullWidth
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Set your desired max height here
                      },
                    },
                  }}
                  className={
                    designation === "select"
                      ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                      : `${classes.DropdownTextInput}`
                  }
                  value={designation}
                  onChange={handleChangeDesignation}
                  size="small"
                >
                  <MenuItem value="select" style={{ display: "none" }}>
                    Select Designation
                  </MenuItem>
                  {designations &&
                    designations.length > 0 &&
                    designations.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={item._id}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.name?.charAt(0).toUpperCase() +
                            item.name
                              ?.slice(1)
                              .toLowerCase()
                              .replaceAll("_", ".")}
                        </MenuItem>
                      );
                    })}
                </Select>
                {/* <TextField
                  select
                  value={designation}
                  onChange={handleChangeDesignation}
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                  }}
                  size="small"
                  className={classes.classFilterDropdown}
                >
                  {["teacher", "principal", "sr.Teacher", "jr.Teacher"].map(
                    (item, index) => (
                      <MenuItem
                        key={item}
                        value={item}
                        className={classes.MenuItems}
                      >
                        {item}
                      </MenuItem>
                    )
                  )}
                </TextField> */}
              </div>
            </div>
            <div className={`${classes.FilterSubContainer}`}>
              <div
                className={`${classes.DividerClasses} ${classes.vertical}`}
              ></div>
              <Button
                variant="contained"
                className={`d-flex align-items-center ${classes.searchButton}`}
                onClick={() => handleAddTeacherClick()}
              >
                <span style={{ marginRight: "10px" }}>
                  <RoundAddIcon />
                </span>
                <span className={classes.mainBtnText}>Add New Teacher</span>
              </Button>
            </div>
          </div>
        </div>
        {totalCount && totalCount > 0 ? (
          <div>
            <div className={classes.TableContainer}>
              <Table className={classes.TableClass}>
                <TableHead>
                  <TableRow className={classes.TableHeaderRow}>
                    <TableCell flex={1} className={classes.firstCell}>
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckBoxChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: "135px" }}>
                      <span className={classes.TableHeaderText}>
                        Teacher Name
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "name")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        !sortBy?.includes("designationInfo") &&
                        sortBy?.includes("name") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: "120px" }}>
                      <span className={classes.TableHeaderText}>
                        Designation
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(
                            dispatch,
                            sortBy,
                            "designationInfo.name"
                          )
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("designationInfo.name") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: "270px" }}>
                      <span className={classes.TableHeaderText}>
                        School Name
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "schoolInfo.name")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("schoolInfo.name") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: "190px" }}>
                      <span className={classes.TableHeaderText}>
                        Teacher Contact Number
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "userInfo.phone")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("userInfo.phone") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: "145px" }}>
                      <span className={classes.TableHeaderText}>
                        Teacher Email ID
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "userInfo.email")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("userInfo.email") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1}>
                      <span className={classes.TableHeaderText}>Status</span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "isActive")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("isActive") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{renderTabledata()}</TableBody>
              </Table>
            </div>
            <DefaultPagination count={totalCount} />
          </div>
        ) : (
          <div>
            <NodataFound text={"Please add teachers to view"} />
          </div>
        )}
      </Card>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default TeachersIndex;
