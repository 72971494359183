import { GET_COUPONSES_FAIL, GET_COUPONSES_SUCCESS } from "../contants/couponsConstants";
  
  const initialState = {
    allCoupons: [],
    allCouponsErr: null,
  };
  
  export const couponReducers = (state = initialState, action) => {
    switch (action.type) {
      case GET_COUPONSES_SUCCESS:
        return { ...state, allCoupons: action.payload };
      case GET_COUPONSES_FAIL:
        return { ...state, allCouponsErr: action.payload };
      default:
        return state;
    }
  };
  