
import { InactiveSchoolsIcon, SchoolCourseIcon, ActiveSchoolsIcon } from "../../Assets/Icons/icons";
import { ADD_COURSE_FAIL, ADD_COURSE_SUCCESS, GET_ONE_COURSE_FAIL, GET_ONE_COURSE_SUCCESS, GET_COURSES_FAIL, GET_COURSES_SUCCESS, COURSE_PIECHART_FAIL, COURSE_PIECHART_SUCCESS, GET_GAMES_LIST_SUCCESS, GET_GAMES_LIST_FAIL, GET_COURSE_PIECHART_SUCCESS, GET_COURSE_PIECHART_FAIL } from "../contants/courseConstants";

const initialState = {
    error: null,
    success: null,
    coursesList: [],
    gamesList: [],
    oneCourse: null,
    totalCount: 0,
    piechartData: [
        {
            icon: <SchoolCourseIcon />,
            title: "Total Games",
            number: "0",
            key: "totalGamess",
        },
        {
            icon: <ActiveSchoolsIcon />,
            title: "Active Games",
            number: "0",
            key: "activeGamess",
        },
        {
            icon: <InactiveSchoolsIcon />,
            title: "Inactive Games",
            number: "0",
            key: "inactiveGamess",
        },
    ]
};

export const courseReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_COURSE_FAIL:
            return { ...state, error: action.payload };
        case ADD_COURSE_SUCCESS:
            return { ...state, error: null, success: action.payload };
        case GET_COURSES_SUCCESS:
            return { ...state, error: null, coursesList: action.payload.courses, totalCount: action.payload.totalCount };
        case GET_COURSES_FAIL:
            return { ...state, error: action.payload };
        case GET_ONE_COURSE_SUCCESS:
            return { ...state, error: null, oneCourse: action.payload };
        case GET_ONE_COURSE_FAIL:
            return { ...state, error: action.payload };
        case COURSE_PIECHART_FAIL:
            return { ...state, error: action.payload };
        case COURSE_PIECHART_SUCCESS:
            return { ...state, error: null, piechartData: action.payload };
        case GET_GAMES_LIST_FAIL:
            return { ...state, error: action.payload };
        case GET_GAMES_LIST_SUCCESS:
            return { ...state, error: null, gamesList: action.payload };
        case GET_COURSE_PIECHART_SUCCESS:
            return { ...state, error: null, piechartData: action.payload };
        case GET_COURSE_PIECHART_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};