import React, { useRef, useState } from "react";
import { Modal, Button, Typography, Link, Box, Divider } from "@mui/material";
import classes from "./student.module.css";
import { CloseIcon } from "../../../Assets/Icons/icons";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { generatePassword } from "../../../Utilities/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader, resetPassword } from "../../../Redux/actions/userActions";
import AlertBox from "../../../Components/AlertBox";

const ResetPasswordModal = ({
  modalClose,
  width,
  height,
  email,
  setSuccessAlert,
}) => {
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const [accountEmail, setAccountEmail] = useState(email);
  const [password, setPassword] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [modalToggle, setModalToggle] = useState(true);
  const [defaultErrorMessage, setDefaultErrorMessage] = useState("");
  const { error } = useSelector((state) => state.userLogin);

  function closeModal(data) {
    setModalToggle(false);
    modalClose(false);
    if (data) {
      setSuccessAlert(true);
    }
  }

  const CloseAlert = () => {
    setErrorAlert(false);
    setDefaultErrorMessage("");
  };

  const handleSubmit = () => {
    if (password && password !== "") {
      const userEntry = {
        username: accountEmail,
        password: password,
      };
      console.log(userEntry);
      dispatch(pageLoader(true));
      dispatch(resetPassword(userEntry, setSuccessAlert, setErrorAlert));
      closeModal(true);
    } else {
      setErrorAlert(true);
      console.log("Please enter or generate password", password);
      setDefaultErrorMessage("Please enter or generate password");
      return;
    }
  };

  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => closeModal(false)}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          top: "10%",
          height: "fit-content",
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{ minWidth: width, height: height }}
          className={"loginModalWidth"}
        >
          <div className={classes.forgetPasswordmodalclose}>
            <Button
              variant="text"
              sx={{ minWidth: "unset" }}
              className={classes.closeButton}
              onClick={() => closeModal(false)}
            >
              <CloseIcon />
            </Button>
          </div>
          <div className={classes.ModelContentContainer}>
            <ValidatorForm
              onSubmit={handleSubmit}
              useref="form"
              className={classes.AddStudentForm}
            >
              <Typography className={classes.StudentPageMainHeading}>
                Reset Password
              </Typography>
              <div className="mt-3">
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText} mb-2`}
                >
                  Account Email ID
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="accountEmailInput"
                  fullWidth
                  placeholder="Enter account email ID"
                  disabled
                  className={classes.TextInput}
                  value={accountEmail}
                  onChange={(e) => {
                    setAccountEmail(e.target.value);
                  }}
                  size="small"
                  validators={["required", "isEmail"]} // Add this line
                  errorMessages={[
                    "Account Email is required",
                    "Enter a valid email ID",
                  ]}
                />
              </div>
              <Typography
                className={`${classes.genereratePasswordtext} mt-1 mb-3`}
                onClick={() => {
                  generatePassword(email, setPassword);
                }}
              >
                Generate new Password
              </Typography>
              <Divider>
                <span className={classes.textLable}>Or</span>
              </Divider>
              <div className="mt-3">
                <Typography
                  className={`${classes.TableBodyText} ${classes.HeadingText} mb-2`}
                >
                  Create new password
                </Typography>
                <TextValidator
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                  }}
                  id="passworxInput"
                  fullWidth
                  placeholder="Enter new password"
                  className={classes.TextInput}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  size="small"
                  validators={["required"]}
                  errorMessages={["Password is required"]}
                />
              </div>
              <div className={`mt-4 ${classes.ModelBtnSection}`}>
                <Button
                  variant="outlined"
                  className={classes.cancelbutton}
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={classes.savebutton}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </ValidatorForm>
          </div>
        </Box>
      </Modal>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {defaultErrorMessage && errorAlert && (
        <AlertBox
          type="error"
          message={defaultErrorMessage}
          stateName={CloseAlert}
        />
      )}
    </div>
  );
};

export default ResetPasswordModal;
