import {
    ADD_CLASS_FAIL,
    ADD_CLASS_SUCCESS,
    GET_CLASSES_FAIL,
    GET_CLASSES_SUCCESS,
    GET_ONE_CLASS_FAIL,
    GET_ONE_CLASS_SUCCESS,
} from "../contants/classConstants";

const initialState = {
    error: null,
    success: null,
    classesList: [],
    oneClass: null,
    totalCount: 0,
};

export const classReducers = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CLASS_FAIL:
            return { ...state, error: action.payload };
        case ADD_CLASS_SUCCESS:
            return { ...state, error: null, success: action.payload };
        case GET_CLASSES_SUCCESS:
            return { ...state, error: null, classesList: action.payload.classes, totalCount: action.payload.totalCount };
        case GET_CLASSES_FAIL:
            return { ...state, error: action.payload };
        case GET_ONE_CLASS_SUCCESS:
            return { ...state, error: null, oneClass: action.payload };
        case GET_ONE_CLASS_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};