import React, { useEffect } from "react";
import classes from "./roleManagement.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BrudCrumsNextIcon,
  RoundAddPurpleIcon,
  SortIcon,
} from "../../../Assets/Icons/icons";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import { Col, Row } from "react-bootstrap";
import {
  Typography,
  TextField,
  MenuItem,
  Button,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";
import { useState } from "react";
import Footer from "../../../Components/Footer";
import InputModal from "../../../Components/InputModal";
import {
  addNewDesignation,
  getAllDesignations,
} from "../../../Redux/actions/teacherActions";
import AlertBox from "../../../Components/AlertBox";
import {
  addNewAccessLevel,
  addNewRole,
  getAllAccessLevel,
  getOneRoles,
  updateRole,
} from "../../../Redux/actions/roleManagementAction";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { getOneSchools } from "../../../Redux/actions/schoolActions";

const AddNewRole = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accessLevel, setAccessLevel] = useState("select");
  const [designation, setDesignation] = useState("select");
  const [type, setType] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [errorAlert, setErrorAlert] = useState(false);
  const [topic, setTopic] = useState("");
  const [customDesignationSuccessAlert, setDesignationCustomSuccessAlert] =
    useState(false);
  const [customAccessSuccessAlert, setAccessCustomSuccessAlert] =
    useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [school, setSchool] = useState("");
  const [modalToggle, setModalToggle] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [isSchool, setIsSchool] = useState("");
  const [defaultError, setDefaultError] = useState("");
  const [defaultErrorAlert, setDefaultErrorAlert] = useState(false);
  const {
    success: designationAddSuccess,
    error: designationAddError,
    designations,
  } = useSelector((state) => state.teacherDatas);
  const { oneSchool } = useSelector((state) => state.schoolDatas);
  const {
    success: accesAddSuccess,
    error: accesAddError,
    success,
    error,
    oneRole,
    accessLevelList,
  } = useSelector((state) => state.roleDatas);

  useEffect(() => {
    let _page = window.location.pathname;
    console.log("_page =", _page);
    if (_page.includes("add-new-role/schools")) {
      setIsSchool("school");
    } else {
      setIsSchool("test");
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("edit")) {
      const path = window.location.pathname.split("/");
      dispatch(getOneRoles(path[path.length - 1], setErrorAlert));
    } else {
      const path = window.location.pathname.split("/");
      const topic = path[path.length - 1].split("-");
      console.log(topic[0]);
      setTopic(topic[0]);
      if (topic[0] === "schools") {
        dispatch(getOneSchools(topic[1], setErrorAlert));
        setSchool(topic[1]);
      }
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("edit")) {
      setRoleName(oneRole?.name);
      setPermissions(oneRole?.permissions);
      setSchool(oneRole?.schoolInfo?._id);
      setDesignation(oneRole?.designationInfo?._id);
      setAccessLevel(oneRole?.accessInfo?._id);
      setTopic(oneRole?.management);
    }
  }, [oneRole, dispatch]);

  const handleChange = (module, action) => {
    const rows = [
      "all",
      // "school",
      "student",
      "teacher",
      "course&games",
      // "chat",
      // "user_management",
    ];

    const columns = [
      "all",
      "add",
      "edit",
      "delete",
      "download",
      "upload",
      "message",
      "report",
    ];

    if (module === "all") {
      if (action === "all") {
        if (permissions?.includes("all:all")) {
          setPermissions([]);
        } else {
          const array = rows.map((row) =>
            columns.map((column) => `${row}:${column}`)
          );
          const flatArray = [].concat.apply([], array);
          flatArray.push("all:all");
          setPermissions(flatArray);
        }
      } else {
        if (permissions?.includes(`all:${action}`)) {
          const filteredPermissions = permissions.filter(
            (perm) =>
              !perm.endsWith(`:${action}`) &&
              perm !== `all:all` &&
              !perm.endsWith(`:all`)
          );
          setPermissions(filteredPermissions);
        } else {
          const addNewPermissions = rows.map((row) => `${row}:${action}`);
          setPermissions([...permissions, ...addNewPermissions]);
        }
      }
    } else {
      // Handle other modules
      if (action === "all") {
        if (permissions?.includes(`${module}:all`)) {
          const filteredPermissions = permissions.filter(
            (perm) =>
              !perm.startsWith(`${module}:`) &&
              perm !== `all:all` &&
              !perm.startsWith(`all:`)
          );
          setPermissions(filteredPermissions);
        } else {
          const addNewPermissions = columns.map(
            (column) => `${module}:${column}`
          );
          setPermissions([...permissions, ...addNewPermissions]);
        }
      } else {
        if (permissions?.includes(`${module}:${action}`)) {
          const filteredPermissions = permissions.filter(
            (perm) =>
              perm !== `${module}:${action}` &&
              perm !== `${module}:all` &&
              perm !== `all:${action}` &&
              perm !== `all:all`
          );
          setPermissions(filteredPermissions);
        } else {
          const addNewPermissions = [...permissions, `${module}:${action}`];
          setPermissions(addNewPermissions);
        }
      }
    }
  };

  const handleNavigateuserManagement = () => {
    dispatch(setHeaderName("Role Management"));
    navigate("/role-management");
  };

  const CloseAlert = () => {
    setSuccessAlert(false);
    setAccessCustomSuccessAlert(false);
    setDesignationCustomSuccessAlert(false);
    setErrorAlert(false);
    setDefaultErrorAlert(false);
    setDefaultError("");
  };

  const handleDesignation = (event) => {
    setModalToggle(true);
    setType("addDesignation");
  };

  const handleAccessLevel = (event) => {
    setModalToggle(true);
    setType("addAccessLevel");
  };

  const ModalClose = () => {
    setModalToggle(false);
  };

  const AddDesignationInServer = (userEntry) => {
    console.log("UserEntry", userEntry);
    dispatch(pageLoader(true));
    dispatch(
      addNewDesignation(
        userEntry,
        setErrorAlert,
        setDesignationCustomSuccessAlert
      )
    );
  };

  useEffect(() => {
    dispatch(getAllDesignations(setErrorAlert));
    dispatch(getAllAccessLevel(setErrorAlert));
  }, [dispatch]);

  const handleSubmit = () => {
    if (permissions.length > 0) {
      const userEntry = {
        name: roleName,
        designation: designation,
        access_level: accessLevel,
        management: topic,
        permissions: permissions,
      };
      console.log("UserEntry", userEntry);
      if (topic === "schools") {
        userEntry.school_id = oneSchool._id;
      }
      dispatch(pageLoader(true));
      if (window.location.pathname.includes("edit")) {
        dispatch(
          updateRole(oneRole?._id, userEntry, setErrorAlert, setSuccessAlert)
        );
      } else {
        dispatch(addNewRole(userEntry, setErrorAlert, setSuccessAlert));
      }
    } else {
      setDefaultErrorAlert(true);
      setDefaultError("Please Select Any Permission");
    }
  };

  const AddLevelInServer = (userEntry) => {
    console.log("UserEntry", userEntry);
    dispatch(pageLoader(true));
    dispatch(
      addNewAccessLevel(userEntry, setErrorAlert, setAccessCustomSuccessAlert)
    );
  };

  return (
    <div>
      {window.location.pathname.includes("edit") ? (
        <div className={classes.brudcrumbs}>
          <span
            className={classes.TableHeaderText}
            onClick={() => handleNavigateuserManagement()}
          >
            Role Management
          </span>
          <span>
            <BrudCrumsNextIcon />
          </span>
          <span
            className={classes.TableBodyText}
            style={{ textTransform: "capitalize" }}
          >
            {oneRole?.designationInfo?.name?.replaceAll("_", " ").toLowerCase()}
          </span>
        </div>
      ) : (
        <div className={classes.brudcrumbs}>
          <span
            className={classes.TableHeaderText}
            onClick={() => handleNavigateuserManagement()}
          >
            Role Management
          </span>
          <span>
            <BrudCrumsNextIcon />
          </span>
          {/* <span
            className={classes.TableBodyText}
            style={{ textTransform: "capitalize" }}
          >
            {topic}
          </span>
          {topic === "schools" && (
            <span>
              <BrudCrumsNextIcon />
            </span>
          )}
          {topic === "schools" && oneSchool && (
            <span className={classes.TableBodyText}>{oneSchool?.name}</span>
          )}
          <span>
            <BrudCrumsNextIcon />
          </span> */}
          <span className={classes.TableBodyText}>{`Add New Role`}</span>
        </div>
      )}
      <ValidatorForm
        useref="form"
        onSubmit={handleSubmit}
        className={classes.formInputContainer}
      >
        <Row className={`mt-3`}>
          <Col md={4}>
            <Typography className={classes.formLable}>Designation</Typography>
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                marginBottom: "15px",
              }}
              select
              id="designationInput"
              fullWidth
              placeholder="Select Designation"
              className={
                designation === "select"
                  ? `${classes.TextInput} ${classes.defaultMenuItem}`
                  : classes.TextInput
              }
              value={designation}
              onChange={(e) => {
                setDesignation(e.target.value);
              }}
              size="small"
              validators={["required"]} // Add this line
              errorMessages={["Designation is required"]}
            >
              <MenuItem
                key={"select"}
                value={"select"}
                style={{ display: "none" }}
              >
                Select Class
              </MenuItem>
              {designations &&
                designations.length > 0 &&
                designations.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item._id}
                      style={{ textTransform: "capitalize" }}
                    >
                      {item.name?.toLowerCase()?.replaceAll("_", " ")}
                    </MenuItem>
                  );
                })}
            </TextField>
            <Button
              variant="text"
              className={classes.addText}
              onClick={() => handleDesignation()}
            >
              <RoundAddPurpleIcon />
              <span>Add Designation</span>
            </Button>
          </Col>
          <Col md={4}>
            <Typography className={classes.formLable}>Access Level</Typography>
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                marginBottom: "15px",
              }}
              select
              id="accessLevelInput"
              fullWidth
              placeholder="Select Access Level"
              className={
                accessLevel === "select"
                  ? `${classes.TextInput} ${classes.defaultMenuItem}`
                  : classes.TextInput
              }
              value={accessLevel}
              onChange={(e) => {
                setAccessLevel(e.target.value);
              }}
              size="small"
              validators={["required"]} // Add this line
              errorMessages={["Access Level is required"]}
            >
              <MenuItem key={"select"} value={"select"}>
                Select Access Level
              </MenuItem>
              {accessLevelList &&
                accessLevelList.length > 0 &&
                accessLevelList.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item._id}
                      style={{ textTransform: "capitalize" }}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })}
            </TextField>
            <Button
              variant="text"
              className={classes.addText}
              onClick={() => handleAccessLevel()}
            >
              <RoundAddPurpleIcon />
              <span>Add Access Level</span>
            </Button>
          </Col>
          {topic !== "schools" && (
            <Col md={4}>
              <Typography className={classes.formLable}>Name</Typography>
              <TextValidator
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  marginBottom: "15px",
                }}
                id="nameInput"
                fullWidth
                placeholder="Enter Name"
                className={classes.TextInput}
                value={roleName}
                onChange={(e) => {
                  setRoleName(e.target.value);
                }}
                size="small"
                validators={["required"]} // Add this line
                errorMessages={["Name is required"]}
              />
            </Col>
          )}
        </Row>
        <Card className={`mt-4`} style={{ marginBottom: "70px" }}>
          <div
            style={{
              padding: "20px",
            }}
          >
            <Typography className={classes.TableContainerHeader}>
              Access Level Information
            </Typography>
          </div>
          <div className={classes.TableContainer}>
            <Table className={classes.tableAddRole}>
              <TableHead>
                <TableRow className={classes.TableHeaderRowCheck}>
                  <TableCell flex={1}>
                    <span
                      className={classes.TableHeaderText}
                      style={{ fontWeight: 500 }}
                    >
                      #Modules
                    </span>
                    <span>
                      <SortIcon />
                    </span>
                  </TableCell>
                  <TableCell flex={1}>
                    <div className={classes.CheckboxHeader}>
                      <span
                        className={classes.TableHeaderText}
                        style={{ fontWeight: 500 }}
                      >
                        All
                      </span>
                      <span>
                        <Checkbox
                          checked={permissions?.includes("all:all")}
                          onChange={() => handleChange("all", "all")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell flex={1}>
                    <div className={classes.CheckboxHeader}>
                      <span
                        className={classes.TableHeaderText}
                        style={{ fontWeight: 500 }}
                      >
                        Add
                      </span>
                      <span>
                        <Checkbox
                          checked={permissions?.includes("all:add")}
                          onChange={() => handleChange("all", "add")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell flex={1}>
                    <div className={classes.CheckboxHeader}>
                      <span
                        className={classes.TableHeaderText}
                        style={{ fontWeight: 500 }}
                      >
                        Edit
                      </span>
                      <span>
                        <Checkbox
                          checked={permissions?.includes("all:edit")}
                          onChange={() => handleChange("all", "edit")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell flex={1}>
                    <div className={classes.CheckboxHeader}>
                      <span
                        className={classes.TableHeaderText}
                        style={{ fontWeight: 500 }}
                      >
                        Delete
                      </span>
                      <span>
                        <Checkbox
                          checked={permissions?.includes("all:delete")}
                          onChange={() => handleChange("all", "delete")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell flex={1}>
                    <div className={classes.CheckboxHeader}>
                      <span
                        className={classes.TableHeaderText}
                        style={{ fontWeight: 500 }}
                      >
                        Download
                      </span>
                      <span>
                        <Checkbox
                          checked={permissions?.includes("all:download")}
                          onChange={() => handleChange("all", "download")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell flex={1}>
                    <div className={classes.CheckboxHeader}>
                      <span
                        className={classes.TableHeaderText}
                        style={{ fontWeight: 500 }}
                      >
                        Upload
                      </span>
                      <span>
                        <Checkbox
                          checked={permissions?.includes("all:upload")}
                          onChange={() => handleChange("all", "upload")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell flex={1}>
                    <div className={classes.CheckboxHeader}>
                      <span
                        className={classes.TableHeaderText}
                        style={{ fontWeight: 500 }}
                      >
                        Message
                      </span>
                      <span>
                        <Checkbox
                          checked={permissions?.includes("all:message")}
                          onChange={() => handleChange("all", "message")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell flex={1}>
                    <div className={classes.CheckboxHeader}>
                      <span
                        className={classes.TableHeaderText}
                        style={{ fontWeight: 500 }}
                      >
                        Report
                      </span>
                      <span>
                        <Checkbox
                          checked={permissions?.includes("all:report")}
                          onChange={() => handleChange("all", "report")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[
                  // "school",
                  "student",
                  "teacher",
                  "course&games",
                  // "chat",
                  // "user_management",
                ]
                  .filter((el) => el != isSchool)
                  .map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell style={{ textTransform: "capitalize" }}>
                          {item.replace("_", " ")}
                        </TableCell>
                        <TableCell>
                          <div className={classes.CheckboxHeader}>
                            <span>
                              <Checkbox
                                checked={permissions?.includes(`${item}:all`)}
                                onChange={() => handleChange(item, "all")}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.CheckboxHeader}>
                            <span>
                              <Checkbox
                                checked={permissions?.includes(`${item}:add`)}
                                onChange={() => handleChange(item, "add")}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.CheckboxHeader}>
                            <span>
                              <Checkbox
                                checked={permissions?.includes(`${item}:edit`)}
                                onChange={() => handleChange(item, "edit")}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.CheckboxHeader}>
                            <span>
                              <Checkbox
                                checked={permissions?.includes(
                                  `${item}:delete`
                                )}
                                onChange={() => handleChange(item, "delete")}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.CheckboxHeader}>
                            <span>
                              <Checkbox
                                checked={permissions?.includes(
                                  `${item}:download`
                                )}
                                onChange={() => handleChange(item, "download")}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.CheckboxHeader}>
                            <span>
                              <Checkbox
                                checked={permissions?.includes(
                                  `${item}:upload`
                                )}
                                onChange={() => handleChange(item, "upload")}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.CheckboxHeader}>
                            <span>
                              <Checkbox
                                checked={permissions?.includes(
                                  `${item}:message`
                                )}
                                onChange={() => handleChange(item, "message")}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.CheckboxHeader}>
                            <span>
                              <Checkbox
                                checked={permissions?.includes(
                                  `${item}:report`
                                )}
                                onChange={() => handleChange(item, "report")}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </Card>
        <Footer
          callback={handleNavigateuserManagement}
          edit={window.location.pathname.includes("edit")}
        />
      </ValidatorForm>
      {modalToggle && (
        <InputModal
          modalClose={ModalClose}
          type={type}
          callback={
            type === "addDesignation"
              ? AddDesignationInServer
              : type === "addAccessLevel"
              ? AddLevelInServer
              : null
          }
        />
      )}
      {(errorAlert && designationAddError) ||
      (errorAlert && accesAddError) ||
      (errorAlert && error) ? (
        <AlertBox
          type="error"
          message={designationAddError || accesAddError || error}
          stateName={CloseAlert}
        />
      ) : null}
      {defaultError && defaultErrorAlert && (
        <AlertBox type="error" message={defaultError} stateName={CloseAlert} />
      )}
      {customDesignationSuccessAlert && designationAddSuccess ? (
        <AlertBox
          type="success"
          message={designationAddSuccess || accesAddSuccess}
          stateName={CloseAlert}
        />
      ) : customAccessSuccessAlert && accesAddSuccess ? (
        <AlertBox
          type="success"
          message={accesAddSuccess}
          stateName={CloseAlert}
        />
      ) : null}
      {successAlert && success && (
        <AlertBox
          type="success"
          message={success}
          stateName={CloseAlert}
          callback={handleNavigateuserManagement}
        />
      )}
    </div>
  );
};

export default AddNewRole;
