import React, { useEffect, useState } from "react";
import {
  Card,
  TextField,
  Typography,
  InputAdornment,
  Button,
  MenuItem,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import noDataImage from "../../../Assets/Images/noDataImage.png";
import { Table } from "react-bootstrap";
import classes from "./school.module.css";
import {
  RoundAddIcon,
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import SchoolTableListItem from "./schoolTableListItem";
import { useNavigate } from "react-router-dom";
import DefaultPagination from "../../../Components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  pageLoader,
  setHeaderName,
  setPageValue,
} from "../../../Redux/actions/userActions";
import StatisticsCard from "../../../Components/statisticsCard";
import {
  getAllSchools,
  getSchoolsPieChart,
} from "../../../Redux/actions/schoolActions";
import AlertBox from "../../../Components/AlertBox";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import NodataFound from "../../../Components/nodataFound";
import { PAGINATION_VALUE } from "../../../Redux/contants/userConstants";

const SchoolIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [sortbyStatus, setSortbyStatus] = useState("all");
  const [errorAlert, setErrorAlert] = useState(false);
  const { error, schoolsList, piechartData, totalCount } = useSelector(
    (state) => state.schoolDatas
  );
  const { sortBy, page } = useSelector((state) => state.userLogin);

  useEffect(() => {
    dispatch(setPageValue(1));
  }, []);

  const handleChange = (e) => {
    setSortbyStatus(e.target.value);
  };

  const handleAddSchoolClick = () => {
    dispatch(setHeaderName("add-school"));
    navigate("/add-school");
  };

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(
      getAllSchools(
        setErrorAlert,
        sortbyStatus,
        search,
        sortBy,
        search !== "" ? 1 : page
      )
    );
    dispatch(getSchoolsPieChart(setErrorAlert, piechartData));
  }, [dispatch, sortbyStatus, sortBy, page, search]);

  useEffect(() => {
    dispatch(setPageValue(1));
  }, [search]);

  const handleSeachOption = () => {
    dispatch(pageLoader(true));
    dispatch(getAllSchools(setErrorAlert, sortbyStatus, search, sortBy, page));
  };

  return (
    <div>
      <StatisticsCard data={piechartData} />
      <Card className="mt-3">
        <div style={{ padding: "20px" }}>
          <Typography className={classes.SchoolsMainHeader}>
            All School Details
          </Typography>
          <div className={`mt-4 ${classes.FilterContainerMain}`}>
            <div className={classes.HeadertextFeildContainer}>
              <div>
                <TextField
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                  }}
                  fullWidth
                  placeholder="Search by School Name or address"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  className={classes.searchBar}
                  id="firstNameinput"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  size="small"
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  className={classes.searchButton}
                  style={{
                    width: "110px",
                    height: "40px",
                  }}
                  onClick={handleSeachOption}
                >
                  <span className={classes.nrmlBtnText}>Search</span>
                </Button>
              </div>
              <div>
                <TextField
                  select
                  value={sortbyStatus}
                  onChange={handleChange}
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                  }}
                  size="small"
                  className={classes.sortDropdown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className={classes.SortByLable}>Sort by:</span>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="all">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value={"active"}>Active</MenuItem>
                  <MenuItem value={"inactive"}>Inactive</MenuItem>
                </TextField>
              </div>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ columnGap: "10px" }}
            >
              <div
                className={`${classes.DividerClasses} ${classes.vertical}`}
              ></div>
              <Button
                variant="contained"
                className={`d-flex align-items-center ${classes.searchButton}`}
                onClick={() => handleAddSchoolClick()}
              >
                <span style={{ marginRight: "10px" }}>
                  <RoundAddIcon />
                </span>
                <span className={classes.mainBtnText}>Add New School</span>
              </Button>
            </div>
          </div>
        </div>
        {totalCount > 0 ? (
          <div>
            <div className={classes.TableContainer}>
              <Table className={classes.TableClass}>
                <TableHead>
                  <TableRow className={classes.TableHeaderRow}>
                    <TableCell flex={1} className={classes.firstCell}>
                      <span className={classes.TableHeaderText}>#No</span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: "270px" }}>
                      <span className={classes.TableHeaderText}>
                        School Name
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "name")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("name") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: "180px" }}>
                      <span className={classes.TableHeaderText}>Address</span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "address")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("address") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: "130px" }}>
                      <span className={classes.TableHeaderText}>
                        Total Student
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "numberOfStudent")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("numberOfStudent") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1} style={{ minWidth: "140px" }}>
                      <span className={classes.TableHeaderText}>
                        Contact Number
                      </span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "user.phone")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("user.phone") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1}>
                      <span className={classes.TableHeaderText}>Email ID</span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "user.email")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("user.email") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1}>
                      <span className={classes.TableHeaderText}>Status</span>
                      <span
                        onClick={() =>
                          handleSortClick(dispatch, sortBy, "isActive")
                        }
                      >
                        {sortBy?.includes("desc") &&
                        sortBy?.includes("isActive") ? (
                          <SortIconOpposite />
                        ) : (
                          <SortIcon />
                        )}
                      </span>
                    </TableCell>
                    <TableCell flex={1}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schoolsList &&
                    schoolsList.map((item, index) => {
                      return (
                        <SchoolTableListItem
                          item={item}
                          key={index}
                          index={index + 1 + (page - 1) * 10}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            {totalCount && <DefaultPagination count={totalCount} />}
          </div>
        ) : (
          <div>
            <NodataFound text={"Please add schools to view"} />
          </div>
        )}
      </Card>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
    </div>
  );
};

export default SchoolIndex;
