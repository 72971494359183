import React, { useEffect, useState } from "react";
import classes from "./school.module.css";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Button,
  Typography,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  Card,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import {
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import DefaultPagination from "../../../Components/Pagination";
import TeacherTableListItem from "../Teachers/teacherTableListItem";
import {
  getAllDesignations,
  getAllTeachers,
} from "../../../Redux/actions/teacherActions";
import { setPageValue } from "../../../Redux/actions/userActions";

const TeacherMannagementTab = ({ oneSchool }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(false);
  const { sortBy, page } = useSelector((state) => state.userLogin);
  const [search, setSearch] = useState("");
  const [designation, setDesignation] = useState("select");
  const [errorAlert, setErrorAlert] = useState(false);
  const { error, teachersList, totalCount, designations } = useSelector(
    (state) => state.teacherDatas
  );

  useEffect(() => {
    dispatch(setPageValue(1));
    dispatch(getAllDesignations(setErrorAlert));
  }, []);

  const handleChangeDesignation = (e) => {
    console.log(e.target.value);
    setDesignation(e.target.value);
  };

  useEffect(() => {
    if (oneSchool && oneSchool?._id) {
      dispatch(
        getAllTeachers(
          setErrorAlert,
          search,
          oneSchool?._id,
          designation,
          sortBy,
          page
        )
      );
    }
  }, [dispatch, oneSchool, sortBy, page, designation, search]);

  const handleSearchClick = () => {
    dispatch(
      getAllTeachers(
        setErrorAlert,
        search,
        oneSchool?._id,
        designation,
        sortBy,
        page
      )
    );
    dispatch(getAllDesignations(setErrorAlert));
  };

  const handleCheckBoxChange = (event) => {
    setChecked(event.target.checked);
    renderTabledata();
  };

  const renderTabledata = () => {
    if (teachersList && teachersList.length > 0) {
      return teachersList.map((item, index) => {
        return (
          <TeacherTableListItem
            item={item}
            key={index}
            status={checked}
            from={"school"}
          />
        );
      });
    }
  };

  return (
    <div>
      <Card>
        <div className={`${classes.FilterSubContainer}`}>
          <div>
            <TextField
              sx={{
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
              fullWidth
              placeholder="Search by Teacher Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.searchBar}
              id="firstNameinput"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="small"
            />
          </div>
          <div>
            <Button
              variant="contained"
              className={classes.searchButton}
              style={{
                width: "110px",
                height: "40px",
              }}
              onClick={() => {
                handleSearchClick();
              }}
            >
              <span className={classes.nrmlBtnText}>Search</span>
            </Button>
          </div>
          <div>
            <Select
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                marginBottom: "15px",
                height: "40px",
              }}
              select
              id="teachernameInput"
              fullWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Set your desired max height here
                  },
                },
              }}
              className={
                designation === "select"
                  ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                  : `${classes.DropdownTextInput}`
              }
              value={designation}
              onChange={handleChangeDesignation}
              size="small"
            >
              <MenuItem value="select" style={{ display: "none" }}>
                Select Designation
              </MenuItem>
              {designations &&
                designations.length > 0 &&
                designations.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item._id}
                      style={{ textTransform: "capitalize" }}
                    >
                      {item.name?.charAt(0).toUpperCase() +
                        item.name?.slice(1).toLowerCase().replaceAll("_", ".")}
                    </MenuItem>
                  );
                })}
            </Select>
            {/* <TextField
                  select
                  value={designation}
                  onChange={handleChangeDesignation}
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                    "& fieldset": {
                      top: 0,
                    },
                  }}
                  size="small"
                  className={classes.classFilterDropdown}
                >
                  {["teacher", "principal", "sr.Teacher", "jr.Teacher"].map(
                    (item, index) => (
                      <MenuItem
                        key={item}
                        value={item}
                        className={classes.MenuItems}
                      >
                        {item}
                      </MenuItem>
                    )
                  )}
                </TextField> */}
          </div>
        </div>
        <div className={classes.TableContainer}>
          <Table className={classes.TableClass}>
            <TableHead>
              <TableRow className={classes.TableHeaderRow}>
                <TableCell flex={1} className={classes.firstCell}>
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckBoxChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 135 }}>
                  <span className={classes.TableHeaderText}>Teacher Name</span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "name")}
                  >
                    {sortBy?.includes("desc") &&
                    !sortBy?.includes("designationInfo") &&
                    sortBy?.includes("name") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 135 }}>
                  <span className={classes.TableHeaderText}>Designation</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "designationInfo.name")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("designationInfo.name") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 135 }}>
                  <span className={classes.TableHeaderText}>School Name</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "schoolInfo.name")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("schoolInfo.name") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 200 }}>
                  <span className={classes.TableHeaderText}>
                    Teacher Contatct Number
                  </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "userInfo.phone")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("userInfo.phone") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 150 }}>
                  <span className={classes.TableHeaderText}>
                    Teacher Email ID
                  </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "userInfo.email")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("userInfo.email") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: 95 }}>
                  <span className={classes.TableHeaderText}>Status</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "isActive")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("isActive") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTabledata()}</TableBody>
          </Table>
        </div>
        <DefaultPagination count={totalCount} />
      </Card>
    </div>
  );
};

export default TeacherMannagementTab;
