import React from "react";
import classes from "./auth.module.css";
import loginImage from "../../Assets/Images/LoginImage.png";
import { Typography } from "@mui/material";

const CommonImageContainer = () => {
  return (
    <div>
      <div className={classes.imageContainer}>
        <img src={loginImage} alt="MainLogo" className={classes.loginImage} />
        {/* <div className={classes.imageHeaderContainer}>
          <Typography className={classes.imageHeader}>
            Lorem Ipsum is simply dummy text
          </Typography>
          <Typography className={classes.imageSubHeader}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the
          </Typography>
        </div> */}
      </div>
    </div>
  );
};

export default CommonImageContainer;
