import { Button, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Card } from "@mui/material";
import classes from "./index.module.css";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  ExcelFileIcon,
  FileDeleteBinIcon,
  ImageDeleteIcon,
  ImageEditIcon,
  PdfFileIcon,
  PhpFileIcon,
} from "../Assets/Icons/icons";

const CommanDropDown = ({ image, callback, type, name, text }) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (isEdit && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [isEdit]);

  const onDrop = (acceptedFiles) => {
    try {
      const fileSizeInMB = acceptedFiles.size / (1024 * 1024); // Convert bytes to megabytes
      const maxSizeInMB = 100;

      if (fileSizeInMB > maxSizeInMB) {
        alert(
          `File size exceeds the maximum allowed size of ${maxSizeInMB} MB.`
        );
        // You can clear the input or handle it in another way based on your requirements
        setFiles(null);
        return;
      }
      console.log(acceptedFiles[0]);
      callback(acceptedFiles[0]);
      setIsEdit(false);
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log("Selected file:", selectedFile);

    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024); // Convert bytes to megabytes
      const maxSizeInMB = 100;

      if (fileSizeInMB > maxSizeInMB) {
        alert(
          `File size exceeds the maximum allowed size of ${maxSizeInMB} MB.`
        );
        // You can clear the input or handle it in another way based on your requirements
        setFiles(null);
        return;
      }
      console.log("Selected file:", selectedFile);
      callback(selectedFile);
      setIsEdit(false); // Exit edit mode
    } else {
      if (type === "coverImage") {
        console.log("No file selected.", image);
        const imageUrl = URL.createObjectURL(image);
        console.log("Fallback image URL:", imageUrl);
        setFiles(imageUrl);
      } else {
        console.log("FileSelectedNotImage", image);
        setFiles(image);
      }
      setIsEdit(false); // Exit edit mode
    }
  };

  const getIcon = (filename) => {
    const fileExtension = filename.split(".").pop();
    console.log("File extension:", fileExtension);
    if (fileExtension === "pdf") {
      return <PdfFileIcon />;
    } else if (fileExtension === "xlsx") {
      return <ExcelFileIcon />;
    }
  };

  useEffect(() => {
    if (image) {
      if (type === "coverImage") {
        if (typeof image === "object") {
          console.log("Selected image:", image);
          const imageUrl = URL.createObjectURL(image);
          setFiles(imageUrl);
        } else if (typeof image === "string") {
          console.log("Fallback image URL:", image);
          setFiles(image);
        } else {
          console.error("Invalid image type");
        }
      } else {
        console.log("FileSelectedNotImage", image);
        setFiles(image);
      }
    }
  }, [image]);

  return (
    <div>
      {image && files && !isEdit ? (
        <div>
          {type && type === "coverImage" ? (
            <div className={classes.dragDropimageContainer}>
              <img
                src={files}
                alt="GamePicture"
                className={`${classes.gameImage}`}
              />
              <div className={classes.btnSection}>
                <Button variant="text" onClick={() => setIsEdit(true)}>
                  <ImageEditIcon />
                </Button>
                <Button onClick={() => setFiles(null)} variant="text">
                  <ImageDeleteIcon />
                </Button>
              </div>
            </div>
          ) : type && type === "levelFile" ? (
            <Card
              className="d-flex align-items-center"
              style={{
                padding: "10px",
                width: "100%",
                height: "48px",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <PhpFileIcon />
                <Typography
                  className={classes.MenuItemText}
                  style={{ marginLeft: 10 }}
                >
                  {files.name || name}
                </Typography>
              </div>
              <Button
                variant="text"
                onClick={() => setFiles(null)}
                style={{ minWidth: 0 }}
              >
                <FileDeleteBinIcon />
              </Button>
            </Card>
          ) : null}
        </div>
      ) : (
        <div>
          <Dropzone onDrop={onDrop} multiple={false} noClick>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: classes.dragAndDropArea })}>
                <input
                  // {...getInputProps()}
                  ref={fileInputRef} // Attach the ref to the file input
                  onChange={handleFileInputChange}
                  type="file"
                  style={{ opacity: 0, position: "absolute", zIndex: 22 }}
                  accept=".pdf, .png, .jpg, .jpeg, .xls, .xlsx"
                  max="100000"
                />
                <Button className={classes.browseButton}>Browse...</Button>
                <Typography className={classes.dragDropText}>
                  or drop file here
                </Typography>
              </div>
            )}
          </Dropzone>
          <Typography className={`mt-2 ${classes.someNoteText}`}>
            (
            {text
              ? text
              : "Supported format: PDF, PNG, JPG, Xls only, max 6 MB"}
            )
          </Typography>
        </div>
      )}
    </div>
  );
};

export default CommanDropDown;
