import { authApi, getAuthorizationHeader } from "../../Api/api";
import {
  ADD_SUBSCRIPTION_FAIL,
  ADD_SUBSCRIPTION_SUCCESS,
  GET_ALL_SUBSCRIPTION_FAIL,
  GET_ALL_SUBSCRIPTION_SUCCESS,
  GET_ONE_SUBSCRIPTION_FAIL,
  GET_ONE_SUBSCRIPTION_SUCCESS,
} from "../contants/subscriptionContants";
import { pageLoader } from "./userActions";

export const addNewSubscription =
  (userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.post(
        "/subscription/create-subscription",
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      console.log(data);
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_SUBSCRIPTION_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        console.log("success", data);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: ADD_SUBSCRIPTION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const updateSubscription =
  (id, userEntry, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.patch(
        `/subscription/subscription-update/${id}`,
        userEntry,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      console.log(data);
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_SUBSCRIPTION_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        console.log("success", data);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: ADD_SUBSCRIPTION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getAllSubscriptions =
  (setErrorAlert, search, sortBy, page, limit) => async (dispatch) => {
    try {
      const { data } = await authApi.get(
        `/subscription/subscription-all?search=${search || ""}&page=${
          page || 1
        }&limit=${limit || 100}&sort=${sortBy || ""}`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      console.log("SubscData", data);
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: GET_ALL_SUBSCRIPTION_SUCCESS,
          payload: data.data,
        });
        console.log("success", data);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: GET_ALL_SUBSCRIPTION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const deleteSubscription =
  (id, setSuccessAlert, setErrorAlert) => async (dispatch) => {
    try {
      const { data } = await authApi.delete(
        `/subscription/subscription-delete/${id}`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      console.log(data);
      dispatch(pageLoader(false));
      if (data.success) {
        dispatch({
          type: ADD_SUBSCRIPTION_SUCCESS,
          payload: data.message,
        });
        setSuccessAlert(true);
        console.log("success", data);
        dispatch(getAllSubscriptions(setErrorAlert));
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: ADD_SUBSCRIPTION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      dispatch(pageLoader(false));
      setErrorAlert(true);
    }
  };

export const getOneSubscription = (id, setErrorAlert) => async (dispatch) => {
  try {
    const { data } = await authApi.get(
      `/subscription/${id}`,
      {},
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      }
    );
    console.log(data);
    dispatch(pageLoader(false));
    if (data.success) {
      dispatch({
        type: GET_ONE_SUBSCRIPTION_SUCCESS,
        payload: data.data,
      });
      console.log("success", data);
    }
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: GET_ONE_SUBSCRIPTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    dispatch(pageLoader(false));
    setErrorAlert(true);
  }
};
