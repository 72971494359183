import React from "react";
import noDataImage from "../Assets/Images/noDataImage.png";
import classes from "./index.module.css";
import { Typography } from "@mui/material";

const NodataFound = ({ text }) => {
  return (
    <div className={classes.noDataImageContainer}>
      <img
        src={noDataImage}
        alt="noDataImage"
        className={classes.noDataImage}
      />
      <Typography className={classes.MenuItemText} style={{ marginLeft: 0 }}>
        {text}
      </Typography>
    </div>
  );
};

export default NodataFound;
