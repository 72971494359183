import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import classes from "./index.module.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MutiSelectSchoolDropdown({
  width,
  value,
  handleChange,
  list,
  type,
}) {
  useEffect(() => {
    console.log(`${type} ===>`, list);
  }, [list]);

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          multiple
          sx={{
            "& legend": {
              display: "none",
            },
            "& fieldset": {
              top: 0,
            },
            width: width,
            height: "40px",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                padding: 0, // Set your desired max height here
              },
            },
          }}
          size="small"
          displayEmpty
          value={value}
          className={classes.schoolFilterDropdown}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(value) => {
            if (value.length === 0) {
              return <em className={classes.placeHolderText}>Select {type}</em>;
            }
            return value
              .map((item) =>
                type === "School"
                  ? item.name
                  : item?.charAt(0).toUpperCase() +
                    item?.slice(1).toLowerCase().replaceAll("_", " ")
              )
              ?.join(", ");
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="" style={{ display: "none" }}>
            <em>Select {type}</em>
          </MenuItem>
          {type === "School" && (
            <MenuItem key={"all"} value={"all"}>
              <ListItemText
                primary={"Clear All"}
                style={{ textTransform: "capitalize" }}
              />
            </MenuItem>
          )}
          {list &&
            list.length > 0 &&
            list.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Checkbox checked={value.includes(item)} />
                <ListItemText
                  primary={
                    type === "School"
                      ? item.name
                      : item?.charAt(0).toUpperCase() +
                        item?.slice(1).toLowerCase().replaceAll("_", " ")
                  }
                  style={{ textTransform: "capitalize" }}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
