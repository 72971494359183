import { authApi, getAuthorizationHeader } from "../../Api/api";
import { DASHBOARD_FIRST_PIECHART_FAIL, DASHBOARD_FIRST_PIECHART_SUCCESS } from "../contants/dashboardConstants";
import { pageLoader } from "./userActions";

export const getDashboardFirstPiechart = (setErrorAlert, array, navigate) => async (dispatch) => {
    try {
        const { data } = await authApi.get(`/super-admin/first-dashboard`, {
            headers: {
                Authorization: getAuthorizationHeader(),
            },
        });
        console.log(data);
        console.log(array);
        let newData = [];
        Object.keys(data.data)?.map((item) => {
            const obj = array.find((element) => {
                if (element.key === item) {
                    return element
                }
            })
            newData.push({
                ...obj,
                number: data.data[item]
            })
        })

        dispatch(pageLoader(false));
        if (data.success) {
            dispatch({
                type: DASHBOARD_FIRST_PIECHART_SUCCESS,
                payload: newData,
            });
        }
    } catch (error) {
        if (error && error.response && error.response.status === 401) {
            localStorage.removeItem("userToken");
            dispatch(pageLoader(false));
            navigate("/login");
        }
        dispatch({
            type: DASHBOARD_FIRST_PIECHART_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        dispatch(pageLoader(false));
        setErrorAlert(true);
    }
}