import { TableCell, TableRow, Typography, Chip, Button } from "@mui/material";
import React from "react";
import classes from "./payment.module.css";
import { StyledMenu } from "../../../Components/CommonMenu";
import { useState } from "react";
import {
  ActiveItemIcon,
  InactiveItemIcon,
  PaymentInprogressIcon,
  ThreeDotMenuIcon,
} from "../../../Assets/Icons/icons";
import dayjs from "dayjs";

const PaymentListItem = ({ item, key,index }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <TableRow key={item.id} className={classes.TableBodyRow}>
        <TableCell flex={1} className={classes.firstCell}>
           <Typography
            className={classes.TableBodyText}
          >{`#${index.toLocaleString("en-US", {
            minimumIntegerDigits: 3,
            useGrouping: false,
          })}`}</Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {item?.collection_type}
          </Typography>
        </TableCell>
        <TableCell flex={1} className={classes.firstCell}>
          <Typography className={classes.TableBodyText} textTransform={"capitalize"} >
            {item?.subscribed_by?.toLowerCase()} | {item?.name ?? "-"}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
            {dayjs(item?.updatedAt).format("DD MMM YYYY")}
          </Typography>
        </TableCell>
        <TableCell flex={1} className={classes.firstCell}>
          <Typography className={classes.TableBodyText}>
            {item?.subscriptionName}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Typography className={classes.TableBodyText}>
          £{item?.order_amount}
          </Typography>
        </TableCell>
        <TableCell flex={1}>
          <Chip
            icon={
              item.order_status === "SUCCESS" ? (
                <ActiveItemIcon />
              ) : item.order_status === "PENDING" ? (
                <PaymentInprogressIcon />
              ) : (
                <PaymentInprogressIcon />
              )
            }
            style={{
              background:
                item.order_status === "SUCCESS"
                  ? "var(--active-chip-background)"
                  : item.order_status === "PENDING"
                  ? "var(--inprogress-chip-background)"
                  : "var(--inprogress-chip-background)",
            }}
            label={
              item.order_status === "SUCCESS"
                ? "Paid"
                : item.order_status === "PENDING"
                ? "In progress"
                : "In progress"
            }
            className={classes.StatusChip}
          />
        </TableCell>
        {/* <TableCell flex={1}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
            variant="text"
            style={{
              minWidth: 0,
            }}
          >
            <ThreeDotMenuIcon />
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          ></StyledMenu>
        </TableCell> */}
      </TableRow>
    </React.Fragment>
  );
};

export default PaymentListItem;
